import ReactSelect, { components, StylesConfig } from 'react-select';
import styles from './Select.module.scss';
import { useEffect, useState } from 'react';
import { Search } from 'iconoir-react';
import LegacyCheckbox from '../../UI/Checkbox/LegacyCheckbox';

export default function Select<T>(props: SelectProps<T>) {
  const placeholder = props.displaySearchIcon
    ? <span className={styles.placeholderWithIcon}><Search />{props.placeholder}</span>
    : props.placeholder;
  return (
    <div>
      {props.label && (
        <label className={styles.label}>
          <strong>{props.label}</strong>
        </label>
      )}

      <ReactSelect
        isDisabled={props.disabled}
        className={styles.select}
        value={getValue(props.value, props.options)}
        onChange={(option: unknown) => props.onChange(option ? (option as Option<T>).value : null)}
        options={props.options}
        placeholder={placeholder}
        noOptionsMessage={() => ''}
        isSearchable={props.isSearchable}
        styles={props.compact ? compactReactSelectStyles(props.stylesOverride) : reactSelectStyles(props.stylesOverride)}
        menuPlacement={props.upwards ? 'top' : 'bottom'}
      />
    </div>
  );
}

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const [focus, setFocus] = useState(false);

  const stylesOverride = { ...props.stylesOverride };
  if (props.darkBackgroundOnFocus && focus) stylesOverride.control = { background: 'var(--panel-dark-new-details)' };

  return (
    <div style={props.containerStyle}>
      {props.label && <label className={styles.label}>
        <strong>{props.label}</strong>
      </label>}

      <ReactSelect
        isDisabled={props.disabled}
        value={getValues(props.value, props.options)}
        onChange={(options: unknown) => props.onChange((options as Option<T>[]).map(option => option.value))}
        isSearchable={props.isSearchable !== false}
        options={props.options}
        placeholder={props.placeholder}
        noOptionsMessage={() => ''}
        styles={reactSelectStyles(stylesOverride)}
        menuPlacement={props.upwards ? 'top' : 'bottom'}
        menuShouldScrollIntoView={false}
        onMenuOpen={props.onMenuOpen}
        isMulti
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        components={props.showCheckboxes ? { Option: OptionWithCheckBox } : {}}
        hideSelectedOptions={!props.showCheckboxes}
        closeMenuOnSelect={!props.showCheckboxes}
      />
    </div>
  );
}

export function LimitedSelect<T>(props: LimitedSelectProps<T>) {
  const [filteredOptions, setFilteredOptions] = useState<Option<T>[]>([]);
  const [placeHolder, setPlaceHolder] = useState<string | undefined>(props.placeholder);
  const dropdownMaxSize = props.dropdownMaxSize || 50;

  const togglePlaceholder = () => {
    if (!props.secondaryPlaceholder) return;

    if (placeHolder === props.placeholder) {
      setPlaceHolder(props.secondaryPlaceholder);
    } else {
      setPlaceHolder(props.placeholder);
    }
  };

  const filter = (inputStr: string) => {
    setFilteredOptions(props.options.filter(it => it.label.toLowerCase().includes(inputStr.toLowerCase())).slice(0, dropdownMaxSize));
  };

  useEffect(() => {
    setFilteredOptions(props.options.slice(0, dropdownMaxSize));
  }, [props.options]);

  return (
    <div>
      <label className={styles.label}>
        <strong>{props.label}</strong>
      </label>

      <ReactSelect
        isDisabled={props.disabled}
        value={getValue(props.value, props.options)}
        onInputChange={filter}
        onChange={(option: unknown) => props.onChange(option ? (option as Option<T>).value : null)}
        onMenuOpen={togglePlaceholder}
        onMenuClose={togglePlaceholder}
        options={filteredOptions}
        placeholder={placeHolder}
        noOptionsMessage={() => ''}
        styles={reactSelectStyles()}
        menuPlacement={props.upwards ? 'top' : 'bottom'}
      />
    </div>
  );
}

export function PlainMultiSelect<T>(props: Omit<MultiSelectProps<T>, 'label'>) {
  return (
    <div>
      <ReactSelect
        isDisabled={props.disabled}
        value={getValues(props.value, props.options)}
        onChange={(options: unknown) => props.onChange((options as Option<T>[]).map(option => option.value))}
        options={props.options}
        placeholder={props.placeholder}
        noOptionsMessage={() => ''}
        styles={plainReactSelectStyles}
        isClearable={false}
        menuPlacement={props.upwards ? 'top' : 'bottom'}
        isMulti
      />
    </div>
  );
}

function OptionWithCheckBox(props) {
  return (
    <div>
      <components.Option {...props} isFocused={false}>
        <LegacyCheckbox checked={props.isSelected}>
          {props.label}
        </LegacyCheckbox>
      </components.Option>
    </div>
  );
}

function getValue<T>(value: T, options: Option<T>[]) {
  return value ? options.find(option => option.value === value) ?? null : null;
}

function getValues<T>(values: T[], options: Option<T>[]) {
  return options.filter(option => values.includes(option.value));
}

interface Option<T> {
  label: string,
  value: T
}

interface SelectProps<T> {
  // eslint-disable-next-line react/no-unused-prop-types
  compact?: boolean,
  value: T | null,
  onChange: (value: T | null) => unknown,
  label: string,
  placeholder?: string,
  options: Option<T>[],
  disabled?: boolean,
  upwards?: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  stylesOverride?: any,
  // eslint-disable-next-line react/no-unused-prop-types
  isSearchable?: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  displaySearchIcon?: boolean
}

interface MultiSelectProps<T> extends Omit<SelectProps<T[]>, 'options' | 'value' | 'onChange' | 'compact'> {
  value: T[],
  options: Option<T>[],
  onChange: (value: T[]) => unknown,
  containerStyle?: any,
  stylesOverride?: any,
  darkBackgroundOnFocus?: boolean,
  isSearchable?: boolean,
  onMenuOpen?: () => unknown,
  showCheckboxes?: boolean
}

interface LimitedSelectProps<T> extends SelectProps<T> {
  dropdownMaxSize: number,
  secondaryPlaceholder: string | undefined
}

export const reactSelectStyles: (overrides?: Partial<StylesConfig>) => StylesConfig = overrides => ({
  control: provided => ({
    ...provided,
    borderRadius: 3,
    background: 'transparent',
    borderColor: 'var(--input-border)',
    fontSize: 'var(--font-size-m)',
    minHeight: '40px',
    cursor: 'pointer',
    ':focus-within': { boxShadow: 'none' },
    ':active': {
      boxShadow: 'none'
    },
    ':hover': {
      borderColor: 'var(--input-border)',
      boxShadow: 'none'
    },
    ':focus': {
      borderColor: 'var(--input-border)',
      boxShadow: 'none'
    },
    ...(overrides?.control || {})
  }),
  singleValue: provided => ({
    ...provided,
    color: 'var(--text-primary)'
  }),
  multiValue: provided => ({
    ...provided,
    background: 'var(--chip-background)',
    border: 'none',
    borderRadius: '100px',
    alignItems: 'center'
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'var(--text-primary)',
    fontSize: 'var(--font-size-m)',
    paddingLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '0'
  }),
  multiValueRemove: provided => ({
    ...provided,
    borderRadius: '100px',
    width: '18px',
    height: '18px',
    marginLeft: '6px',
    marginRight: '6px',
    boxSizing: 'border-box',
    border: '1.5px solid var(--input-border)',
    '> svg': {
      transform: 'scale(1.8)'
    }
  }),
  input: provided => ({
    ...provided,
    color: 'var(--text-primary)'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: provided => ({
    ...provided,
    background: 'var(--input-select-background)',
    border: '1px solid var(--input-border)',
    zIndex: '999',
    ...(overrides?.menu || {})
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'var(--input-border)',
    ':hover': {
      color: 'var(--input-border)'
    },
    ...overrides?.dropdownIndicator
  }),
  option: (provided, state) => ({
    padding: '7px 10px',
    ...(!state.isSelected
      ? {}
      : {
        backgroundColor: 'var(--input-select-option-active-background)'
      }),
    ...(!state.isFocused
      ? {}
      : {
        backgroundColor: 'var(--input-select-option_hover-background)'
      }),
    ':hover': {
      backgroundColor: 'var(--input-select-option_hover-background)',
      cursor: 'pointer'
    },
    ...(overrides?.option || {})
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingRight: '0',
    color: 'var(--input-border)',
    ':hover': {
      color: 'var(--input-border)'
    }
  }),
  valueContainer: provided => ({
    ...provided
  })
});

export const plainReactSelectStyles: StylesConfig = {
  ...reactSelectStyles(),
  valueContainer: provided => ({
    ...provided,
    padding: '0'
  }),
  control: provided => ({
    ...provided,
    borderRadius: 3,
    background: 'transparent',
    border: 'none',
    fontSize: 'var(--font-size-m)',
    cursor: 'pointer',
    ':focus-within': { boxShadow: 'none' },
    ':active': {
      boxShadow: 'none'
    },
    ':hover': {
      border: 'none',
      boxShadow: 'none'
    },
    ':focus': {
      border: 'none',
      boxShadow: 'none'
    }
  }),
  multiValueLabel: (provided, state) => ({
    ...reactSelectStyles().multiValueLabel!(provided, state),
    fontSize: 'var(--font-size-s)'
  }),
  dropdownIndicator: () => ({ display: 'none' }),
  placeholder: provided => ({
    ...provided,
    color: 'var(--text-faded)',
    ':after': {
      display: 'inline-block',
      content: '""',
      padding: '3px',
      border: 'solid var(--text-faded)',
      borderWidth: '0 1px 1px 0',
      transform: 'rotate(45deg) translateY(-50%)',
      marginLeft: '5px'
    }
  })
};

const compactReactSelectStyles: (overrides?: Partial<StylesConfig>) => StylesConfig = overrides => ({
  ...reactSelectStyles(),
  control: provided => ({
    ...provided,
    borderRadius: 0,
    background: 'transparent',
    border: 'none',
    fontSize: 'var(--font-size-m)',
    minHeight: 'unset',
    cursor: 'pointer',
    ':focus-within': { boxShadow: 'none' },
    ':active': {
      boxShadow: 'none'
    },
    ':hover': {
      boxShadow: 'none'
    },
    ':focus': {
      boxShadow: 'none'
    },
    ...overrides?.control
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    color: 'var(--input-border)',
    ':hover': {
      color: 'var(--input-border)'
    },
    ...overrides?.dropdownIndicator
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 0,
    color: 'var(--input-border)',
    ':hover': {
      color: 'var(--input-border)'
    }
  })
});
