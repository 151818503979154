import { Image } from '../routes/Explore/panoramic-view/meshGenerator';

export default class CapturePoint {
  static fromDto(dto: CapturePointDto) {
    return new CapturePoint(
      dto.id,
      dto.organizationId,
      dto.externalSnapshotId,
      dto.location?.coordinates,
      dto.worldLocation?.coordinates,
      dto.distance,
      dto.type,
      dto.imageProperties
    );
  }

  leftNeighborId: string | null = null;
  rightNeighborId: string | null = null;

  constructor(
    readonly id: string,
    readonly organizationId: string,
    readonly snapshotId: string,
    readonly location: [number, number, number],
    readonly worldLocation?: [number, number, number],
    readonly distance?: number,
    readonly type?: string,
    readonly imageProperties?: Image[]
  ) {}

  getWorldCoordinates(): [number, number] {
    if (!this.worldLocation) {
      return [0, 0];
    }
    const mercatorCoordinateMin = 20037508.34;
    return [
      (Math.atan(Math.exp((this.worldLocation[1] * Math.PI) / mercatorCoordinateMin)) * 360) / Math.PI - 90,
      (this.worldLocation[0] * 180) / mercatorCoordinateMin
    ];
  }
}

export interface CapturePointDto {
  id: string,
  location: {
    coordinates: [number, number, number]
  },
  worldLocation?: {
    coordinates: [number, number, number]
  },
  organizationId: string,
  externalSnapshotId: string,
  distance?: number,
  type?: string,
  imageProperties?: Image[]
}
