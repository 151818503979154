import styles from './OrganizationSettings.module.scss';
import { useTranslation } from 'react-i18next';
import LabelWithDropdown from '../../../components/UI/LabelWithDropdown/LabelWithDropdown';
import { useCurrentAccount, useEditAccount } from '../../../account/useAccounts';
import { useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { KMH_TO_MPH, METER_TO_FEET } from '../../../components/PointCloud/unitConstants';
import { CardinalDirection } from '../../../utils/getCardinalDirectionFromAngle';
import { Flippers } from '../../../switches/Flippers';
import { disabledTreeValueMethods, TreeValueMethod } from '../../../property-enums/TreeValueMethod';
import { DbhMeasurementHeight } from '../../../property-enums/DbhMeasurementHeight';
import { StreetAddressOrder } from '../../../organization/Organization';
import Spinner from '../../../components/UI/Spinner/Spinner';

const METRICAL = 'metrical';
const IMPERIAL = 'imperial';

const unitMap = [
  { id: METRICAL, translationKey: 'settings.dataStandards.defaults.metrical' },
  { id: IMPERIAL, translationKey: 'settings.dataStandards.defaults.imperial' }
];

const streetAddressOrderMap = [
  { id: StreetAddressOrder.STREET_NAME_FIRST, translationKey: 'settings.dataStandards.defaults.startsWithName' },
  { id: StreetAddressOrder.STREET_NUMBER_FIRST, translationKey: 'settings.dataStandards.defaults.startsWithNumber' }
];

const currencyMap = [
  { id: 'EUR', translationKey: 'EUR - €' },
  { id: 'USD', translationKey: 'USD - $' },
  { id: 'HUF', translationKey: 'HUF - Ft' }
];

const getWindSpeedMap = (isMetric: boolean) => {
  const speedFactor = isMetric ? 1 : KMH_TO_MPH;

  return new Array(16).fill({}).map((it, idx) => {
    return {
      id: `${(idx + 2) * 10}`,
      translationKey: `${((idx + 2) * 10 * speedFactor).toFixed(0)} ${isMetric ? 'km/h' : 'mph'}`
    };
  });
};

const prevailingWindDirectionMap = Object.values(CardinalDirection).map(direction => {
  return { id: direction, translationKey: 'treeDetails.risk.windDirections.' + direction };
});

const treeValueMethodMap = Object.values(TreeValueMethod).map(method => {
  return { id: method, translationKey: 'treeDetails.treeValueMethod.' + method, disabled: disabledTreeValueMethods.includes(method) };
});

const dbhMeasurementHeightOptions = [DbhMeasurementHeight.EU, DbhMeasurementHeight.US, DbhMeasurementHeight.GERMANY];

const dbhMeasurementHeightMapMetric = dbhMeasurementHeightOptions.map(value => {
  return {
    id: value.toString(),
    translationKey: value.toFixed(1) + ' m'
  };
});
const dbhMeasurementHeightMapImperial = dbhMeasurementHeightOptions.map(value => {
  return {
    id: value.toString(),
    translationKey: (value * METER_TO_FEET).toFixed(2) + ' ft'
  };
});

export default function OrganizationSettings() {
  const { t } = useTranslation();
  const { organizationService } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const editAccount = useEditAccount(account.id);

  const [unit, setUnit] = useState(account.organization.isMetric ? unitMap[0] : unitMap[1]);
  const [streetAddressOrder, setStreetAddressOrder] = useState(streetAddressOrderMap.find(it => it.id === account.organization.streetAddressOrder) || streetAddressOrderMap[0]);
  const windSpeedMap = useMemo(() => {
    return getWindSpeedMap(unit.id === METRICAL);
  }, [unit.id]);

  const dbhMeasurementHeightMap = useMemo(() => {
    return unit.id === METRICAL ? dbhMeasurementHeightMapMetric : dbhMeasurementHeightMapImperial;
  }, [unit.id]);

  const [currency, setCurrency] = useState(currencyMap.find(it => it.id === account.organization.currency) || currencyMap[0]);
  const [windSpeed, setWindSpeed] = useState(windSpeedMap.find(it => +it.id === account.organization.defaultWindSpeed) || windSpeedMap[6]);
  const [prevailingWindDirection, setPrevailingWindDirection] = useState(prevailingWindDirectionMap.find(it => it.id.toString() === account.organization.prevailingWindDirection) || null);
  const [treeValueMethod, setTreeValueMethod] = useState(treeValueMethodMap.find(it => it.id.toString() === account.organization.treeValueMethod)!);
  const [dbhMeasurementHeight, setDbhMeasurementHeight] = useState(dbhMeasurementHeightMap.find(it => it.id === account.organization.dbhMeasurementHeight.toString()));
  const [disabledButton, setDisabledButton] = useState(true);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const isChanged = account.organization.currency !== currency.id ||
            account.organization.isMetric !== (unit.id === METRICAL) ||
            account.organization.streetAddressOrder !== streetAddressOrder.id ||
            account.organization.prevailingWindDirection !== (prevailingWindDirection?.id || null) ||
            account.organization.defaultWindSpeed !== Number(windSpeed.id) ||
            account.organization.treeValueMethod !== treeValueMethod.id ||
            account.organization.dbhMeasurementHeight !== Number(dbhMeasurementHeight!.id) ;
    setDisabledButton(!isChanged);
  }, [unit, currency, windSpeed, prevailingWindDirection, treeValueMethod, dbhMeasurementHeight, streetAddressOrder]);

  useEffect(() => {
    setWindSpeed(windSpeedMap.find(it => +it.id === account.organization.defaultWindSpeed) || windSpeedMap[6]);
    setDbhMeasurementHeight(dbhMeasurementHeightMap.find(it => it.id === account.organization.dbhMeasurementHeight.toString()));
  }, [windSpeedMap]);

  const applyChanges = async () => {
    setPending(true);
    const payload = {};
    if (account.organization.isMetric !== (unit.id === METRICAL)) {
      payload['isMetric'] = !account.organization.isMetric;
      await organizationService.toggleUnitOfMeasure(account.organization.id);
    }

    if (account.organization.streetAddressOrder !== streetAddressOrder.id) {
      payload['streetAddressOrder'] = account.organization.streetAddressOrder;
      await organizationService.setStreetAddressOrder(account.organization.id, streetAddressOrder.id);
    }

    if (account.organization.currency !== currency.id) {
      payload['currency'] = currency.id;
      await organizationService.setCurrency(account.organization.id, currency.id);
    }

    if (account.organization.defaultWindSpeed !== Number(windSpeed.id)) {
      payload['defaultWindSpeed'] = Number(windSpeed.id);
      await organizationService.setDefaultWindSpeed(account.organization.id, Number(windSpeed.id));
    }

    if (account.organization.prevailingWindDirection !== prevailingWindDirection?.id && prevailingWindDirection?.id) {
      payload['prevailingWindDirection'] = prevailingWindDirection.id;
      await organizationService.setPrevailingWindDirection(account.organization.id, prevailingWindDirection.id);
    }

    if (account.organization.treeValueMethod !== treeValueMethod.id) {
      payload['treeValueMethod'] = treeValueMethod.id;
      await organizationService.setTreeValueMethod(account.organization.id, treeValueMethod.id);
    }

    if (account.organization.dbhMeasurementHeight !== Number(dbhMeasurementHeight!.id)) {
      payload['dbhMeasurementHeight'] = Number(dbhMeasurementHeight!.id);
      await organizationService.setDbhMeasurementHeight(account.organization.id, Number(dbhMeasurementHeight!.id));
    }

    editAccount(account.refreshOrganization(account.organization.refresh(payload)));
    setDisabledButton(true);
    setPending(false);
  };

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{t('settings.dataStandards.defaults.title')}</h2>
      <p className={styles.description}>{t('settings.dataStandards.defaults.description')}</p>
      <LabelWithDropdown
        onSelect={it => setUnit(it)}
        dropdownValue={unit}
        label={'settings.dataStandards.defaults.unitLabel'}
        items={unitMap}/>
      <LabelWithDropdown
        onSelect={it => setCurrency(it)}
        dropdownValue={currency}
        label={'settings.dataStandards.defaults.currencyLabel'}
        items={currencyMap}/>
      <LabelWithDropdown
        onSelect={it => setPrevailingWindDirection(it)}
        dropdownValue={prevailingWindDirection || undefined}
        label={'settings.dataStandards.defaults.prevailingWindDirectionLabel'}
        items={prevailingWindDirectionMap}/>
      {account.organization.isEnabled(Flippers.windspeedSlider) && (
        <LabelWithDropdown
          onSelect={it => setWindSpeed(it)}
          dropdownValue={windSpeed}
          label={'settings.dataStandards.defaults.windSpeedLabel'}
          items={windSpeedMap}
        />
      )}
      <LabelWithDropdown
        onSelect={it => setStreetAddressOrder(it)}
        dropdownValue={streetAddressOrder}
        label={'settings.dataStandards.defaults.streetAddressOrder'}
        items={streetAddressOrderMap}/>
      {account.organization.isEnabled(Flippers.newPlatformProperties) && (
        <LabelWithDropdown
          onSelect={it => setTreeValueMethod(it)}
          dropdownValue={treeValueMethod}
          label={'settings.dataStandards.defaults.treeValueMethod'}
          items={treeValueMethodMap}/>
      )}
      <LabelWithDropdown
        onSelect={it => setDbhMeasurementHeight(it)}
        dropdownValue={dbhMeasurementHeight}
        label={'settings.dataStandards.defaults.setDbhMeasurementHeight'}
        items={dbhMeasurementHeightMap}/>
      <div className={styles.buttonContainer}>
        {pending ?
          (
            <div className={styles.spinner}>
              <Spinner/>
            </div>
          )
          :
          (
            <button
              disabled={disabledButton}
              className={`${styles.applyButton} ${disabledButton ? styles.disabled : ''}`}
              onClick={applyChanges}
            >
              {t('settings.dataStandards.defaults.apply')}
            </button>
          )
        }
      </div>
    </section>
  );
}
