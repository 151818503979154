import styles from './ForgotPasswordScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import { FormEvent, useCallback, useRef, useState } from 'react';

import { FullLogoIcon } from '../UI/Icon/Icon';
import Input from '../UI/Input/Input';
import LegacyButton from '../UI/Button/LegacyButton';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../account/LanguageSelector';
// import AuthPageLayout from '../UI/Page/carbon/AuthPageLayout';
// import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
// import { Button, FluidForm } from '@carbon/react';

export default function ForgotPasswordScreen(props: ForgotPasswordScreenProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [email, setEmail] = useState('');

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (!formRef.current || !formRef.current.checkValidity() || props.isLoading) {
        return;
      }

      props.onForgotPassword(email);
      return false;
    },
    [email, props]
  );

  // const handleSubmit = useCallback(
  //   (event: FormEvent) => {
  //     event.preventDefault();
  //
  //     if (event.currentTarget.tagName === 'FORM' && (!(event.currentTarget as HTMLFormElement).reportValidity() || props.isLoading)) {
  //       return;
  //     }
  //
  //     props.onForgotPassword(email);
  //     return false;
  //   },
  //   [email, props]
  // );

  // TODO: Add CDS redesigned forgot password screen
  // return (
  //   <AuthPageLayout pageTitle="authPageTitles.forgotPassword">
  //     <AuthPageLayout.Content>
  //       {props.isCompleted ? (<div className="text-[var(--cds-interactive)]">{t('forgotPassword.success')}</div>) : (
  //         <FluidForm onSubmit={handleSubmit} className="w-full relative">
  //           <div className="flex flex-col gap-2 w-full">
  //             <FluidTextInput
  //               autoComplete="email"
  //               labelText={t('signup.email')}
  //               autoFocus={true}
  //               className="w-80"
  //               type="email"
  //               id="forgot-password-email"
  //               value={email}
  //               onChange={e => setEmail(e.target.value)}
  //             />
  //           </div>
  //           <div className="w-full flex mt-4 justify-end text-sm">
  //             <Button
  //               kind="secondary"
  //               className="px-4 py-3"
  //               onClick={() => navigate('/')}
  //             >
  //               {t('forgotPassword.backToLoginPage')}
  //             </Button>
  //             <Button type="submit" kind="primary" className="px-4 py-3">{t('forgotPassword.sendLink')}</Button>
  //           </div>
  //         </FluidForm>
  //       )}
  //     </AuthPageLayout.Content>
  //   </AuthPageLayout>
  // );

  return (
    <div className={styles.forgotPassword}>
      <FullLogoIcon />

      <div className={styles.container}>
        {props.isCompleted && <div className={styles.success}>{t('forgotPassword.success')}</div>}

        {!props.isCompleted && (
          <form
            className={styles.form}
            ref={formRef}
            onSubmit={onSubmit}>
            <Input
              label="forgotPassword.email"
              value={email}
              placeholder="email@provider.com"
              required
              onValueChange={setEmail}
              type="email"
              legacyStyle={true}
            />

            <div className={styles.controls}>
              <LegacyButton
                variant="secondary"
                type="button"
                onClick={() => navigate('/')}
                role="link">
                {t('forgotPassword.backToLogin')}
              </LegacyButton>

              <LegacyButton disabled={!email || props.isLoading}>
                {t('forgotPassword.sendMeTheLink')}
              </LegacyButton>
            </div>
          </form>
        )}
      </div>
      <div className={styles.languageSelector}>
        <LanguageSelector onLanguageChange={user => i18n.changeLanguage(user.language)} initialLanguage={i18n.language || 'en'} />
      </div>
    </div>
  );
}

interface ForgotPasswordScreenProps {
  onForgotPassword: (email: string) => unknown,
  isCompleted: boolean,
  isLoading: boolean
}
