import { useTranslation } from 'react-i18next';
import { Button } from '@carbon/react';
import { Observation } from '../../../tree/DetailedTree';

export function ObservationItem(props: ObservationItemProps) {
  const { t } = useTranslation();
  return (
    <div className="flex px-4 text-sm font-normal">
      {props.observation.photoUrls[0] && <div className="relative w-20 h-20 mr-3">
        <img src={props.observation.photoUrls[0]} alt="observation" className="w-full h-full object-cover" />
        {props.observation.photoUrls.length > 0 && <Button kind="ghost" size="sm" className="absolute bottom-0 right-0 bg-black bg-opacity-30 text-white text-xs">
          {props.observation.photoUrls.length}
        </Button>}
      </div>}
      <div>
        <div className="font-medium">
          {t(`virtualInspection.observationOptions.${props.observation.name}`)}
        </div>
        <div className="pb-6 justify-start items-start gap-4 inline-flex">
          <div
            className="text-sm text-[var(--cds-text-secondary)]">
            {t('treeDetails.dataCollection.treePart')}<br />
            {t('treeDetails.dataCollection.recommendation')}<br />
            {t('treeDetails.dataCollection.conditionOfConcern')}
          </div>
          <div
            className="text-sm">
            {t(`virtualInspection.observationGroupTitles.${props.observation.affectedTreePart}`)}<br />
            {props.observation.mitigations.map(it => it.name || it.taskTemplate.name).join(', ')}<br />
            {t(`details.properties.booleanLabels.${props.observation.conditionOfConcern}`)}
          </div>
        </div>
      </div>
    </div>
  );
}

interface ObservationItemProps {
  observation: Observation
}
