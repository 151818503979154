import { ButtonsOverlay, TileButton, TileContainer, TileContent } from '../TileLayout';
import { Xmark, Expand, Reduce } from 'iconoir-react';
import useStreetViewProps from './useStreetViewProps';
import StreetViewBottomCenterButtons from './StreetViewBottomCenterButtons';
import StreetViewRightBottomButtons from './StreetViewRightBottomButtons';
import StreetViewContent from './StreetViewContent';
import { TreeDisplays } from '../../constants';
import ViewSelector from '../components/ViewSelector';
import AddPageIcon from '../components/AddPageIcon';
import DetailedTree from '../../../../tree/DetailedTree';

export default function StreetViewTile(props: StreetViewTileProps) {
  const streetViewProps = useStreetViewProps({ tree: props.tree });

  return (
    <TileContainer>
      <TileContent>
        <ButtonsOverlay>
          <ButtonsOverlay.TopRight>
            <TileButton onClick={() => props.resizeTile()} icon={props.isExpanded ? <Reduce /> : <Expand />} />
            {props.showAddButton && <TileButton onClick={() => props.openSecondaryTile()} icon={<AddPageIcon />} />}
            {props.showCloseButton && <TileButton onClick={() => props.setView(TreeDisplays.NULL)} icon={<Xmark />} />}
          </ButtonsOverlay.TopRight>
          <ButtonsOverlay.BottomCenter>
            <StreetViewBottomCenterButtons {...streetViewProps} />
            <ViewSelector setView={props.setView} view={props.view} />
          </ButtonsOverlay.BottomCenter>
          <ButtonsOverlay.RightBottom>
            <StreetViewRightBottomButtons {...streetViewProps} />
          </ButtonsOverlay.RightBottom>
        </ButtonsOverlay>
        <StreetViewContent {...streetViewProps} />
      </TileContent>
    </TileContainer>
  );
}

interface StreetViewTileProps {
  view: TreeDisplays,
  setView: (view: TreeDisplays) => void,
  tree: DetailedTree,
  openSecondaryTile: () => void,
  showAddButton: boolean,
  showCloseButton: boolean,
  resizeTile: () => void,
  isExpanded: boolean
}
