import styles from './TileLayout.module.scss';
import { ReactElement, ReactNode } from 'react';
import { IconButton } from '@carbon/react';

export function ButtonsOverlay(props: ButtonsOverlayProps) {
  return <div className={styles.buttonsOverlay}>
    {props.children}
  </div>;
}

interface ButtonsOverlayProps {
  children: ReactNode
}

ButtonsOverlay.BottomRight = function ({ children }) {
  return <div className={styles.bottomRight}>{children}</div>;
};

ButtonsOverlay.BottomLeft = function ({ children }) {
  return <div className={styles.bottomLeft}>{children}</div>;
};

ButtonsOverlay.BottomCenter = function ({ children }) {
  return <div className="absolute bottom-[20px] left-1/2 translate-x-[-50%] pointer-events-auto">{children}</div>;
};

ButtonsOverlay.RightBottom = function ({ children }) {
  return <div className={styles.rightBottom}>{children}</div>;
};

ButtonsOverlay.LeftCenter = function ({ children }) {
  return <div className={styles.leftCenter}>{children}</div>;
};

ButtonsOverlay.RightCenter = function ({ children }) {
  return <div className={styles.rightCenter}>{children}</div>;
};

ButtonsOverlay.TopRight = function ({ children }) {
  return <div className={styles.topRight}>{children}</div>;
};

export function TileContent({ children }) {
  return <div className={styles.content}>{children}</div>;
}

export function TileContainer({ children }: { children }) {
  return <div className={styles.container}>{children}</div>;
}

export function TileButton(props: TileButtonProps) {
  return (
    <IconButton
      label={props.label}
      disabled={props.disabled}
      onClick={props.onClick}
      className={props.active ? 'bg-[#22272A] hover:bg-[#22272A]' : 'bg-[#22272ACC] hover:bg-[#22272A]'}
    >
      {props.icon}
    </IconButton>
  );
}

interface TileButtonProps {
  label: string,
  disabled?: boolean,
  active?: boolean,
  onClick: () => void,
  icon: ReactElement
}

export function TileButtonGroup({ children }) {
  return <div className={styles.tileButtonGroup}>{children}</div>;
}
