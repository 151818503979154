import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Checkbox, FilterableMultiSelect } from '@carbon/react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useAvailableColumnSelectorProperties } from '../../../properties/usePropertyConfigurations';
import { Flippers } from '../../../switches/Flippers';
import { DisplayableTreeProperty } from '../../../tree/Tree';

export default function ColumnSelector(props: { disabled: boolean }) {
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const properties = useAvailableColumnSelectorProperties(organization.isEnabled(Flippers.release24q3));
  const items = Object.values(properties).flat().filter(it => it !== DisplayableTreeProperty.SafetyFactors);
  const selectedItems = items.filter(it => urlContext.getVisibleTableProperties()?.includes(it));
  const allSelected = selectedItems.length === items.length;
  const indeterminate = !allSelected && selectedItems.length > 0;
  let isChangeHandlerCalled = false;

  const filterItems = (x, extra) => {
    return items.filter(it => t(`tree.${it}`).toLowerCase().includes(extra.inputValue.toLowerCase()));
  };
  const placeholder = () => {
    if (selectedItems.length > 1) return t('columnSelector.columnsSelected');
    return selectedItems.length ? t('columnSelector.columnSelected') : t('columnSelector.selectColumns');
  };

  const handleChange = (data: { selectedItems }) => {
    if (isChangeHandlerCalled) return;
    isChangeHandlerCalled = true;
    urlContext.setVisibleTableProperties(data.selectedItems);
  };

  const handleSelectAll = () => {
    if (selectedItems.length > 0) {
      urlContext.setVisibleTableProperties([]);
      return;
    }

    urlContext.setVisibleTableProperties(items);
  };

  return (
    <div className="flex bg-[var(--cds-field)]">
      <div className={'flex items-center h-full px-2 ' + (props.disabled ? '' : 'border-solid border-b border-0 border-[var(--cds-border-strong)]')}>
        <Checkbox className="text-[var(--cds-text-secondary)] pt-1 [&>label]:pt-0.5" indeterminate={indeterminate} checked={allSelected} disabled={props.disabled} id="select-all-column" labelText={t('all') || ''} onClick={handleSelectAll}/>
      </div>
      <FilterableMultiSelect
        id="column-selector"
        placeholder={placeholder()}
        items={items}
        selectedItems={selectedItems}
        filterItems={filterItems}
        onChange={handleChange}
        itemToString={it => it ? t(`tree.${it}`) : ''}
        disabled={props.disabled}
        size="lg"
      />
    </div>
  );
}
