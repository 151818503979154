import styles from './ImagesViewTile.module.scss';
import { ButtonsOverlay, TileButton, TileButtonGroup, TileContainer, TileContent } from '../TileLayout';
import { Tree } from '../../../../tree/Tree';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Xmark, Expand, Minus, NavArrowLeft, NavArrowRight, Plus, Reduce } from 'iconoir-react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { TreeDisplays } from '../../constants';
import AddPageIcon from '../components/AddPageIcon';
import ImageCounter from '../components/ImageCounter';
import ViewSelector from '../components/ViewSelector';

export default function ImagesViewTile(props: ImagesViewTileProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [mouseDownPos, setMouseDownPos] = useState({ clientX: 0, clientY: 0 });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const leftChevronRef = useRef<HTMLButtonElement | null>(null);
  const rightChevronRef = useRef<HTMLButtonElement | null>(null);
  const fitToTileRef = useRef<() => void>();

  const imageUrls = props.tree.images.map(it => it.getRotatedUrl());

  useEffect(() => {
    if (!containerRef.current || !imageRef.current || !fitToTileRef.current) return;
    imageRef.current.onload = () => {
      const ro = new ResizeObserver(() => fitToTileRef.current!());
      ro.observe(containerRef.current!);
      return () => ro.disconnect();
    };
  }, []);

  const displayNext = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (activeIndex === imageUrls.length - 1) {
      return setActiveIndex(0);
    }
    setActiveIndex(prev => prev + 1);
  };

  const displayPrev = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (activeIndex === 0) {
      return setActiveIndex(imageUrls.length - 1);
    }
    setActiveIndex(prev => prev - 1);
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return;
    const container = containerRef.current?.getBoundingClientRect();
    const halfPoint = container.width / 2;
    if (halfPoint < event.clientX - container.x) {
      leftChevronRef.current?.classList.remove(styles.visible);
      rightChevronRef.current?.classList.add(styles.visible);
    } else {
      rightChevronRef.current?.classList.remove(styles.visible);
      leftChevronRef.current?.classList.add(styles.visible);
    }
  };

  const handleMouseLeave = () => {
    leftChevronRef.current?.classList.remove(styles.visible);
    rightChevronRef.current?.classList.remove(styles.visible);
  };

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>, fitToTile: () => void) => {
    if (event.clientX === mouseDownPos.clientX && event.clientY === mouseDownPos.clientY && containerRef.current) {
      const halfPoint = containerRef.current?.getBoundingClientRect().width / 2;
      if (halfPoint < event.clientX) {
        displayNext();
      } else {
        displayPrev();
      }
      fitToTile();
    }
  };

  return (
    <TileContainer>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, setTransform }) => {
          fitToTileRef.current = () => {
            if (!imageRef.current || !containerRef.current) return;
            const imageRatio = imageRef.current.clientWidth / imageRef.current.clientHeight;
            const containerRatio = containerRef.current.clientWidth / containerRef.current.clientHeight;

            if (containerRatio < imageRatio) {
              const scale = containerRef.current?.clientHeight / imageRef.current.clientHeight;
              const x = (containerRef.current?.clientWidth - (scale * imageRef.current?.clientWidth)) / 2;
              setTransform(x, 0, scale, 1);
            } else {
              const scale = containerRef.current?.clientWidth / imageRef.current?.clientWidth;
              const y = (containerRef.current?.clientHeight - (scale * imageRef.current?.clientHeight)) / 2;
              setTransform(0, y, scale, 1);
            }
          };
          return <>
            <ButtonsOverlay>
              <ButtonsOverlay.TopRight>
                <TileButton onClick={() => props.resizeTile()} icon={props.isExpanded ? <Reduce /> : <Expand />} />
                {props.showAddButton && <TileButton onClick={() => props.openSecondaryTile()} icon={<AddPageIcon />} />}
                {props.showCloseButton && <TileButton onClick={() => props.setView(TreeDisplays.NULL)} icon={<Xmark />} />}
              </ButtonsOverlay.TopRight>
              <ButtonsOverlay.LeftCenter>
                <button
                  ref={leftChevronRef}
                  className={styles.arrowButton}
                  onClick={event => {
                    fitToTileRef.current!();
                    displayPrev(event);
                  }}
                >
                  <NavArrowLeft />
                </button>
              </ButtonsOverlay.LeftCenter>
              <ButtonsOverlay.RightCenter>
                <button
                  ref={rightChevronRef}
                  className={styles.arrowButton}
                  onClick={event => {
                    fitToTileRef.current!();
                    displayNext(event);
                  }}
                >
                  <NavArrowRight />
                </button>
              </ButtonsOverlay.RightCenter>
              <ButtonsOverlay.RightBottom>
                <TileButtonGroup>
                  <TileButton onClick={() => zoomIn()} icon={<Plus />} />
                  <TileButton onClick={() => zoomOut()} icon={<Minus />} />
                </TileButtonGroup>
              </ButtonsOverlay.RightBottom>
              <ButtonsOverlay.BottomCenter>
                <ImageCounter
                  imagesLength={imageUrls.length}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  oneThirdView={!props.showAddButton}
                />
                <ViewSelector setView={props.setView} view={props.view} />
              </ButtonsOverlay.BottomCenter>
            </ButtonsOverlay>
            <TileContent>
              <div
                ref={containerRef}
                className={styles.imageContainer}
                style={{ backgroundImage: `url('${imageUrls[activeIndex]}')` }}
                onMouseMove={e => handleMouseMove(e)}
                onMouseDown={e => setMouseDownPos({ clientX: e.clientX, clientY: e.clientY })}
                onMouseUp={e => handleMouseUp(e, fitToTileRef.current!)}
                onMouseLeave={handleMouseLeave}
              >
                <div className={styles.blur}>
                  <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }} >
                    <img
                      ref={imageRef}
                      className={styles.image}
                      src={imageUrls[activeIndex]}
                      alt=""
                    />
                  </TransformComponent>
                </div>
              </div>
            </TileContent>
          </>;
        }}
      </TransformWrapper>
    </TileContainer>
  );
}

interface ImagesViewTileProps {
  view: TreeDisplays,
  setView: (view: TreeDisplays) => void,
  tree: Tree,
  openSecondaryTile: () => void,
  showAddButton: boolean,
  showCloseButton: boolean,
  resizeTile: () => void,
  isExpanded: boolean
}
