import { t } from 'i18next';
import React from 'react';
import { CheckCircle, XmarkCircle } from 'iconoir-react';
import { useCurrentAccount } from '../../account/useAccounts';
import { AccountType } from '../../account/AccountType';
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeader } from '@carbon/react';

enum Permissions {
  ViewTreeDetails = 'viewTreeDetails',
  DeleteTree = 'deleteTree',
  CreateTask = 'createTask',
  WorkOnTasks = 'workOnTasks',
  ManageMembers = 'manageMembers',
  OrganizationDataStandards = 'organizationDataStandards',
  OrganizationSettings = 'organizationSettings'
}

const clientPermissions = [
  Permissions.ViewTreeDetails
];

const employeePermissions = [
  ...clientPermissions,
  Permissions.WorkOnTasks
];
const managerPermissions = [
  ...employeePermissions,
  Permissions.CreateTask
];
const adminPermissions = [
  ...managerPermissions,
  Permissions.ManageMembers,
  Permissions.OrganizationDataStandards,
  Permissions.DeleteTree
];
const ownerPermissions = [
  ...adminPermissions.filter(it => it !== Permissions.WorkOnTasks),
  Permissions.OrganizationSettings
];
export function RolesAndPermissions() {
  const currentAccount = useCurrentAccount();
  const columns = [AccountType.Client, AccountType.Employee, AccountType.Manager, AccountType.Admin];
  if (currentAccount.isOwner()) columns.push(AccountType.Owner);
  return (
    <div className="max-w-[640px] mx-auto flex flex-col gap-16">
      <Table size="lg" useZebraStyles={false}>
        <TableHead>
          <TableRow>
            <TableHeader />
            {columns.map(column => <TableHeader id={column} key={column}>
              <span className="capitalize">{t(`account.type.${column}`)}</span>
            </TableHeader>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(Permissions)
            .filter(it => it !== Permissions.OrganizationSettings || currentAccount.isOwner())
            .map(permission => <TableRow key={permission}>
              <TableCell>{t(`rolesAndPermissions.${permission}`)}</TableCell>
              <TableCell>{clientPermissions.includes(permission) ? <CheckCircle className="text-success-500" /> :
                <XmarkCircle className="text-danger-500" />}</TableCell>
              <TableCell>{employeePermissions.includes(permission) ? <CheckCircle className="text-success-500" /> :
                <XmarkCircle className="text-danger-500" />}</TableCell>
              <TableCell>{managerPermissions.includes(permission) ? <CheckCircle className="text-success-500" /> :
                <XmarkCircle className="text-danger-500" />}</TableCell>
              <TableCell>{adminPermissions.includes(permission) ? <CheckCircle className="text-success-500" /> :
                <XmarkCircle className="text-danger-500" />}</TableCell>
              {currentAccount.isOwner() &&
                <TableCell>{ownerPermissions.includes(permission) ? <CheckCircle className="text-success-500" /> :
                  <XmarkCircle className="text-danger-500" />}</TableCell>}
            </TableRow>)}
        </TableBody>
      </Table>
    </div>
  );
}
