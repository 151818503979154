import { EnumCondition, Filter, NumericCondition } from './FilterConfig';
import { Tree } from '../../../../tree/Tree';
import { isNumericCondition } from '../../../TaskManager/create/FilterConfig';

export class FilterCondition {
  static fromConfig(config: Filter): FilterCondition {
    return new FilterCondition(config.property, config.condition, config.value);
  }

  constructor(
    readonly property: keyof Tree,
    private readonly condition: NumericCondition | EnumCondition,
    private readonly value: number | string[]
  ) {}

  apply(tree: Partial<Tree>): boolean {
    if (tree[this.property] === undefined || tree[this.property] === null) return false;
    const property = isNumericCondition(this.condition) ? (Math.round(100 * (tree[this.property] as number)) / 100) : tree[this.property] as string;
    switch (this.condition) {
    case NumericCondition.EQUALS:
      return property === this.value;
    case NumericCondition.GREATER_THAN:
      return property > this.value;
    case NumericCondition.LESS_THAN:
      return property < this.value;
    case EnumCondition.IN:
      return (this.value as string[]).includes(property as string);
    default:
      return false;
    }
  }
}
