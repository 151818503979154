import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Plan } from '@carbon/icons-react';

export interface SearchHint {
  label: string,
  description?: string
}

export interface TreeByIdHint extends SearchHint {
  id: string
}

export interface TreeByCustomerIdHint extends SearchHint {
  id: string
}

export interface ManagedAreaHint extends SearchHint {
  id: string
}

export interface AddressHint extends SearchHint {
  coordinates: number[]
}

export default function SearchHintsSection<T>(props: SearchHintsSectionProps<T>) {
  const { t } = useTranslation();

  return (
    <div className="py-4 text-xs font-normal">
      <div className="flex items-center pb-1 px-4 font-medium">
        <div className="pr-2">{props.icon}</div>
        <div>{t(props.title)}</div>
      </div>
      <div>
        {props.hints.map(hint => {
          const isHighlighted = props.highlightedHint && hint.label === props.highlightedHint.label;
          return (
            <div
              key={hint.label}
              onClick={() => props.onSelect(hint as T)}
              className={`font-light text-[var(--cds-text-secondary)] py-2 px-4 items-center text-sm gap-4 cursor-pointer hover:bg-[var(--cds-field)] ${isHighlighted ? 'bg-[var(--cds-field)]' : ''}`}
            >
              {hint.label}
              {hint.description &&
                <div className="text-sm text-[var(--cds-text-placeholder)] flex items-center">
                  {hint.description}
                </div>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
}

interface SearchHintsSectionProps<T> {
  onSelect: (hint: T) => void,
  hints: TreeByIdHint[] | TreeByCustomerIdHint[] | ManagedAreaHint[] | AddressHint[],
  title: string,
  icon: ReactElement,
  highlightedHint: TreeByIdHint | TreeByCustomerIdHint | ManagedAreaHint | AddressHint | null
}
