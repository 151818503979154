import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useContext } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useNavigate } from 'react-router-dom';
import { Task, TaskStatus } from '../../../task-manager/Task';
import DropdownButtonGroup, { DropdownButton } from '../../../components/UI/DrowdownButton/DropdownButtonGroup';
import Button from '../../../components/UI/Button/Button';
import { ConnectedModule } from '../ConnectedModule';
import { ContextFields } from '../../Explore/UrlContext';
import { AuthContext } from '../../../auth/AuthContext';

export default function ActionButtons(props: ActionButtonsProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const user = useContext(AuthContext).user;

  const navigate = useNavigate();

  const changeTaskStatusTo = async (status: TaskStatus) => {
    await taskManagerService.updateTask(props.task.organizationId, props.task.id, { status });
  };

  const startTask = async () => {
    await changeTaskStatusTo(TaskStatus.STARTED);
    if (props.task.connectedModule === ConnectedModule.remoteInspection)
      navigate(`/organizations/${account.organization.id}/remote-inspections/${props.task.id}?${ContextFields.TreeId}=${props.task.getStartingTreeId()}`);
  };

  const employeeActions = {
    [TaskStatus.ASSIGNED]: <PendingAcceptanceButtonGroup changeTaskStatusTo={changeTaskStatusTo}/>,
    [TaskStatus.ACCEPTED]: <Button onClick={startTask}>{t('taskManager.taskTable.actionButtons.start')}</Button>,
    [TaskStatus.STARTED]: <StartedTaskButtonGroupForEmployee task={props.task} changeTaskStatusTo={changeTaskStatusTo}/>,
    [TaskStatus.PAUSED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.STARTED)}>{t('taskManager.taskTable.actionButtons.resume')}</Button>
  };

  const managerActions = {
    [TaskStatus.DRAFT]: <AssignButton task={props.task} changeStatusToAssigned={() => changeTaskStatusTo(TaskStatus.ASSIGNED)} />,
    [TaskStatus.ASSIGNED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.REVOKED)}>{t('taskManager.taskTable.actionButtons.revoke')}</Button>,
    [TaskStatus.REJECTED]: <AssignButton task={props.task} changeStatusToAssigned={() => changeTaskStatusTo(TaskStatus.ASSIGNED)} />,
    [TaskStatus.UNASSIGNED]: <AssignButton task={props.task} changeStatusToAssigned={() => changeTaskStatusTo(TaskStatus.ASSIGNED)} />,
    [TaskStatus.ACCEPTED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.REVOKED)}>{t('taskManager.taskTable.actionButtons.revoke')}</Button>,
    [TaskStatus.STARTED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.PAUSED)}>{t('taskManager.taskTable.actionButtons.pause')}</Button>,
    [TaskStatus.PAUSED]: <PausedButtonGroupForManager task={props.task} changeTaskStatusTo={changeTaskStatusTo}/>,
    [TaskStatus.COMPLETED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.APPROVED)}>{t('taskManager.taskTable.actionButtons.approve')}</Button>,
    [TaskStatus.APPROVED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.ARCHIVED)}>{t('taskManager.taskTable.actionButtons.archive')}</Button>,
    [TaskStatus.REVOKED]: <AssignButton task={props.task} changeStatusToAssigned={() => changeTaskStatusTo(TaskStatus.ASSIGNED)} />
  };

  const selfAssignedActions = {
    [TaskStatus.DRAFT]: <AssignButton task={props.task} changeStatusToAssigned={() => changeTaskStatusTo(TaskStatus.ASSIGNED)} />,
    [TaskStatus.REJECTED]: <AssignButton task={props.task} changeStatusToAssigned={() => changeTaskStatusTo(TaskStatus.ASSIGNED)} />,
    [TaskStatus.UNASSIGNED]: <AssignButton task={props.task} changeStatusToAssigned={() => changeTaskStatusTo(TaskStatus.ASSIGNED)} />,
    [TaskStatus.ASSIGNED]: <PendingAcceptanceButtonGroup changeTaskStatusTo={changeTaskStatusTo}/>,
    [TaskStatus.ACCEPTED]: <Button onClick={startTask}>{t('taskManager.taskTable.actionButtons.start')}</Button>,
    [TaskStatus.STARTED]: <StartedTaskButtonGroupForEmployee task={props.task} changeTaskStatusTo={changeTaskStatusTo}/>,
    [TaskStatus.PAUSED]: <PausedButtonGroupForManager task={props.task} changeTaskStatusTo={changeTaskStatusTo}/>,
    [TaskStatus.COMPLETED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.APPROVED)}>{t('taskManager.taskTable.actionButtons.approve')}</Button>,
    [TaskStatus.APPROVED]: <Button onClick={() => changeTaskStatusTo(TaskStatus.ARCHIVED)}>{t('taskManager.taskTable.actionButtons.archive')}</Button>
  };

  const getActions = () => {
    if (props.task.assignee?.id === props.task.createdBy?.id) return selfAssignedActions;
    if (props.task.createdBy?.id === user.id) return managerActions;
    return employeeActions;
  };

  return (
    <div>
      {getActions()[props.task.status]}
    </div>
  );
}

interface ActionButtonsProps {
  task: Task
}

const AssignButton = (props: { task: Task, changeStatusToAssigned: () => void }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={props.changeStatusToAssigned}
      disabled={!props.task.assignee?.id || !props.task.deadline}
    >
      {t('taskManager.taskTable.actionButtons.assign')}
    </Button>
  );
};

const PendingAcceptanceButtonGroup = (props: { changeTaskStatusTo: (status: TaskStatus) => void }) => {
  const { t } = useTranslation();

  return (
    <DropdownButtonGroup
      label={t('taskManager.taskTable.actionButtons.accept')}
      onClick={() => props.changeTaskStatusTo(TaskStatus.ACCEPTED)}
    >
      <DropdownButton
        onClick={() => props.changeTaskStatusTo(TaskStatus.REJECTED)}
        label={t('taskManager.taskTable.actionButtons.reject')}
      />
    </DropdownButtonGroup>
  );
};

const PausedButtonGroupForManager = (props: { task: Task, changeTaskStatusTo: (status: TaskStatus) => void }) => {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const unassignTask = async () => {
    await taskManagerService.updateTask(
      props.task.organizationId,
      props.task.id,
      { status: TaskStatus.UNASSIGNED, assigneeUserId: null }
    );
  };

  return (
    <DropdownButtonGroup
      label={t('taskManager.taskTable.actionButtons.resume')}
      onClick={() => props.changeTaskStatusTo(TaskStatus.STARTED)}
    >
      <DropdownButton
        onClick={unassignTask}
        label={t('taskManager.taskTable.actionButtons.unassign')}
      />
    </DropdownButtonGroup>
  );
};

const StartedTaskButtonGroupForEmployee = (props: { task: Task, changeTaskStatusTo: (status: TaskStatus) => void }) => {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const navigate = useNavigate();

  const canBeCompleted = props.task.progress === props.task.treeIds.length || props.task.connectedModule === null;

  const completeTask = () => {
    props.changeTaskStatusTo(TaskStatus.COMPLETED);
  };

  const continueTask = () => {
    if (props.task.connectedModule === ConnectedModule.remoteInspection)
      navigate(`/organizations/${account.organization.id}/remote-inspections/${props.task.id}?${ContextFields.TreeId}=${props.task.nextTodoTreeId}`);
  };

  return (
    <DropdownButtonGroup
      onClick={canBeCompleted ? completeTask : continueTask}
      label={t(`taskManager.taskTable.actionButtons.${canBeCompleted ? 'complete' : 'continue'}`)}
    >
      <DropdownButton
        onClick={() => props.changeTaskStatusTo(TaskStatus.PAUSED)}
        label={t('taskManager.taskTable.actionButtons.pause')}
      />
    </DropdownButtonGroup>
  );
};
