export const MAP_STYLES = {
  default: 'mapbox://styles/ghrtms/cl6uqhcdo006314n0h12uf0kq',
  satellite: 'mapbox://styles/mapbox/satellite-v9'
};

export const CARBON_MAP_STYLES = {
  white: 'mapbox://styles/ghrtms/cm23aalrk006n01pb7j5l4ot0',
  g10: 'mapbox://styles/ghrtms/cm23a74lp000p01qp64br55wl',
  g90: 'mapbox://styles/ghrtms/cm23adm7g006o01pb90eth0bd',
  g100: 'mapbox://styles/ghrtms/cm23agpyr006b01pgdi0ffl5t'
};

export const CLUSTERING_ZOOM_LEVEL = 17;
export const MAX_ZOOM_LEVEL = 20;
