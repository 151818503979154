import { StreetViewProps } from './useStreetViewProps';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import CapturePoint from '../../../../capture-point/CapturePoint';
import { useTracking } from '../../../../analytics/useTracking';
import SafetyFactorMarkerGroup from '../../../../components/PointCloud/SafetyFactorMarkerGroup';
import { LeaningAngleGroup } from '../../../../components/PointCloud/LeaningAngleGroup';
import { RulerGroup } from '../../../../components/PointCloud/RulerGroup';
import PanoramicDataHandler from '../../../Explore/panoramic-view/PanoramicDataHandler';
import { newVector3FromLocalCoords } from '../../../../utils/ThreeJsHelpers';
import * as THREE from 'three';
import { PanoramicViewLight } from '../../../Explore/panoramic-view/PanoramicViewLight';
import styles from './StreetViewTile.module.scss';
import useMountedEffect from '../../../../hooks/useMountedEffect';
import { LoadablePointCloud } from '../../../../point-cloud/LoadablePointCloud';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import MatrixUtils from '../../../../utils/MatrixUtils';
import { DetailsContext } from '../../LegacyDetails';
import { EnvironmentAccordion } from '../../DataPanel/DataTabs/ClearancesTab';
import DetailedTree from '../../../../tree/DetailedTree';
import { Tab } from '../../DataPanel/DataTabs/TabSelector';
import StreetViewRiskOverlay from '../components/StreetViewRiskOverlay';

export default function StreetViewContent(props: StreetViewProps) {
  const [isLoaded, setLoaded] = useState(false);
  const currentAccount = useCurrentAccount();
  const { rulersAndHover: rulers } = useContext(DetailsContext);

  const organizationId = currentAccount.organization.id;

  const { t } = useTranslation();
  const { capturePointService, treeService, urlContext } = useContext(DependencyInjectionContext);
  const capturePointId = urlContext.getCapturePointId();
  const account = useCurrentAccount();
  const [currentCapturePoint, setCurrentCapturePoint] = useState<CapturePoint | null>(null);
  const [capturePoints, setCapturePoints] = useState<{ id: string, location: [number, number, number] }[]>([]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { events, track } = useTracking();

  const environmentPointSize = 1.6;

  const safetyFactorGroupRef = useRef<SafetyFactorMarkerGroup | null>(null);
  const leaningAngleGroupRef = useRef<LeaningAngleGroup | null>(null);
  const rulersRef = useRef<RulerGroup[]>([]);

  const selectedAccordion = urlContext.getSelectedEnvAccordion();
  const isDemoTree = props.tree.externalId === '4713';

  const dataHandler = useMemo(() =>
    new PanoramicDataHandler(treeService, capturePointService, account.organization),
  [treeService, capturePointService, account.organization]);

  const isCanvasRendered = useCallback(node => {
    if (node !== null) {
      canvasRef.current = node;
    }
  }, []);

  const getTreePosition = useCallback(tree => {
    return newVector3FromLocalCoords(tree.localizedLocation)
      .sub(new THREE.Vector3(...props.tree.localizedLocation)
        .applyAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2));
  }, [props.tree.localizedLocation]);

  useEffect(() => {
    if (!canvasRef.current) return;
    props.viewRef.current = PanoramicViewLight.create(canvasRef.current!, styles, account.organization.isMetric);
    props.viewRef.current?.listen(() => {});
    props.viewRef.current.render();
    return () => {
      props.viewRef.current?.dispose();
      props.viewRef.current?.clearListeners();
      urlContext.setCapturePointId(null);
      urlContext.setCameraRotation(null);
    };
  }, []);

  useMountedEffect(() => {
    const view = props.viewRef.current;
    if (!view) return;

    if (props.lineMeasurementEnabled) {
      track(events.LINE_MEASUREMENT_CHANGE_IN_DETAILS, { enabled: true });
      view.enableLineMeasure();
    } else {
      track(events.LINE_MEASUREMENT_CHANGE_IN_DETAILS, { enabled: false });
      view.disableLineMeasure();
    }
    view.render();
    return () => view.disableLineMeasure();
  }, [props.lineMeasurementEnabled]);

  useEffect(() => {
    if (!canvasRef.current?.parentElement) return;
    const ro = new ResizeObserver(() => props.viewRef.current?.setSize());
    ro.observe(canvasRef.current?.parentElement);
    return () => ro.disconnect();
  }, [props.viewRef]);

  useEffect(() => props.viewRef.current?.setTreePosition(props.tree), [props.tree, props.viewRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view) return;
    view.measurement.reset();
    new LoadablePointCloud(props.tree.getPointCloudUrl(account.organization)).loadIntoWithTreeId(props.tree.id, props.tree.localizedLocation, undefined, props.hideCanopy).then(pc => {
      if (pc && pc.belongsTo(props.tree) && isLoaded) {
        view.removePointCloud();
        view.setPointCloud(pc);
        if (!props.showPointcloud) {
          view.hideTreePointCloud();
        }
        view.render();
      }
    });
  }, [props.viewRef, props.tree, props.showPointcloud, account.organization, isLoaded, props.hideCanopy]);

  useEffect(() => {
    if (isDemoTree && selectedAccordion === EnvironmentAccordion.CorridorClearing && props.tree.environment?.corridorLShapeVertex?.coordinates) {
      (async () => {
        const isMetrical = account.organization.getIsMetrical();
        props.viewRef.current?.addLShapeRuler(props.tree, isMetrical);

        await props.viewRef.current?.addLShape(account.organization, props.tree.localizedLocation);

        await new LoadablePointCloud(props.tree.getCorridorClearingPointCloudUrl(account.organization), 0.8)
          .loadInto(props.tree.localizedLocation, true, new THREE.PointsMaterial({ size: 0.2, color: '#FF455E', sizeAttenuation: false }))
          .then(pc => props.viewRef.current?.setCriticalPointCloud(pc));

        props.viewRef.current?.render();
      })();
    }

    return () => {
      props.viewRef.current?.removeLShape();
      props.viewRef.current?.removeLShapeRuler();
      props.viewRef.current?.removeCriticalPointCloud();
      props.viewRef.current?.render();
    };
  }, [props.viewRef, selectedAccordion, isDemoTree, props.tree, account.organization.tseBlobContainer]);

  useEffect(() => {
    const view = props.viewRef.current;

    if (!view || !props.tree) return;

    const scalingFactor = props.tree!.height / 6;
    safetyFactorGroupRef.current = new SafetyFactorMarkerGroup(
      2, 0.1, 50, props.tree.getWindDirectionAngle(80) || 0, scalingFactor, () => view.render()
    );
    safetyFactorGroupRef.current.position.copy(getTreePosition(props.tree));
    safetyFactorGroupRef.current.renderOrder = 100;

    view.scene.add(safetyFactorGroupRef.current);

    return () => {
      view.scene.remove(safetyFactorGroupRef.current!);
      safetyFactorGroupRef.current = null;
    };
  }, [props.viewRef, props.tree, getTreePosition, account.organization]);

  useEffect(() => {
    const view = props.viewRef.current;

    if (!view || !props.tree) return;

    leaningAngleGroupRef.current = new LeaningAngleGroup(
      props.tree?.height || 0,
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(...(props.tree?.leaningVector || [0, 0, 0])),
      t('analytics.properties.leaningAngle'),
      () => view.render(),
      true
    );
    leaningAngleGroupRef.current.renderOrder = 100;
    leaningAngleGroupRef.current.position.copy(getTreePosition(props.tree));

    view.scene.add(leaningAngleGroupRef.current);

    return () => {
      view.scene.remove(leaningAngleGroupRef.current!);
      leaningAngleGroupRef.current = null;
    };
  }, [props.viewRef, props.tree, t, getTreePosition]);

  useEffect(() => {
    const view = props.viewRef.current;

    if (!view || !props.tree) return;
    view.clear2DRenderer();

    const isMetrical = account.organization.getIsMetrical();
    rulersRef.current = [
      RulerGroup.forHeight(props.tree, t('analytics.properties.height'), () => view.render(), isMetrical, true),
      RulerGroup.forFirstBifurcation(props.tree, t('analytics.properties.trunkHeight'), () => view.render(), isMetrical, true),
      RulerGroup.forCanopyWidth(props.tree, t('analytics.properties.canopyWidth'), () => view.render(), isMetrical, true),
      RulerGroup.forCanopyHeight(props.tree, t('analytics.properties.canopyHeight'), () => view.render(), isMetrical, true),
      RulerGroup.forDBH(account.organization, props.tree, t('analytics.properties.trunkDiameter'), () => view.render(), true)
    ];

    rulersRef.current
      .filter(it => rulers.includes(it.propertyName))
      .forEach(ruler => {
        if (rulers.length > 1) {
          ruler.displayedInOriginalPlace();
        } else {
          ruler.displayedInTheMiddle();
        }
      });

    view.scene.add(...rulersRef.current);

    return () => {
      view.scene.remove(...rulersRef.current);
      rulersRef.current = [];
    };
  }, [props.viewRef, props.tree, account.organization, t, getTreePosition, rulers]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view || !view.scene ||
      !safetyFactorGroupRef.current ||
      !leaningAngleGroupRef.current
    ) return;

    safetyFactorGroupRef.current.visible = rulers.includes('safetyFactor');
    leaningAngleGroupRef.current?.setVisibility(rulers.includes('leaningAngle'));
    rulersRef.current.forEach(it => it.setVisibility(rulers.includes(it.propertyName)));
    view.render();
  }, [props.viewRef, account.organization, t, props.tree, rulers]);

  useEffect(() => {
    new LoadablePointCloud(props.tree.getEnvironmentPointCloudUrl(account.organization), environmentPointSize)
      .loadInto(props.tree.localizedLocation, true).then(pc => {
        const view = props.viewRef.current;
        if (pc && view) {
          view.removeEnvironmentPointCloud();
          view.setEnvironmentPointCloud(pc);
          if (!props.showEnvironmentPointcloud) {
            view.hideEnvironmentPointCloud();
          }
          view.render();
        }
      });
  }, [props.viewRef, props.tree, props.showEnvironmentPointcloud, account.organization]);

  useEffect(() => {
    (async () => {
      if (capturePointId) {
        const capturePoint = await capturePointService.show(organizationId, capturePointId);
        setCurrentCapturePoint(capturePoint);
      }
    })();
  }, [props.tree.capturePointId, organizationId, capturePointId, props.tree]);

  const calculateBestCapturePoint = useCallback((tree: DetailedTree) => {
    const relativeCameraPosition = new THREE.Vector3(tree.metricHeight, 0, 0).applyMatrix3(MatrixUtils.degToYRotationMatrix(tree.canopyDirection));
    const treePosition = newVector3FromLocalCoords(tree.localizedLocation);
    const idealCameraPositionFront = treePosition.clone().add(relativeCameraPosition);
    const idealCameraPositionBack = treePosition.clone().sub(relativeCameraPosition);
    const sortedCapturepoints = capturePoints
      .filter(it => distanceTo(it, treePosition) > tree.metricHeight)
      .map(it => ({ id: it.id, distance: Math.min(distanceTo(it, idealCameraPositionFront), distanceTo(it, idealCameraPositionBack)) }))
      .sort((a, b) => a.distance - b.distance);

    return sortedCapturepoints[0];

    function distanceTo(capturePoint: { location: [number, number, number]}, otherLocation: THREE.Vector3): number {
      return newVector3FromLocalCoords(capturePoint.location).distanceTo(otherLocation);
    }
  }, [capturePoints]);

  useEffect(() => {
    if (capturePointId) return;

    const nextCapturePoint = calculateBestCapturePoint(props.tree);
    if (!nextCapturePoint || (currentCapturePoint && nextCapturePoint.id === currentCapturePoint.id)) return;
    (async () => {
      setCurrentCapturePoint(await capturePointService.show(organizationId, nextCapturePoint.id));
      urlContext.setCapturePointId(null);
      urlContext.setCameraRotation(null);
    })();
  }, [calculateBestCapturePoint, selectedAccordion]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view || !view.scene) return;

    (async () => {
      const bestCapturePoint = await capturePointService.show(organizationId, props.tree.capturePointId);
      const panoramicData = await dataHandler.load(bestCapturePoint, props.tree, account, false);
      if (!panoramicData) return;

      setCapturePoints(panoramicData.capturePoints);
      view.setCapturePointsAsCss3D(panoramicData.capturePoints, onCapturePointChange);
      view.render();
    })();
  }, [props.tree]);

  const onCapturePointChange = (nextCapturePointId: string) => {
    track(events.CAPTURE_POINT_CHANGE_FROM_DETAILS_VIEW, { from: capturePointId, to: nextCapturePointId });
    urlContext.setCapturePointId(nextCapturePointId);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view || !view.scene || !currentCapturePoint || !dataHandler) return;

    (async () => {
      const panoramicData = await dataHandler.load(currentCapturePoint, props.tree, account, false);
      if (!panoramicData) return;
      const { images } = panoramicData;
      await view.setSphereAndPutCameraThere(images);
      view.lookAtTree(props.tree, currentAccount.organization.isMetric);
      view.setSize();
      view.render();
      setLoaded(true);
    })();
    return () => {
      if (!canvas) return;
      view.remove();
      setLoaded(false);
    };
  }, [currentCapturePoint]);

  useEffect(() => {
    const view = props.viewRef.current;
    if (!view) return;
    if (props.showEnvironmentPointcloud) {
      view.showEnvironmentPointCloud();
    } else {
      view.hideEnvironmentPointCloud();
    }
    view.render();
  }, [props.viewRef, props.showEnvironmentPointcloud]);

  return <>
    <div className={styles.container}>
      <canvas ref={isCanvasRendered} style={{ visibility: isLoaded ? 'visible' : 'hidden' }} />
      {!isLoaded &&
        <div>
          <Spinner />
        </div>}
      {urlContext.getSelectedDataPanelTab() === Tab.Risk &&
        <StreetViewRiskOverlay
          tree={props.tree}
          viewRef={props.viewRef}
        />
      }
    </div>
  </>;
}
