import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import styles from './ImagesViewTile.module.scss';
import { ButtonsOverlay, TileButton, TileContainer, TileContent } from '../TileLayout';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ImageCounter from '../components/ImageCounter';
import ViewSelector from '../components/ViewSelector';
import ZoomIn from '@carbon/icons-react/lib/ZoomIn';
import ZoomOut from '@carbon/icons-react/lib/ZoomOut';
import { TreeDisplays } from '../../../Details/TreeDisplayTile/TreeDisplays';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, ArrowRight, Close, SplitScreen } from '@carbon/icons-react';
import { Button } from '@carbon/react';

export default function ImagesViewTile(props: ImagesViewTileProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [mouseDownPos, setMouseDownPos] = useState({ clientX: 0, clientY: 0 });
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const leftChevronRef = useRef<HTMLButtonElement | null>(null);
  const rightChevronRef = useRef<HTMLButtonElement | null>(null);
  const resetRef = useRef<() => void>();

  const imageBlobs = props.treeImageBlobs;

  useEffect(() => {
    if (!containerRef.current || !imageRef.current || !resetRef.current) return;
    imageRef.current.onload = () => {
      const ro = new ResizeObserver(() => resetRef.current!());
      ro.observe(containerRef.current!);
      return () => ro.disconnect();
    };
  }, [containerRef, imageRef, resetRef]);

  const displayNext = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (activeIndex === imageBlobs.length - 1) {
      return setActiveIndex(0);
    }
    setActiveIndex(prev => prev + 1);
  };

  const displayPrev = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (activeIndex === 0) {
      return setActiveIndex(imageBlobs.length - 1);
    }
    setActiveIndex(prev => prev - 1);
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return;
    const container = containerRef.current?.getBoundingClientRect();
    const halfPoint = container.width / 2;
    if (halfPoint < event.clientX - container.x) {
      leftChevronRef.current?.classList.remove(styles.visible);
      rightChevronRef.current?.classList.add(styles.visible);
    } else {
      rightChevronRef.current?.classList.remove(styles.visible);
      leftChevronRef.current?.classList.add(styles.visible);
    }
  };

  const handleMouseLeave = () => {
    leftChevronRef.current?.classList.remove(styles.visible);
    rightChevronRef.current?.classList.remove(styles.visible);
  };

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>, fitToTile: () => void) => {
    if (event.clientX === mouseDownPos.clientX && event.clientY === mouseDownPos.clientY && containerRef.current) {
      const halfPoint = containerRef.current?.getBoundingClientRect().width / 2;
      if (halfPoint < event.clientX) {
        displayNext();
      } else {
        displayPrev();
      }
      fitToTile();
    }
  };

  return (
    <TileContainer>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, centerView }) => {
          resetRef.current = centerView;

          return <>
            <ButtonsOverlay>
              <ButtonsOverlay.TopRight>
                {props.showAddButton && <TileButton label={t('tooltips.splitScreen')} onClick={() => props.openSecondaryTile()} icon={<SplitScreen />} />}
                {props.showCloseButton && <TileButton label={t('tooltips.close')} onClick={() => props.setView(TreeDisplays.NULL)} icon={<Close />} />}
              </ButtonsOverlay.TopRight>
              <ButtonsOverlay.LeftCenter>
                <Button
                  ref={leftChevronRef}
                  kind="secondary"
                  className="flex items-center justify-center size-12 p-0 bg-[rgba(0,0,0,0.5)]"
                  onClick={event => {
                    resetRef.current!();
                    displayPrev(event);
                  }}
                >
                  <ArrowLeft />
                </Button>
              </ButtonsOverlay.LeftCenter>
              <ButtonsOverlay.RightCenter>
                <Button
                  ref={rightChevronRef}
                  kind="secondary"
                  className="flex items-center justify-center size-12 p-0 bg-[rgba(0,0,0,0.5)]"
                  onClick={event => {
                    resetRef.current!();
                    displayNext(event);
                  }}
                >
                  <ArrowRight />
                </Button>
              </ButtonsOverlay.RightCenter>
              <ButtonsOverlay.BottomRight>
                <ImageCounter
                  imagesLength={imageBlobs.length}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
                <div className="flex gap-4">
                  <ViewSelector setView={props.setView} view={props.view}/>
                  <div className="flex">
                    <TileButton label={t('tooltips.zoomIn')} onClick={() => zoomIn()} icon={<ZoomIn />} />
                    <TileButton label={t('tooltips.zoomOut')} onClick={() => zoomOut()} icon={<ZoomOut />} />
                  </div>
                </div>
              </ButtonsOverlay.BottomRight>
            </ButtonsOverlay>
            <TileContent>
              <div
                ref={containerRef}
                className={styles.imageContainer}
                style={{ backgroundImage: `url('${imageBlobs[activeIndex]}')` }}
                onMouseMove={e => handleMouseMove(e)}
                onMouseDown={e => setMouseDownPos({ clientX: e.clientX, clientY: e.clientY })}
                onMouseUp={e => handleMouseUp(e, resetRef.current!)}
                onMouseLeave={handleMouseLeave}
              >
                <div className={styles.blur}>
                  <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
                    <img
                      ref={imageRef}
                      className={styles.image}
                      src={imageBlobs[activeIndex]}
                      alt=""
                    />
                  </TransformComponent>
                </div>
              </div>
            </TileContent>
          </>;
        }}
      </TransformWrapper>
    </TileContainer>
  );
}

interface ImagesViewTileProps {
  treeImageBlobs: string[],
  view: TreeDisplays,
  setView: (view: TreeDisplays) => void,
  openSecondaryTile: () => void,
  showAddButton: boolean,
  showCloseButton: boolean
}
