import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pointInPolygon from 'point-in-polygon';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import SearchHintsSection, {
  AddressHint,
  ManagedAreaHint,
  TreeByCustomerIdHint,
  TreeByIdHint
} from './SearchHintsSection';
import { MapPin } from 'iconoir-react';
import { useDebouncedCallback } from '../../../../../hooks/useDebouncedCallback';
import { Organization } from '../../../../../organization/Organization';
import { AuthContext } from '../../../../../auth/AuthContext';
import { ManagedArea } from '../../../../../managed-area/ManagedArea';
import calculateMaxBounds from '../../../../../utils/calculateMaxBounds';
import { Search } from '@carbon/react';
import { Area, Tree } from '@carbon/icons-react';

export default function SearchBar({ organization, managedAreas }: SearchBarProps) {
  const { t } = useTranslation();
  const user = useContext(AuthContext).user;
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const { treeService, managedAreaService, addressService } = useContext(DependencyInjectionContext);

  const [treeByIdHints, setTreeByIdHints] = useState<TreeByIdHint[]>([]);
  const [treeByCustomerIdHints, setTreeByCustomerIdHints] = useState<TreeByCustomerIdHint[]>([]);
  const [managedAreasHints, setManagedAreasHints] = useState<ManagedAreaHint[]>([]);
  const [addressHints, setAddressHints] = useState<AddressHint[]>([]);
  const [noResultsFound, setNoResultsFound] = useState<boolean>(false);
  const [highlightedHint, setHighlightedHint] = useState<TreeByIdHint | TreeByCustomerIdHint | ManagedAreaHint | AddressHint | null>(null);
  const [searchPhrase, setSearchPhrase] = useState('');

  const searchRef = useRef<HTMLDivElement | null>(null);

  const clearSearchStates = () => {
    setSearchPhrase('');
    setManagedAreasHints([]);
    setTreeByIdHints([]);
    setTreeByCustomerIdHints([]);
    setAddressHints([]);
    setNoResultsFound(false);
  };

  const onValueChange = useDebouncedCallback((value: string) => onInputChange(value), 500);

  const onInputChange = async (value: string) => {
    if (value.length === 0) {
      clearSearchStates();
      return;
    }

    const treesById = await treeService.findByExternalId(organization.id, value, 5);
    const treesByCustomerTreeId = await treeService.findByCustomerTreeId(organization.id, value, 5);
    const managedAreas = await managedAreaService.findByName(organization.id, value, 5);
    const addresses = await addressService.searchByAddress(calculateMaxBounds(organization.boundaries.coordinates), value, 5, user.language);

    if (treesById.length === 0 && managedAreas.length === 0 && addresses.length === 0 && treesByCustomerTreeId.length === 0) {
      setManagedAreasHints([]);
      setTreeByIdHints([]);
      setTreeByCustomerIdHints([]);
      setAddressHints([]);
      return setNoResultsFound(true);
    }

    if (treesById.length > 0) {
      setTreeByIdHints(treesById.map(tree => ({
        id: tree.id,
        label: tree.externalId,
        description: tree.managedArea.code
      })));
    } else {
      setTreeByIdHints([]);
    }

    if (treesByCustomerTreeId.length > 0) {
      setTreeByCustomerIdHints(treesByCustomerTreeId.map(tree => ({
        id: tree.id,
        label: tree.customerTreeId,
        description: tree.managedArea.code
      })));
    } else {
      setTreeByCustomerIdHints([]);
    }

    if (managedAreas.length > 0) {
      setManagedAreasHints(managedAreas.map(area => ({ id: area.id, label: area.code })));
    } else {
      setManagedAreasHints([]);
    }

    if (addresses.length > 0) {
      setAddressHints(addresses.map(it => ({
        label: it.placeName,
        coordinates: it.geometry.coordinates,
        description: findManagedAreaByCoordinates(it.geometry.coordinates)
      })));
    } else {
      setAddressHints([]);
    }

    setNoResultsFound(false);
  };

  const findManagedAreaByCoordinates = coordinates => {
    if (!managedAreas) return;
    return managedAreas.find(area => pointInPolygon(coordinates, area.boundingBox.coordinates[0]))?.code;
  };

  const closeSideBar = () => {
    urlContext.setTreeId('');
    urlContext.setFilterPanelOpen(false);
  };

  const treeByIdHintSelect = (hint: TreeByIdHint | TreeByCustomerIdHint) => {
    closeSideBar();
    urlContext.setTreeId(hint.id);
    clearSearchStates();
  };

  const managedAreaHintSelect = (hint: ManagedAreaHint) => {
    closeSideBar();
    if (urlContext.getReverseMASelection()) {
      urlContext.setReverseMASelection(false);
    }
    urlContext.setManagedAreaIds([hint.id]);
    clearSearchStates();
  };

  const addressHintSelect = (hint: AddressHint) => {
    closeSideBar();
    urlContext.setPositionFromCoordinates(hint.coordinates);
    clearSearchStates();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
      clearSearchStates();
    }
  };

  const searchNavigation = (event: KeyboardEvent) => {
    if (!['ArrowDown', 'ArrowUp', 'Enter', 'Escape'].includes(event.code)) return;
    event.preventDefault();
    const allHints = [...treeByIdHints, ...treeByCustomerIdHints, ...managedAreasHints, ...addressHints];
    if (!highlightedHint) return setHighlightedHint(allHints[0]);
    const currentIndex = allHints.indexOf(highlightedHint);

    if (event.code === 'Escape') {
      clearSearchStates();
    }
    if (event.code === 'ArrowDown') {
      if (currentIndex === allHints.length - 1) {
        setHighlightedHint(allHints[0]);
      } else {
        setHighlightedHint(allHints[currentIndex + 1]);
      }
    }
    if (event.code === 'ArrowUp') {
      if (currentIndex === 0) {
        setHighlightedHint(allHints[allHints.length - 1]);
      } else {
        setHighlightedHint(allHints[currentIndex - 1]);
      }
    }
    if (event.code === 'Enter') {
      if (treeByIdHints.includes(highlightedHint as TreeByIdHint)) {
        treeByIdHintSelect(highlightedHint as TreeByIdHint);
      } else if (treeByCustomerIdHints.includes(highlightedHint as TreeByCustomerIdHint)) {
        treeByIdHintSelect(highlightedHint as TreeByCustomerIdHint);
      } else if (managedAreasHints.includes(highlightedHint as ManagedAreaHint)) {
        managedAreaHintSelect(highlightedHint as ManagedAreaHint);
      } else if (addressHints.includes(highlightedHint as AddressHint)) {
        addressHintSelect(highlightedHint as AddressHint);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [
    searchRef.current,
    JSON.stringify([treeByIdHints, treeByCustomerIdHints, managedAreasHints, addressHints]),
    highlightedHint,
    searchNavigation
  ]);
  return (
    <div ref={searchRef} className="w-[358px] relative">
      <Search
        size="lg"
        placeholder={t('navbar.searchBar.searchInputPlaceholder')}
        labelText="Search"
        closeButtonLabelText="Clear search input"
        onChange={e => {
          onValueChange(e.target.value);
          setSearchPhrase(e.target.value);
        }}
        onKeyDown={e => searchNavigation(e.nativeEvent)}
        value={searchPhrase}
        className="[&>input]:max-h-12 [&>button]:max-h-12"
      />

      <div className="absolute top-12 left-0 w-full bg-[var(--cds-background)]">
        {noResultsFound &&
          <h4 className="text-[#ff483e] flex justify-center my-2">{t('navbar.searchBar.searchInputErrorMessage')}</h4>}
        {Boolean(treeByIdHints.length) &&
          <>
            <SearchHintsSection
              onSelect={treeByIdHintSelect}
              hints={treeByIdHints}
              title={'navbar.searchBar.treesById'}
              icon={<Tree fontSize={12} />}
              highlightedHint={highlightedHint}
            />
          </>
        }
        {Boolean(treeByCustomerIdHints.length) &&
          <>
            {Boolean(treeByIdHints.length) && <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full"></div>}
            <SearchHintsSection
              onSelect={treeByIdHintSelect}
              hints={treeByCustomerIdHints}
              title={'navbar.searchBar.treesByCustomerId'}
              icon={<Tree fontSize={12} />}
              highlightedHint={highlightedHint}
            />
          </>
        }
        {Boolean(managedAreasHints.length) &&
          <>
            {(Boolean(treeByCustomerIdHints.length) || Boolean(treeByIdHints.length)) && <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full"></div>}
            <SearchHintsSection
              onSelect={managedAreaHintSelect}
              hints={managedAreasHints}
              title={'navbar.searchBar.managedAreas'}
              icon={<Area fontSize={12} />}
              highlightedHint={highlightedHint}
            />
          </>
        }
        {Boolean(addressHints.length) &&
          <>
            {(Boolean(treeByCustomerIdHints.length) || Boolean(treeByIdHints.length) || Boolean(managedAreasHints.length)) && <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full"></div>}
            <SearchHintsSection
              onSelect={addressHintSelect}
              hints={addressHints}
              title={'navbar.searchBar.addresses'}
              icon={<MapPin fontSize={12} />}
              highlightedHint={highlightedHint}
            />
          </>
        }
      </div>
    </div>
  );
}

interface SearchBarProps {
  organization: Organization,
  managedAreas?: ManagedArea[] | null
}
