import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ResetPasswordScreen } from '../../components/Auth/ResetPasswordScreen';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PasswordService } from '../../password/PasswordService';
import { Password } from '../../password/Password';
// import AuthPageLayout from '../../components/UI/Page/carbon/AuthPageLayout';
// import { Button, FluidForm } from '@carbon/react';
// import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
// import { PasswordRequirementsListItem } from '../CarbonSettings/UserPreferences';

const TranslatedErrorMessage = {
  Fallback: 'resetPassword.errors.fallback',
  'The given access token has expired': 'resetPassword.errors.expired',
  'accessToken should not be empty': 'resetPassword.errors.expired'
};

export default function ResetPassword() {
  const location = useLocation();
  const {
    t,
    i18n: { language: lang }
  } = useTranslation();
  const [error, setError] = useState<keyof typeof TranslatedErrorMessage | ''>('');
  const [isCompleted, setCompletionState] = useState(false);
  const [isLoading, setLoadingState] = useState(false);

  const errorMessage = (error && t(TranslatedErrorMessage[error])) || '';
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const accessToken = searchParams.get('accessToken') || '';

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerification, setNewPasswordVerification] = useState('');
  const submitButtonContainerRef = useRef<HTMLDivElement>(null);

  const passwordErrors = useMemo(() => {
    if (!newPassword) {
      return [];
    }
    return new Password(newPassword).getErrors();
  }, [newPassword]);

  useEffect(() => {
    const submitButton = submitButtonContainerRef.current?.querySelector('button');
    if (!submitButton) return;
    submitButton.disabled = !(passwordErrors.length === 0 && newPassword === newPasswordVerification);
  }, [newPassword, newPasswordVerification]);

  const onReset = useCallback(
    async (password: string) => {
      setError('');
      setLoadingState(true);
      try {
        await PasswordService.make().reset(accessToken, password, lang);
        setCompletionState(true);
      } catch (error) {
        setError((error as Error).message as unknown as '');
      }
      setLoadingState(false);
    },
    [accessToken, lang]
  );

  const handleFormSubmit = async () => {
    await onReset(newPassword);
  };

  // return (
  //   <AuthPageLayout pageTitle="authPageTitles.resetPassword">
  //     <AuthPageLayout.Content>
  //       <FluidForm onSubmit={handleFormSubmit}>
  //         <div className="mb-6 font-sm font-semibold">{t('userPreferences.changePassword')}</div>
  //         <div className="flex flex-col gap-6">
  //           <FluidTextInput
  //             type="password"
  //             labelText={t('userPreferences.newPasswordLabel')}
  //             id="new-password"
  //             value={newPassword}
  //             onChange={e => setNewPassword(e.target.value)}
  //           />
  //           <FluidTextInput
  //             type="password"
  //             labelText={t('userPreferences.newPasswordVerifyLabel')}
  //             id="verify-new-password"
  //             value={newPasswordVerification}
  //             onChange={e => setNewPasswordVerification(e.target.value)}
  //           />
  //           <div>
  //             <div className="mb-2 font-sm font-semibold">{t('password.title')}</div>
  //             <ul className="flex flex-col gap-2">
  //               <PasswordRequirementsListItem
  //                 isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.TooShort)}
  //                 label="be least 10 characters in length" />
  //               <PasswordRequirementsListItem
  //                 isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.NoSmallCaps)}
  //                 label="contain lower case letters (a-z)" />
  //               <PasswordRequirementsListItem
  //                 isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.NoUpperCase)}
  //                 label="contain upper case letters (A-Z)" />
  //               <PasswordRequirementsListItem
  //                 isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.NoSpecialCharacters)}
  //                 label="contain a special character ($€+!?;., etc...)" />
  //             </ul>
  //           </div>
  //         </div>
  //         <div ref={submitButtonContainerRef}>
  //           <Button className="float-right mt-8" type="submit" disabled={newPassword.length === 0}>{t('password.saveChanges')}</Button>
  //         </div>
  //       </FluidForm>
  //
  //     </AuthPageLayout.Content>
  //   </AuthPageLayout>
  // );

  return (
    <ResetPasswordScreen
      error={errorMessage}
      isCompleted={isCompleted}
      onReset={onReset}
      isLoading={isLoading}
    />
  );
}
