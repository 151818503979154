import { StreetViewProps } from './useStreetViewProps';
import { TileButton } from '../TileLayout';
import { Reset, RulerAlt } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import ZoomIn from '@carbon/icons-react/lib/ZoomIn';
import ZoomOut from '@carbon/icons-react/lib/ZoomOut';

export default function StreetViewBottomCenterButtons(props: StreetViewProps) {
  const { t } = useTranslation();

  return <div className="flex gap-4">
    <div className="flex gap-0.5">
      <TileButton
        label={t('tooltips.lineMeasurement')}
        onClick={() => props.setLineMeasurementEnabled(state => !state)}
        icon={<RulerAlt/>}
        active={props.lineMeasurementEnabled}
      />
    </div>
    <div className="flex gap-0.5">
      <TileButton
        label={t('tooltips.resetView')}
        onClick={() => props.resetView()}
        icon={<Reset/>}
      />
      <div className="flex">
        <TileButton label={t('tooltips.zoomIn')} onClick={() => props.viewRef.current?.zoomIn()} icon={<ZoomIn />} />
        <TileButton label={t('tooltips.zoomOut')} onClick={() => props.viewRef.current?.zoomOut()} icon={<ZoomOut />} />
      </div>
    </div>
  </div>;
}
