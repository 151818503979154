import mapboxgl, { CustomLayerInterface, EventData, MapEventType } from 'mapbox-gl';
import * as THREE from 'three';
import { MercatorCoordinate } from '../../MercatorCoordinate';
import { DisplayableTreeProperty, Tree, TreeDisplayConfiguration } from '../../../../tree/Tree';
import { GeoJSONTree } from '../../../../tree/GeoJSONTree';
import { TFunction } from 'react-i18next';
import { ManagedArea } from '../../../../managed-area/ManagedArea';
import { MapboxPopupTreeNamecard } from '../../tree-namecard/MapboxPopupTreeNamecard';
import { TreeNamecard } from '../../tree-namecard/TreeNamecard';
import { HoverTimerForTreeId } from '../../panoramic-view/TreeMarkerHandler';
import TreeService from '../../../../tree/TreeService';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { RefObject } from 'react';
import { HTMLTreeMarker } from '../../tree-marker/html-tree-marker/HtmlTreeMarker';
import { Organization } from '../../../../organization/Organization';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import {
  AdvancedFilter,
  AdvancedFilterConfiguration,
  SigmaBoundary
} from '../../table-view/advanced-filter/AdvancedFilter';
import { Account } from '../../../../account/Account';
import { Cohort } from '../../../LegacyDetails/LegacyDetails';
import { ColoringType } from '../../../../components/Navbar/PropertyLegend';
import FilterConfig from '../../../TaskManager/create/FilterConfig';
import FilterCompound from '../../workspace/filter-compound/FilterCompound';
import { Flippers } from '../../../../switches/Flippers';

export class TreeMarkerLayer implements CustomLayerInterface {
  static readonly ID = 'custom-tree-layer';
  static createTree(data: GeoJSONTree, managedAreaCodeMap: { [key: string]: string }, coords?: [number, number, number]): Tree {
    return new Tree(
      data.properties.id,
      data.properties.managedAreaId,
      data.properties.height,
      data.properties.trunkHeight,
      data.properties.trunkWidth,
      data.properties.trunkEllipseRadiusA,
      data.properties.trunkEllipseRadiusB,
      data.properties.trunkCircumference,
      data.properties.canopyHeight,
      data.properties.canopyWidth,
      data.properties.canopyEllipseRadiusA,
      data.properties.canopyEllipseRadiusB,
      data.properties.canopyCircumference,
      NaN,
      [NaN, NaN],
      data.properties.leafArea,
      data.properties.leafBiomass,
      data.properties.leafAreaIndex,
      data.properties.carbonStorage,
      data.properties.grossCarbonSequestration,
      data.properties.no2,
      data.properties.so2,
      data.properties.pm25,
      data.properties.co,
      data.properties.o3,
      data.properties.ndvi,
      data.properties.treeHealth,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      data.properties.potentialEvapotranspiration,
      data.properties.transpiration,
      data.properties.oxygenProduction,
      data.properties.safetyFactorAt80Kmh,
      data.properties.safetyFactorAtDefaultWindSpeed,
      coords || data.geometry.coordinates,
      JSON.parse(data.properties.safetyFactors || '{}'),
      data.properties.externalId,
      data.properties.customerTreeId,
      data.properties.customerTagId,
      data.properties.customerSiteId,
      [NaN, NaN, NaN],
      [],
      new ManagedArea(
        data.properties.managedAreaId,
        managedAreaCodeMap[data.properties.managedAreaId],
        '',
        ManagedArea.EMPTY_BOUNDING_BOX
      ),
      data.properties.trunkDiameter,
      data.properties.genus || '-',
      data.properties.species || '-',
      data.properties.scientificName || '-',
      '',
      '',
      data.properties.evaporation,
      data.properties.waterIntercepted,
      data.properties.avoidedRunoff,
      [NaN, NaN, NaN],
      data.properties.leaningAngle,
      data.properties.treeValueCavat,
      data.properties.treeValueKoch,
      data.properties.treeValueRado,
      data.properties.thermalComfort,
      '',
      data.properties.dieback,
      { cohortValues: JSON.parse(data.properties.cohortValues || '{}') } as Cohort,
      data.properties.status,
      data.properties.vitalityVigor,
      NaN,
      NaN,
      NaN,
      NaN,
      data.properties.viStatus,
      JSON.parse(data.properties.mitigations || '{}'),
      '',
      '',
      '',
      NaN,
      null,
      null,
      null,
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      data.properties.overallOutlierIndex
    );
  }

  readonly id = TreeMarkerLayer.ID;

  readonly type = 'custom' as const;
  private map?: mapboxgl.Map;
  private selectedPropertyConfig: PropertyConfiguration | null = null;
  private camera?: THREE.Camera;
  private scene = new THREE.Scene();
  private renderer?: THREE.WebGLRenderer;
  private markers: HTMLTreeMarker[] = [];
  private trees: GeoJSONTree[] = [];
  private managedAreaCodeMap = {};
  private treeNamecard: TreeNamecard<mapboxgl.Map> = new MapboxPopupTreeNamecard(
    this.treeService,
    this.t,
    (tree: Tree) => this.onOpen(tree.id),
    this.organization,
    0
  );
  private hoverTimer = new HoverTimerForTreeId();
  private htmlRenderer?: CSS2DRenderer;

  constructor(
    private readonly treeService: TreeService,
    private readonly apiUrl: string,
    private organization: Organization,
    private displayConfiguration: TreeDisplayConfiguration,
    private selectedTreeId: string | null,
    private hideLabel: boolean,
    private readonly t: TFunction,
    private readonly containerRef: RefObject<HTMLDivElement>,
    private hideMarkers: boolean,
    private advancedFilterConfiguration: AdvancedFilterConfiguration,
    private filterConfig: FilterConfig,
    private account: Account,
    private onSelect: (treeId: string) => unknown,
    private onOpen: (treeId: string) => unknown,
    private readonly tracking,
    private coloringType?: ColoringType
  ) {}

  private readonly onData = event => this.createMarkers(event);
  private onResize: () => void = () => {
  };

  setSelectedTreeId(treeId: string | null) {
    this.markers.forEach(it => it.resetSelectionState());
    if (!treeId) {
      this.selectedTreeId = null;
      return;
    }
    this.selectedTreeId = treeId;
    this.treeNamecard.hideImmediately();
  }

  setSelectedPropertyConfig(selectedPropertyConfig: PropertyConfiguration | null) {
    this.selectedPropertyConfig = selectedPropertyConfig;
  }

  setSelectedTreePropertyRangeIndex(index: number) {
    this.markers.forEach(marker => marker.changeOpacity(this.selectedPropertyConfig, index, this.displayConfiguration.windSpeed));
  }

  setSelectedPropertyCohortBoundary(boundary: SigmaBoundary | null) {
    if (this.selectedPropertyConfig?.property) {
      this.markers.forEach(
        marker => marker.changeOpacityForCohort(this.selectedPropertyConfig!.property, boundary)
      );
    }
  }

  setOnSelectCallback(onSelect: (treeId: string) => unknown) {
    this.onSelect = onSelect;
    this.markers.forEach(it => it.setOnSelectCallback(this.onSelect));
  }

  setOnOpenCallback(onOpen: (treeId: string) => unknown) {
    this.onOpen = onOpen;
  }

  setOrganization(organization: Organization) {
    this.treeNamecard.hideImmediately();
    this.treeNamecard.setOrganization(organization);

    this.removeTreeLoaderLayer();
    this.removeTreeSource();

    this.scene.clear();
    this.markers.forEach(it => it.removeListeners());
    this.markers = [];
    this.organization = organization;
    this.addTreeSource();
    this.addTreeLoaderLayer();

    this.map?.triggerRepaint();
  }

  updateDisplayConfiguration(
    displayConfiguration: TreeDisplayConfiguration,
    advancedFilterConfiguration: AdvancedFilterConfiguration,
    filterConfig: FilterConfig,
    hideMarkers: boolean,
    selectedPropertyConfig: PropertyConfiguration | null,
    hideLabel = false,
    account: Account,
    coloringType: ColoringType
  ) {
    this.displayConfiguration = displayConfiguration;
    this.advancedFilterConfiguration = advancedFilterConfiguration;
    this.filterConfig = filterConfig;
    this.coloringType = coloringType;
    this.hideLabel = hideLabel;
    this.hideMarkers = hideMarkers;

    this.resetLayer();

    if (this.map?.getLayer(TreeMarkerLayer.ID)) {
      this.map?.setLayoutProperty(TreeMarkerLayer.ID, 'visibility', hideMarkers ? 'none' : 'visible');
    }

    this.markers.forEach(marker => marker.applyDisplayConfiguration(
      displayConfiguration,
      new AdvancedFilter(this.advancedFilterConfiguration),
      FilterCompound.fromConfig(this.filterConfig),
      hideMarkers,
      selectedPropertyConfig,
      coloringType,
      hideLabel,
      account
    ));
    const property = displayConfiguration.property ?? null;
    const windSpeed = displayConfiguration.windSpeed;
    this.treeNamecard.showProperties(property, selectedPropertyConfig).setWindSpeed(windSpeed);
    this.map?.triggerRepaint();
  }

  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    this.htmlRenderer = new CSS2DRenderer();
    this.htmlRenderer.domElement.style.position = 'absolute';
    this.htmlRenderer.domElement.style.top = '0px';
    this.htmlRenderer.domElement.style.left = '0px';

    this.containerRef.current!.insertBefore(this.htmlRenderer.domElement, this.containerRef.current!.firstChild);

    this.map = map;

    this.camera = new THREE.PerspectiveCamera();
    this.scene = new THREE.Scene();

    const canvas = map.getCanvas();

    this.htmlRenderer.setSize(
      Number(canvas.style.width.slice(0, canvas.style.width.indexOf('px'))),
      Number(canvas.style.height.slice(0, canvas.style.height.indexOf('px')))
    );

    this.renderer = new THREE.WebGLRenderer({
      canvas,
      context: gl,
      antialias: false,
      stencil: false,
      powerPreference: 'high-performance'
    });
    this.renderer.sortObjects = false;
    this.renderer.shadowMap.enabled = false;
    this.renderer.autoClear = false;

    this.addTreeSource();
    this.addTreeLoaderLayer();

    this.onResize = this.setSize.bind(this);
    window.addEventListener('resize', this.onResize);

    map.on('data', this.onData);
  }

  onRemove(map: mapboxgl.Map) {
    map.off('data', this.onData);

    this.removeTreeLoaderLayer();
    this.removeTreeSource();

    this.scene.clear();
    this.markers.forEach(it => it.removeListeners());
    this.markers = [];

    if (this.htmlRenderer?.domElement && this.containerRef.current) {
      this.containerRef.current.removeChild(this.htmlRenderer?.domElement);
    }
    window.removeEventListener('resize', this.onResize);
  }

  prerender() {
    this.scene.visible = (this.map?.getZoom() ?? 0) >= this.organization.getClusteringZoomLevel();

    if (!this.scene.visible && this.htmlRenderer?.domElement.innerHTML) {
      this.treeNamecard.hide();
      this.htmlRenderer.domElement.innerHTML = '';
    }

    if (this.trees.length === 0 || !this.scene.visible) {
      return;
    }

    this.updateMarkers(this.map!.getBounds());
    const mapCenterAsMercatorCoordinates = new MercatorCoordinate(this.map!.getCenter().toArray());
    const scale = mapCenterAsMercatorCoordinates.scale;

    for (let i = this.markers.length - 1; i >= 0; i--) {
      this.markers[i].setPosition(mapCenterAsMercatorCoordinates.x, mapCenterAsMercatorCoordinates.y, scale);
    }
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    if (!this.scene.visible) {
      return;
    }

    gl.clear(gl.DEPTH_BUFFER_BIT);

    if (this.markers.length > 0) {
      const mapCenterAsMercatorCoordinates = new MercatorCoordinate(this.map!.getCenter().toArray());
      const scale = mapCenterAsMercatorCoordinates.scale;

      this.camera!.projectionMatrix = new THREE.Matrix4()
        .fromArray(matrix)
        .multiply(
          new THREE.Matrix4()
            .makeTranslation(mapCenterAsMercatorCoordinates.x, mapCenterAsMercatorCoordinates.y, 0)
            .scale(new THREE.Vector3(scale, -scale, scale))
        );
    }
    this.renderer!.resetState();
    this.renderer!.render(this.scene, this.camera!);
    this.htmlRenderer!.render(this.scene, this.camera!);
  }

  resize() {
    const canvas = this.map?.getCanvas();
    if (!this.htmlRenderer || !canvas || !this.camera) {
      return;
    }

    this.htmlRenderer.setSize(
      Number(canvas.style.width.slice(0, canvas.style.width.indexOf('px'))),
      Number(canvas.style.height.slice(0, canvas.style.height.indexOf('px')))
    );
  }

  getOrganizationId() {
    return this.organization.id;
  }

  private readonly onMouseEnter = (tree: Tree, release24q3 = false) => {
    if (tree.id === this.selectedTreeId) return;
    this.hoverTimer.setTimer(tree.id, () => {
      if (!release24q3) this.treeNamecard.show(this.map!, tree, this.selectedPropertyConfig);
      this.tracking.track(this.tracking.events.TREE_NAME_CARD_SHOWN_FROM_MAP, { tree });
    });
  };

  private readonly onMouseLeave = () => {
    if (!this.map) return;
    this.map.getCanvas().style.cursor = 'unset';
    this.treeNamecard.hide();
    this.hoverTimer.resetTimer();
  };

  private readonly onMouseWheel = (event: WheelEvent) => {
    this.map?.getCanvas().dispatchEvent(new WheelEvent(event.type, event));
  };

  private readonly onMouseDown = (event: MouseEvent) => {
    this.map?.getCanvas().dispatchEvent(new MouseEvent(event.type, event));
  };

  private updateMarkers(bounds: mapboxgl.LngLatBounds) {
    const visibleTreeData = this.trees.filter(tree => {
      const [lng, lat] = tree.geometry.coordinates;
      return bounds.contains({ lng, lat });
    });

    const toBeRemoved = this.markers.filter(marker => !visibleTreeData.some(tree => marker.belongsTo(tree.properties.id)));
    toBeRemoved.forEach(it => {
      it.removeFrom(this.scene);
      this.markers.splice(this.markers.indexOf(it), 1);
      it.removeListeners();
    });

    const toBeAdded = visibleTreeData.filter(tree => !this.markers.some(marker => marker.belongsTo(tree.properties.id)));
    this.markers.push(...toBeAdded.map(data => {
      const marker = HTMLTreeMarker.create(
        TreeMarkerLayer.createTree(data, this.managedAreaCodeMap),
        tree => this.onMouseEnter(tree, this.organization.isEnabled(Flippers.carbonRedesign)),
        this.onMouseLeave,
        this.onMouseWheel,
        this.onMouseDown
      )
        .applyDisplayConfiguration(
          this.displayConfiguration,
          new AdvancedFilter(this.advancedFilterConfiguration),
          FilterCompound.fromConfig(this.filterConfig),
          this.hideMarkers,
          this.selectedPropertyConfig,
          this.coloringType || ColoringType.LABEL,
          this.hideLabel,
          this.account
        );
      marker.setOnSelectCallback(this.onSelect);
      marker.addTo(this.scene);
      return marker;
    }));
    this.markers.find(it => it.tree.id === this.selectedTreeId)?.select();
  }

  private createMarkers(event: MapEventType['data'] & EventData) {
    if (event.sourceId !== 'trees') {
      return;
    }
    const treeData = event.target.querySourceFeatures('trees', { sourceLayer: 'trees' }) as unknown as GeoJSONTree[];
    if (treeData.length === 0) return;

    const managedAreas = event.target.querySourceFeatures('managed-areas', { sourceLayer: 'managedAreas' });

    managedAreas.forEach(area => this.managedAreaCodeMap[area.properties?.id] = area.properties?.code);

    if (managedAreas.length > 0) {
      this.updateManagedAreasInExistingMarkers(this.managedAreaCodeMap);
    }

    const newTreeData = treeData.filter(tree => !this.trees.some(treeData => treeData.properties.id === tree.properties.id));
    this.trees.push(...newTreeData);
    this.map?.triggerRepaint();
  }

  private updateManagedAreasInExistingMarkers(managedAreaCodeMap) {
    this.markers.filter(marker => !marker.tree.managedArea.code)
      .forEach(marker => {
        marker.tree.setManagedArea(
          new ManagedArea(
            marker.tree.managedAreaId,
            managedAreaCodeMap[marker.tree.managedAreaId],
            '',
            ManagedArea.EMPTY_BOUNDING_BOX
          ));
      });
  }

  private addTreeSource() {
    if (!this.organization.id) return;

    let properties: string[] = [
      ...this.advancedFilterConfiguration.includeOnly.map(it => it[0]),
      ...this.advancedFilterConfiguration.min.map(it => it[0]),
      ...this.advancedFilterConfiguration.max.map(it => it[0]),
      ...this.advancedFilterConfiguration.propertyConfigs.map(it => it.property),
      ...this.advancedFilterConfiguration.cohort.map(it => it.property),
      ...FilterCompound.fromConfig(this.filterConfig).getFields(),
      ...(this.selectedPropertyConfig ? [this.selectedPropertyConfig.property] : []),
      ...this.displayConfiguration.filters.flatMap(filter => [
        ...filter.numericPredicates.map(it => it.property),
        ...filter.enumPredicates.map(it => it.property),
        ...filter.propertyConfigurationPredicates.map(it => it.property)
      ])
    ];
    if (properties.some(it => it.includes('safetyFactors'))) {
      properties = properties.filter(it => !it.includes('safetyFactors'));
      properties.push('safetyFactors' as DisplayableTreeProperty);
    }
    const cohortNeeded = this.advancedFilterConfiguration.cohort.length > 0 || this.coloringType === ColoringType.COHORT;
    const cohortQuery = cohortNeeded ? '&cohort=true' : '';
    const propertiesQuery = properties.map(it => `&field=${it}`).join('');
    this.map?.addSource('trees', {
      type: 'vector',
      tiles: [`${this.apiUrl}/v1/organizations/${this.organization.id}/mvt/trees?x={x}&y={y}&z={z}${propertiesQuery}${cohortQuery}`],
      minzoom: this.organization.getClusteringZoomLevel()
    });
  }

  private removeTreeSource() {
    if (this.map?.getSource('trees')) {
      this.map.removeSource('trees');
    }
  }

  private addTreeLoaderLayer() {
    if (!this.organization.id) return;

    this.map?.addLayer({
      id: 'tree-loader-layer',
      type: 'circle',
      source: 'trees',
      'source-layer': 'trees',
      paint: { 'circle-opacity': 0 }
    });
  }

  private removeTreeLoaderLayer() {
    if (this.map?.getLayer('tree-loader-layer')) {
      this.map.removeLayer('tree-loader-layer');
    }
  }

  private setSize() {
    const canvas = this.map?.getCanvas();
    if (!canvas || !this.htmlRenderer || !this.renderer || !this.camera) {
      return;
    }

    this.htmlRenderer.setSize(canvas.clientWidth, canvas.clientHeight);

    this.renderer.render(this.scene, this.camera);
  }

  private resetLayer() {
    this.removeTreeLoaderLayer();
    this.removeTreeSource();
    this.markers.forEach(it => {
      it.removeFrom(this.scene);
      it.removeListeners();
    });
    this.trees = [];
    this.markers = [];
    this.addTreeSource();
    this.addTreeLoaderLayer();
  }
}
