import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { DetailsGroup } from '../../../components/UI/DetailsGroup/DetailsGroup';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import DetailedTree from '../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { ConsequencesOfFailure, LikelihoodOfFailure, LikelihoodOfImpact } from './RiskCategorization';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import { DataStatus } from '../../../tree/DataStatus';
import Toggle from '../../../components/UI/Toggle/Toggle';
import IsaRiskStandards, { IsaLikelihood, IsaRiskRating } from './isaRiskStandards';
import { InspectionLimitation } from '../../../tree/InspectionLimitation';
import { RiskRating } from '../../../property-enums/RiskRating';
import { PlusCircle, Trash } from 'iconoir-react';
import { TreeValueMethod } from '../../../property-enums/TreeValueMethod';
import { DetailsContext, ExtraObject } from '../CarbonDetails';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { TreeDisplays } from '../TreeDisplayTile/TreeDisplays';
import { JobColumnName } from '../../TaskManager/create/JobColumnName';
import { useTaskTemplates } from '../../TaskManager/create/useTaskTemplates';
import { Flippers } from '../../../switches/Flippers';
import {
  Accordion,
  AccordionItem,
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  StructuredListWrapper
} from '@carbon/react';
import FluidDropdown from '@carbon/react/es/components/FluidDropdown/FluidDropdown';
import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
import { View, ViewFilled, WarningFilled } from '@carbon/icons-react';

export const riskColorMap = new Map<string, string>(
  [
    ['low', 'bg-greehill-500'],
    ['moderate', 'bg-summer-yellow-500'],
    ['high', 'bg-warn-chardonnay-500'],
    ['extreme', 'bg-danger-500']
  ]
);

export default function CurrentState({ tree, handleChange, disableEdit, taskId }: {
  tree: DetailedTree | null,
  handleChange: <T>(property: string, value: T) => void,
  disableEdit: boolean,
  taskId: string
}) {
  const {
    setRulers,
    rulers,
    setRiskOverlayIsVisible,
    showCRZ,
    setCRZVisibility,
    showSCRZ,
    setSCRZVisibility
  } = useContext(DetailsContext);
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const [openAccordionItem, setOpenAccordionItem] = useState<string | null>(null);
  const [focusedElement, setFocusedElement] = useState<string | null>(null);
  const { organization } = useCurrentAccount();
  const { taskTemplates } = useTaskTemplates(organization.id);
  const mitigationOptions = taskTemplates
    .filter(template => template.jobColumnName !== JobColumnName.Inspection)
    .map(template => ({ id: template.id, translationKey: template.name }));
  const remainingMitigationOptions = mitigationOptions
    .filter(template => (tree?.mitigations || []).every(mitigation => mitigation.taskTemplateId !== template.id));

  const toggleProperty = useCallback((property: ExtraObject) => {
    if (rulers.includes(property)) {
      const index = rulers.indexOf(property);
      rulers.splice(index, 1);
      setRulers([...rulers]);
    } else {
      setRulers((prev: ExtraObject[]) => [...prev, property]);
    }
  }, [rulers]);

  useEffect(() => {
    if (openAccordionItem === 'Structural assessment') {
      setRiskOverlayIsVisible(true);
    } else {
      setRiskOverlayIsVisible(false);
    }
  }, [openAccordionItem]);

  const getAccordionItemClassName = useCallback((label: string) => {
    return (openAccordionItem === label ? '[&>button]:text-[var(--cds-link-primary-hover)]' : '[&>button>div]:text-[var(--cds-text-secondary)]') +
        ' [&>button]:h-12 [&>button>div]:font-semibold' +
        ` ${openAccordionItem === label ? '!border-t-[var(--cds-interactive)] !border-b-[var(--cds-interactive)] !border-t-2 !border-b-2' : ''}`;
  }, [openAccordionItem]);

  if (!tree) return null;

  const potentialTargetsChange = (index: number, property: string, value: string) => {
    handleChange('potentialTargets',
      (tree?.potentialTargets || []).map((target, i) => i === index ? {
        ...target,
        [property]: value
      } : target)
    );
  };

  const riskRatings = tree.potentialTargets?.map(it =>
    IsaRiskStandards.getRiskRating(
      Object.values(LikelihoodOfFailure).indexOf(it.likelihoodOfFailure),
      Object.values(LikelihoodOfImpact).indexOf(it.likelihoodOfImpact),
      Object.values(ConsequencesOfFailure).indexOf(it.consequences)
    )
  ) || [IsaRiskRating.low];
  const maxRiskRating = 'high'; //IsaRiskRating[Math.max(...riskRatings)];

  const toggleAllProperties = () => {
    if (rulers.length === 5 && showCRZ && showSCRZ) {
      setRulers([]);
      setCRZVisibility(false);
      setSCRZVisibility(false);
    } else {
      setRulers([
        DisplayableTreeProperty.CanopyHeight,
        DisplayableTreeProperty.CanopyWidth,
        DisplayableTreeProperty.TrunkDiameter,
        DisplayableTreeProperty.TrunkHeight,
        DisplayableTreeProperty.Height
      ]);
      setCRZVisibility(true);
      setSCRZVisibility(true);
    }
  };

  const getDimensionsAction = () => {
    if (urlContext.getDetailsTreeDisplay() === TreeDisplays.IMAGES || openAccordionItem !== 'Dimensions') return null;
    if (rulers.length === 5 && showCRZ && showSCRZ) {
      return <ViewFilled
        onClick={e => {
          e.stopPropagation();
          toggleAllProperties();
        }}/>;
    }
    return <View
      onClick={e => {
        e.stopPropagation();
        toggleAllProperties();
      }}/>;
  };

  const handleToggle = (id: string) => {
    setOpenAccordionItem(prev => (prev === id ? null : id));
  };

  const likelyHoodOfFailureOptions = Object.values(LikelihoodOfFailure).filter(it => typeof it === 'string').map(likelihood => ({ id: likelihood, translationKey: likelihood }));

  const consequencesOfFailureOptions = Object.values(ConsequencesOfFailure).filter(it => typeof it === 'string').map(consequence => ({ id: consequence, translationKey: consequence }));

  const impactOptions = Object.values(LikelihoodOfImpact).filter(it => typeof it === 'string').map(impact => ({ id: impact, translationKey: impact }));

  return (
    <DetailsGroup className="twp">
      <div className="flex flex-col gap-12 border-b-0">
        <StructuredListWrapper>
          <StructuredListBody>
            {!organization.isEnabled(Flippers.davey) &&
              <StructuredListRow head className="grid grid-cols-3">
                <StructuredListCell className="px-4 py-3.5 col-start-1 col-end-2">
                  {t('details.properties.condition')}
                </StructuredListCell>
                <StructuredListCell className="px-4 py-3.5 text-[var(--cds-text-primary)] col-start-2 col-end-4">
                  {tree.condition ? t('details.properties.conditionTypes.' + tree.condition) : '-'}
                </StructuredListCell>
              </StructuredListRow>
            }
            <StructuredListRow head={organization.isEnabled(Flippers.davey)} className="grid grid-cols-3">
              <StructuredListCell className="px-4 py-3.5 col-start-1 col-end-2">
                {t('taskManager.currentState.overallTreeRiskRating')}
              </StructuredListCell>
              <StructuredListCell className="px-4 py-3.5 text-[var(--cds-text-primary)] col-start-2 col-end-4 flex items-center gap-4">
                {maxRiskRating ? t(`treeDetails.riskRating.isaRiskRating.${maxRiskRating}`) : '-'}
                <div className={`size-3 rounded-full ${riskColorMap.get(maxRiskRating)}`}/>
              </StructuredListCell>
            </StructuredListRow>
          </StructuredListBody>
        </StructuredListWrapper>
      </div>
      {tree.outliers?.length > 0 && (
        <div className="p-3 my-12 border border-[var(--cds-support-warning)] border-l-4">
          <div className="flex gap-2 items-center mb-2">
            <WarningFilled className="text-[var(--cds-support-warning)]" />
            <div className="font-bold">{t('details.outlierInformation')}</div>
          </div>
          <ul className="ml-11 list-disc">
            {Tree.OUTLIER_PROPERTIES.map((outlier, index) => (
              outlier !== DisplayableTreeProperty.OverallOutlierIndex ?
                <li key={outlier + index} className="text-sm flex gap-1">
                  <div className="text-[var(--cds-text-secondary)]">{t('tree.' + outlier)}:</div>
                  <div>
                    {
                      tree[outlier] === null ?
                        <span className="text-[var(--cds-text-secondary)]">{t('treeDetails.noData')}</span>
                        : <span>{t(`details.properties.booleanLabels.${tree[outlier]}`)}</span>
                    }
                  </div>
                </li> : null
            ))}
          </ul>
        </div>
      )}
      <Accordion>
        <AccordionItem
          title={<div className="flex justify-between">
            <div>{t('taskManager.currentState.dimensions')}</div>
            <div className="mr-4 text-[var(--cds-text-primary)]">{getDimensionsAction()}</div>
          </div>}
          open={openAccordionItem === t('taskManager.currentState.dimensions')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.dimensions'))}
          className={getAccordionItemClassName(t('taskManager.currentState.dimensions'))}
        >
          <PropertyFieldGroup>
            <PropertyField
              property={DisplayableTreeProperty.Height}
              value={tree?.height}
              action={<RulerToggle
                property={DisplayableTreeProperty.Height}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.Height)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.TrunkHeight}
              value={tree?.trunkHeight}
              action={<RulerToggle
                property={DisplayableTreeProperty.TrunkHeight}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.TrunkHeight)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.TrunkDiameter}
              value={tree?.trunkDiameter}
              action={<RulerToggle
                property={DisplayableTreeProperty.TrunkDiameter}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.TrunkDiameter)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.CanopyHeight}
              value={tree?.canopyHeight}
              action={<RulerToggle
                property={DisplayableTreeProperty.CanopyHeight}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.CanopyHeight)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.CanopyWidth}
              value={tree?.canopyWidth}
              action={<RulerToggle
                property={DisplayableTreeProperty.CanopyWidth}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.CanopyWidth)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.CriticalRootZone}
              value={tree?.criticalRootZone}
              action={urlContext.getDetailsTreeDisplay() === TreeDisplays.STREET_VIEW ? <RulerToggle
                property={DisplayableTreeProperty.CriticalRootZone}
                toggleProperty={() => setCRZVisibility(!showCRZ)}
                selected={showCRZ}
              /> : null}
            />
            <PropertyField
              property={DisplayableTreeProperty.StructuralCriticalRootZone}
              value={tree?.structuralCriticalRootZone}
              action={urlContext.getDetailsTreeDisplay() === TreeDisplays.STREET_VIEW ? <RulerToggle
                property={DisplayableTreeProperty.StructuralCriticalRootZone}
                toggleProperty={() => setSCRZVisibility(!showSCRZ)}
                selected={showSCRZ}
              /> : null}
            />
            <PropertyField
              property={DisplayableTreeProperty.NumberOfStems}
              value={tree?.numberOfStems}
              action={<div className="size-6"/>}
            />
          </PropertyFieldGroup>
        </AccordionItem>
        <AccordionItem
          title={t('taskManager.currentState.structuralAssessment')}
          open={openAccordionItem === t('taskManager.currentState.structuralAssessment')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.structuralAssessment'))}
          className={getAccordionItemClassName(t('taskManager.currentState.structuralAssessment'))}
        >
          <PropertyFieldGroup title={t('taskManager.currentState.stability')}>
            <PropertyField property={DisplayableTreeProperty.Slenderness} value={tree?.slenderness}/>
            <PropertyField property={DisplayableTreeProperty.SafetyFactorAt80Kmh} value={tree?.safetyFactorAt80Kmh}/>
            <PropertyField property={'criticalWindSpeed'} value={tree?.criticalWindSpeed}/>
            <PropertyField
              property={DisplayableTreeProperty.AbsoluteWeakestPoint}
              value={tree?.safetyFactors?.at(13)?.weakestPoint}
            />
          </PropertyFieldGroup>
          <PropertyFieldGroup title={t('taskManager.currentState.limbs')}>
            <div className="flex items-center gap-2 p-4">
              <div className="w-1/3 text-[var(--cds-text-secondary)] text-sm">{t('details.properties.numberOfLimbs')}</div>
              <div className="w-2/3 font-medium">
                {tree.limbs.length ?
                  tree.limbs.map((limb, index) => (
                    <div className="flex items-end gap-1" key={`limb-${index + limb.diameter}`}>
                      <div>Limb #{index + 1}: {limb.diameter}</div>
                      <div className="text-[13px] text-[var(--cds-text-secondary)] font-normal">
                        {Tree.getUnit(DisplayableTreeProperty.LimbDiameter, organization)}
                      </div>
                    </div>
                  ))
                  :
                  <span className="text-outer-space-500">-</span>
                }
              </div>
            </div>
            <PropertyField property={DisplayableTreeProperty.CoDominantStems} value={tree?.coDominantStems}/>
            <PropertyField property={DisplayableTreeProperty.IncludedBark} value={tree?.includedBark}/>
            <PropertyField property={'fork'} value={tree?.fork}/>
            <PropertyField property={'crossSectionalShape'} value={tree?.crossSectionalShape}/>
          </PropertyFieldGroup>
        </AccordionItem>
        <AccordionItem
          title={t('taskManager.currentState.healthAndVitality')}
          open={openAccordionItem === t('taskManager.currentState.healthAndVitality')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.healthAndVitality'))}
          className={getAccordionItemClassName(t('taskManager.currentState.healthAndVitality'))}
        >
          <PropertyFieldGroup title={t('taskManager.currentState.conditionAndVigor')}>
            <PropertyField property={DisplayableTreeProperty.Dieback} value={tree?.dieback}/>
            <PropertyField property={'basalAreaIncrement' as DisplayableTreeProperty} value={undefined}/>
            {!organization.isEnabled(Flippers.davey) && <PropertyField property={DisplayableTreeProperty.Condition} value={tree?.condition}/>}
            <PropertyField property={DisplayableTreeProperty.NDVI} value={tree?.ndvi}/>
            <PropertyField
              property={DisplayableTreeProperty.CrownTransparency}
              value={tree?.crownTransparency ? t('details.properties.crownTransparencyTypes.' + tree?.crownTransparency).toString() : null}
            />
          </PropertyFieldGroup>
          <PropertyFieldGroup title={t('taskManager.currentState.foliage')}>
            <PropertyField property={DisplayableTreeProperty.LeafArea} value={tree?.leafArea}/>
            <PropertyField property={DisplayableTreeProperty.LeafAreaIndex} value={tree?.leafAreaIndex}/>
            <PropertyField property={DisplayableTreeProperty.CrownVolume} value={tree?.crownVolume}/>
            <PropertyField property={DisplayableTreeProperty.LeafBiomass} value={tree?.leafBiomass}/>
          </PropertyFieldGroup>
        </AccordionItem>
        <AccordionItem
          title={t('taskManager.currentState.benefits')}
          open={openAccordionItem === t('taskManager.currentState.benefits')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.benefits'))}
          className={getAccordionItemClassName(t('taskManager.currentState.benefits'))}
        >
          <PropertyFieldGroup title={t('taskManager.currentState.waterManagement')}>
            <PropertyField property={DisplayableTreeProperty.AvoidedRunoff} value={tree?.avoidedRunoff}/>
            <PropertyField
              property={DisplayableTreeProperty.PotentialEvapotranspiration}
              value={tree?.potentialEvapotranspiration}/>
            <PropertyField property={DisplayableTreeProperty.WaterIntercepted} value={tree?.waterIntercepted}/>
            <PropertyField property={DisplayableTreeProperty.Evaporation} value={tree?.evaporation}/>
            <PropertyField property={DisplayableTreeProperty.Transpiration} value={tree?.transpiration}/>
          </PropertyFieldGroup>
          <PropertyFieldGroup title={t('taskManager.currentState.ecosystemServices')}>
            <PropertyField property={DisplayableTreeProperty.CarbonStorage} value={tree?.carbonStorage}/>
            <PropertyField
              property={DisplayableTreeProperty.GrossCarbonSequestration}
              value={tree?.grossCarbonSequestration}/>
            <PropertyField property={DisplayableTreeProperty.OxygenProduction} value={tree?.oxygenProduction}/>
            <PropertyField property={DisplayableTreeProperty.PM25} value={tree?.pm25}/>
            <PropertyField property={DisplayableTreeProperty.CO} value={tree?.co}/>
            <PropertyField property={DisplayableTreeProperty.NO2} value={tree?.no2}/>
            <PropertyField property={DisplayableTreeProperty.O3} value={tree?.o3}/>
            <PropertyField property={DisplayableTreeProperty.SO2} value={tree?.so2}/>
          </PropertyFieldGroup>
          {organization.isEnabled(Flippers.davey) ? '' : (
            <PropertyFieldGroup title={t('taskManager.currentState.economicalValue')}>
              {organization.treeValueMethod === TreeValueMethod.CAVAT ?
                <PropertyField property={DisplayableTreeProperty.TreeValueCavat} value={tree?.treeValueCavat}/> : ''}
              {organization.treeValueMethod === TreeValueMethod.KOCH ?
                <PropertyField property={DisplayableTreeProperty.TreeValueKoch} value={tree?.treeValueKoch}/> : ''}
              {organization.treeValueMethod === TreeValueMethod.RADO ?
                <PropertyField property={DisplayableTreeProperty.TreeValueRado} value={tree?.treeValueRado}/> : ''}
              {organization.treeValueMethod === TreeValueMethod.CTLA ?
                <PropertyField property={'ctla'} value={tree?.ctla}/> : ''}
              {organization.treeValueMethod === TreeValueMethod.NORMA_GRANADA ?
                <PropertyField property={'normaGranada'} value={tree?.normaGranada}/> : ''}
              {organization.treeValueMethod === TreeValueMethod.VAT19 ?
                <PropertyField property={'vat19'} value={tree?.vat19}/> : ''}
              {organization.treeValueMethod === TreeValueMethod.ALNARP_MODEL ?
                <PropertyField property={'alnarpModel'} value={tree?.alnarpModel}/> : ''}
            </PropertyFieldGroup>
          )}
        </AccordionItem>
        <div className="h-12 w-full border-solid border-0 border-t border-[var(--cds-border-subtle)]"/>
        <AccordionItem
          title={t('taskManager.currentState.conclusions')}
          open={openAccordionItem === t('taskManager.currentState.conclusions')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.conclusions'))}
          className={getAccordionItemClassName(t('taskManager.currentState.conclusions'))}
        >
          <PropertyField
            property={DisplayableTreeProperty.FurtherInspectionNeeded}
            value={tree.furtherInspectionNeeded ? new Date(tree.furtherInspectionNeeded).toLocaleDateString() : null}
          />
          <PropertyField
            property={DisplayableTreeProperty.ViStatus}
            value={t('virtualInspection.status.' + tree?.viStatus).toString()}
          />
        </AccordionItem>
        <AccordionItem
          title={t('taskManager.currentState.riskCategorization')}
          open={openAccordionItem === t('taskManager.currentState.riskCategorization')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.riskCategorization'))}
          className={getAccordionItemClassName(t('taskManager.currentState.riskCategorization'))}
        >
          <div className="flex flex-col gap-8 py-3">
            {tree.potentialTargets && tree.potentialTargets.map((it, index) => {
              const likelihood = IsaLikelihood[IsaRiskStandards.getLikelihood(
                Object.values(LikelihoodOfFailure).indexOf(it.likelihoodOfFailure),
                Object.values(LikelihoodOfImpact).indexOf(it.likelihoodOfImpact)
              )];
              const riskRating = IsaRiskRating[IsaRiskStandards.getRiskRating(
                Object.values(LikelihoodOfFailure).indexOf(it.likelihoodOfFailure),
                Object.values(LikelihoodOfImpact).indexOf(it.likelihoodOfImpact),
                Object.values(ConsequencesOfFailure).indexOf(it.consequences)
              )];

              if (disableEdit) return (<div className="mx-4">
                <strong>Target {index + 1}</strong>
                <div className="flex flex-row mt-4 gap-8">
                  <div className="w-1/3 text-[var(--cds-text-secondary)] flex flex-col gap-1 text-nowrap">
                    <span>Description</span>
                    <span>Target zone</span>
                    <span>Tree part</span>
                    <span>Conditions of concern</span>
                    <span>Likelihood of failure</span>
                    <span>Likelihood of impact</span>
                    <span>Consequences of failure</span>
                    <span>Failure and impact</span>
                    <span>Risk rating</span>
                  </div>
                  <div className="w-2/3 flex flex-col gap-1">
                    <span>{it.targetDescription || '-'}</span>
                    <span>{it.targetZone || '-'}</span>
                    <span>{it.treePart || '-'}</span>
                    <span>{it.conditionOfConcern || '-'}</span>
                    <span>{it.likelihoodOfFailure || '-'}</span>
                    <span>{it.likelihoodOfImpact || '-'}</span>
                    <span>{it.consequences || '-'}</span>
                    <span>{likelihood || '-'}</span>
                    <span>{riskRating || '-'}</span>
                  </div>
                </div>
              </div>);

              return (
                <div className="flex flex-col gap-1 divide-y divide-[var(--cds-border-subtle-00)]" key={it.id || index}>
                  <div className="flex items-center gap-2">
                    <div className="w-1/3 text-[var(--cds-text-secondary)]">Target number</div>
                    <div className="w-2/3 font-medium py-3">
                      {index + 1}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-1/3 text-[var(--cds-text-secondary)]">Target description</div>
                    <div className="w-2/3 font-medium py-3">
                      {it.targetDescription}
                    </div>
                  </div>
                  <div className="flex gap-0.5">
                    <div className="flex flex-col gap-1">
                      <FluidTextInput
                        labelText="Tree part"
                        value={it.treePart || ''}
                        onChange={e => potentialTargetsChange(index, 'treePart', e.target.value)}
                        onFocus={() => setFocusedElement(`${index}-treePart`)}
                        onBlur={() => setFocusedElement(null)}
                        autoFocus={focusedElement === `${index}-treePart`}
                      />
                      <FluidDropdown
                        titleText="Likelyhood of failure"
                        label={t('settings.chooseAnOption')}
                        value={it.likelihoodOfFailure ? likelyHoodOfFailureOptions.find(option => option.id === it.likelihoodOfFailure) : likelyHoodOfFailureOptions[0]}
                        onChange={value => potentialTargetsChange(index, 'likelihoodOfFailure', value.selectedItem.id)}
                        items={likelyHoodOfFailureOptions}
                        itemToString={item => likelyHoodOfFailureOptions.find(it => it.id === item.id)?.translationKey || ''}
                      />
                      <FluidDropdown
                        titleText="Consequences of failure"
                        label={t('settings.chooseAnOption')}
                        value={it.consequences ? consequencesOfFailureOptions.find(option => option.id === it.consequences) : consequencesOfFailureOptions[0]}
                        onChange={value => potentialTargetsChange(index, 'consequences', value.selectedItem.id)}
                        items={consequencesOfFailureOptions}
                        itemToString={item => consequencesOfFailureOptions.find(it => it.id === item.id)?.translationKey || ''}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <FluidTextInput
                        labelText="Conditions of concern"
                        value={it.conditionOfConcern || ''}
                        onChange={e => potentialTargetsChange(index, 'conditionOfConcern', e.target.value)}
                        onFocus={() => setFocusedElement(`${index}-conditionOfConcern`)}
                        onBlur={() => setFocusedElement(null)}
                        autoFocus={focusedElement === `${index}-conditionOfConcern`}
                      />
                      <FluidDropdown
                        titleText="Impact of failure"
                        label={t('settings.chooseAnOption')}
                        value={it.likelihoodOfImpact ? impactOptions.find(option => option.id === it.likelihoodOfImpact) : impactOptions[0]}
                        onChange={value => potentialTargetsChange(index, 'likelihoodOfImpact', value.selectedItem.id)}
                        items={impactOptions}
                        itemToString={item => impactOptions.find(it => it.id === item.id)?.translationKey || ''}
                      />
                      <div className="py-4">
                        <div>
                          <span>{t('taskManager.risk.failureAndImpact')} </span>
                          <span>{t(`treeDetails.riskRating.isaLikelihood.${likelihood}`)}</span>
                        </div>
                        <div className="flex gap-0.5 items-center">
                          <span>{t('treeDetails.riskRating.riskRating')}:</span>
                          <span className="flex gap-0.5 items-center">
                            <span>
                              <div className={`size-3 rounded-full ${riskColorMap.get(riskRating)}`} />
                            </span>
                            <span>{t(`treeDetails.riskRating.isaRiskRating.${riskRating}`)}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>);
            })}
          </div>
        </AccordionItem>
        <AccordionItem
          title={t('taskManager.currentState.notesAndMitigations')}
          open={openAccordionItem === t('taskManager.currentState.notesAndMitigations')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.notesAndMitigations'))}
          className={getAccordionItemClassName(t('taskManager.currentState.notesAndMitigations'))}
        >
          {disableEdit
            ? <>
              <section className="p-4 text-sm border-t border-t-[var(--cds-border-subtle-00)]">
                <span className="font-semibold">{t('taskManager.currentState.mitigations')}</span>
                {(tree.mitigations || []).map(mitigation => (
                  <div className="mt-4">
                    <div className="font-medium mb-1">{mitigation.name || mitigation.taskTemplate.name}</div>
                    <div className="flex gap-8">
                      <div className="w-1/3 text-[var(--cds-text-secondary)]">{t('taskManager.currentState.residualRisk')}</div>
                      <div className="w-2/3">{mitigation.residualRisk}</div>
                    </div>
                  </div>
                ))}
              </section>
              <section className="p-4 text-sm border-t border-t-[var(--cds-border-subtle-00)]">
                <span className="font-semibold">{t('taskManager.currentState.limitations')}</span>
                <div className="mt-4">
                  <div className="flex gap-8">
                    <div className="w-3/5 text-[var(--cds-text-secondary)]">{t('taskManager.currentState.data')}</div>
                    <div>{tree.dataStatus || '-'}</div>
                  </div>
                  <div className="flex gap-8">
                    <div className="w-3/5 text-[var(--cds-text-secondary)]">{t('taskManager.currentState.advancedAssessment')}</div>
                    <div>{tree.advancedAssessmentNeeded || '-'}</div>
                  </div>
                  <div className="flex gap-8">
                    <div className="w-3/5  text-[var(--cds-text-secondary)]">{t('taskManager.currentState.reason')}</div>
                    <div>{tree.advancedAssessmentReason || '-'}</div>
                  </div>
                  <div className="flex gap-8">
                    <div className="w-3/5  text-[var(--cds-text-secondary)]">{t('taskManager.currentState.recommendedInspectionInterval')}</div>
                    <div>{tree.recommendedInspectionInterval || '-'}</div>
                  </div>
                  <div className="flex gap-8">
                    <div className="w-3/5  text-[var(--cds-text-secondary)]">{t('taskManager.currentState.inspectionLimitations')}</div>
                    <div>{tree.inspectionLimitation || '-'}</div>
                  </div>
                </div>
              </section>
              <section className="p-4 text-sm border-t border-t-[var(--cds-border-subtle-00)]">
                <span className="font-semibold">{t('taskManager.currentState.notes')}</span>
                <div className="mt-4">{tree.note || '-'}</div>
              </section>
            </>
            : <>
              <PropertyFieldGroup>
                <div className="flex items-center gap-2 px-3 py-2">
                  <div className="w-1/3 text-[var(--cds-text-secondary)]">
                    {t('details.properties.overallTreeRiskRating')}
                  </div>
                  <div className="w-2/3 font-medium flex items-center gap-2">
                    <div className={`size-3 rounded-full ${riskColorMap.get(tree?.overallTreeRiskRating || '')}`} />
                    {tree?.overallTreeRiskRating ? tree?.overallTreeRiskRating :
                      <span className="text-outer-space-500">-</span>}
                  </div>
                </div>
                <div className="flex items-center gap-2 px-3 py-2">
                  <div className="w-1/3 text-[var(--cds-text-secondary)]">
                    {t('details.properties.overallResidualRisk')}
                  </div>
                  <div className="w-2/3 font-medium flex items-center gap-2">
                    <div className={`size-3 rounded-full ${riskColorMap.get(tree?.overallResidualRisk || '')}`} />
                    {tree?.overallResidualRisk ? tree?.overallResidualRisk : <span className="text-outer-space-500">-</span>}
                  </div>
                </div>
                <div className="flex items-center gap-2 px-3 py-2">
                  <div className="w-1/3 text-[var(--cds-text-secondary)]">
                    {t('tree.dataStatus')}
                  </div>
                  <div className="w-2/3 font-medium">
                    <div className="w-full">
                      <Dropdown
                        value={{ id: tree?.dataStatus, translationKey: tree?.dataStatus }}
                        items={Object.values(DataStatus).map(status => ({ id: status, translationKey: status }))}
                        className="bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                        fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                        menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                        onSelect={item => handleChange('dataStatus', item.id as string)}
                        disabled={disableEdit}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2 px-3 py-2">
                  <div className="w-1/3 text-[var(--cds-text-secondary)]">
                    {t('tree.recommendedInspectionInterval')}
                  </div>
                  <div className="w-2/3 font-medium">
                    <div className="w-full">
                      <input
                        name={'recommendedInspectionInterval'}
                        className={'w-[calc(100%-1rem)] bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]'}
                        type="text"
                        defaultValue={tree?.recommendedInspectionInterval || ''}
                        onBlur={event => handleChange('recommendedInspectionInterval', event.target.value)}
                        disabled={disableEdit}
                      />
                    </div>
                  </div>
                </div>
              </PropertyFieldGroup>
              <div>
                <div className="pl-3 py-1 uppercase text-[var(--cds-text-secondary)] text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                  {t('taskManager.currentState.mitigationOptions')}
                </div>
                {(tree.mitigations || []).map((mitigation, index) => (
                  <div
                    className={`p-3 ${index === 0 ? '' : 'border-t border-t-[var(--cds-border-subtle-00)]'}`}
                    key={`${mitigation.name} + ${index}`}>
                    <div className="flex justify-between items-center text-[var(--cds-text-secondary)]">
                      <div>
                        {t('taskManager.currentState.name')}
                      </div>
                      <div>
                        <Trash
                          className="text-danger-500 size-4 fill-none cursor-pointer p-2 rounded hover:bg-greehill-00/10"
                          onClick={() => {
                            handleChange('mitigations', tree?.mitigations.filter((_, idx) => idx !== index));
                            handleChange('observations', tree?.observations
                              .map(observation => ({
                                ...observation,
                                mitigations: observation.mitigations.filter(mit => mit.taskTemplateId !== mitigation.taskTemplateId)
                              })));
                          }}
                        />
                      </div>
                    </div>
                    <Dropdown
                      value={mitigationOptions.find(it => it.id === mitigation.taskTemplateId) || {
                        id: mitigation.name,
                        translationKey: mitigation.name
                      }}
                      items={remainingMitigationOptions}
                      className="w-full bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                      fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                      menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                      disabled={!!mitigation.taskTemplateId}
                      onSelect={item => {
                        handleChange('mitigations', tree?.mitigations.map((it, i) => index === i ? {
                          ...it,
                          name: item.translationKey,
                          taskTemplateId: item.id,
                          taskId
                        } : it));
                      }}
                    />
                    <div className="mt-4 mb-2 text-[var(--cds-text-secondary)]">{t('taskManager.currentState.residualRisk')}</div>
                    <Dropdown
                      value={{ id: mitigation?.residualRisk, translationKey: mitigation?.residualRisk }}
                      items={Object.values(RiskRating).map(rating => ({ id: rating, translationKey: rating }))}
                      className="w-full bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                      fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                      menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                      valueRenderer={item => (<div className="flex items-center gap-2"><span
                        className={`size-3 inline-block rounded-full ${riskColorMap.get(item.id || '')}`} /><span>{item.translationKey}</span>
                      </div>)}
                      onSelect={item => {
                        handleChange('mitigations', tree?.mitigations.map((it, i) => index === i ? {
                          ...it,
                          residualRisk: item.id
                        } : it));
                      }}
                    />
                  </div>
                ))}
                <div>
                  <button
                    className="flex gap-2 items-center m-auto rounded-full border border-[var(--cds-border-subtle-00)] py-1 px-3 text-sm mt-2"
                    disabled={disableEdit || tree.mitigations?.length === 3}
                    onClick={() => {
                      handleChange('mitigations', [...tree.mitigations || [], { name: '', residualRisk: RiskRating.LOW }]);
                    }}>
                    <PlusCircle /> {t('taskManager.add')}
                  </button>
                </div>
              </div>
              <div className="my-8">
                <div className="pl-3 py-1 uppercase text-[var(--cds-text-secondary)] text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                  {t('taskManager.currentState.advancedAssessment')}
                </div>
                <div className="flex flex-col rounded-xl overflow-hidden divide-y divide-[var(--cds-border-subtle-00)]">
                  <div className="px-3 py-3 text-[var(--cds-text-secondary)]">
                    <Toggle
                      checked={!!tree?.advancedAssessmentNeeded}
                      onChange={() => handleChange('advancedAssessmentNeeded', !tree.advancedAssessmentNeeded)}
                      disabled={disableEdit}
                    >
                      {t('taskManager.currentState.needed')}
                    </Toggle>
                  </div>
                  <div className="px-3 py-2 flex flex-col gap-2 text-[var(--cds-text-secondary)]">
                    <div>{t('taskManager.currentState.typeOrReason')}</div>
                    <textarea
                      className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]"
                      defaultValue={tree?.advancedAssessmentReason}
                      placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                      onBlur={event => handleChange('advancedAssessmentReason', event.target.value)}
                      disabled={!tree?.advancedAssessmentNeeded || disableEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="my-8">
                <div className="pl-3 py-1 uppercase text-[var(--cds-text-secondary)] text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                  {t('taskManager.currentState.inspectionLimitation')}
                </div>
                <div className="flex flex-col gap-1 divide-y divide-[var(--cds-border-subtle-00)]">
                  <div className="w-full px-3 py-2">
                    <Dropdown
                      value={{ id: tree?.inspectionLimitation, translationKey: tree?.inspectionLimitation }}
                      items={
                        Object.values(InspectionLimitation)
                          .map(status => ({
                            id: status,
                            translationKey: status === InspectionLimitation.ROOT_COLLAR_BURIED ? 'Root collar buried' : status
                          }))
                      }
                      className="w-[calc(100%-1.5rem)] bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                      fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                      menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                      onSelect={item => handleChange('inspectionLimitation', item.id as string)}
                      disabled={disableEdit}
                    />
                  </div>
                  <div className="px-3 py-2 flex flex-col gap-2 text-[var(--cds-text-secondary)]">
                    <div>{t('taskManager.currentState.description')}</div>
                    <textarea
                      className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]"
                      defaultValue={tree?.inspectionLimitationDescription}
                      placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                      onBlur={event => handleChange('inspectionLimitationDescription', event.target.value)}
                      disabled={tree?.inspectionLimitation === InspectionLimitation.NONE || disableEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="my-8 text-[var(--cds-text-secondary)]">
                <div className="pl-3 py-1 uppercase text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                  {t('taskManager.currentState.notesTitle')}
                </div>
                <div className="flex flex-col gap-1 overflow-hidden px-3 py-2">
                  <textarea
                    className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]"
                    defaultValue={tree?.note}
                    placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                    onBlur={event => handleChange('note', event.target.value)}
                    disabled={disableEdit}
                  />
                </div>
              </div>
            </>}
        </AccordionItem>
      </Accordion>
    </DetailsGroup>
  );
}

interface PropertyFieldProps {
  property: DisplayableTreeProperty | keyof DetailedTree,
  value: number | string | boolean | null | undefined,
  action?: ReactNode | ReactNode[]
}

function PropertyField(props: PropertyFieldProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  let value;
  if (typeof props.value === 'boolean') {
    value = t(`details.properties.booleanLabels.${props.value}`);
  } else if (props.property === DisplayableTreeProperty.Dieback && typeof props.value === 'number') {
    value = props.value.toFixed(2);
  } else if (typeof props.value === 'number') {
    value = props.value.toLocaleString('en-US', { maximumFractionDigits: 0 });
  } else {
    value = props.value;
  }
  const unit = Tree.getUnit(props.property, organization);

  return (
    <div className={'flex items-center gap-2 px-4 py-3.5'}>
      <div className="w-1/3 flex gap-2 items-center text-[var(--cds-text-secondary)] text-sm font-normal">
        {t('tree.' + props.property, { defaultValue: t('details.properties.' + props.property) })}
      </div>
      <div className="w-2/3 font-normal text-sm text-[var(--cds-text-primary)] flex justify-between">
        {value ?
          <div className="flex gap-1 items-baseline">
            {value}
            {unit && <span>{t(`units.${unit}`)}</span>}
          </div>
          :
          <span className="text-outer-space-500">-</span>}
        {props.action}
      </div>
    </div>
  );
}

function PropertyFieldGroup({ children, title }: { children: ReactNode | ReactNode[], title?: string }) {
  return (
    <div>
      {title && (
        <h3 className="font-semibold mt-8 ml-4 text-sm">
          {title}
        </h3>
      )}
      <div className="flex flex-col mb-4 divide-y divide-[var(--cds-border-subtle-00)]">
        {children}
      </div>
    </div>
  );
}

interface RulerToggleProps {
  property: ExtraObject,
  toggleProperty: (property: ExtraObject) => void,
  selected: boolean
}

function RulerToggle(props: RulerToggleProps) {
  const { urlContext } = useContext(DependencyInjectionContext);

  if (urlContext.getDetailsTreeDisplay() === TreeDisplays.IMAGES) return null;

  return (
    <div onClick={() => props.toggleProperty(props.property)} className="cursor-pointer">
      {props.selected ? <ViewFilled/> : <View/>}
    </div>
  );
}
