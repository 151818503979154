import { Tree } from '../../../tree/Tree';
import { Image, parseImageProperties } from './meshGenerator';
import CapturePointService from '../../../capture-point/CapturePointService';
import CapturePoint from '../../../capture-point/CapturePoint';
import TreeService from '../../../tree/TreeService';
import * as THREE from 'three';
import { Account } from '../../../account/Account';
import { Organization } from '../../../organization/Organization';
import DetailedTree from '../../../tree/DetailedTree';

type SortedCapturePoint = { id: string, leftNeighborId: string, rightNeighborId: string, location: [number, number, number] };

interface PanoramicData {
  visibleTrees: Tree[],
  images: Image[],
  capturePoints: SortedCapturePoint[],
  currentCapturePoint: { id: string, location: [number, number, number] } | undefined
}

export default class PanoramicDataHandler {
  private capturePointLegacyMap: Record<string, { promise?: Promise<PanoramicData>, res?: PanoramicData, insertionOrder: number }> = {};
  private capturePointImageMap: Record<string, { promise?: Promise<Image[]>, res?: Image[], insertionOrder: number }> = {};
  private insertionOrder = 0;

  constructor(
    private readonly treeService: TreeService,
    private readonly capturePointService: CapturePointService,
    readonly organization: Organization
  ) {}

  loadImages(capturePoint: CapturePoint) {
    const origin = capturePoint.location;
    if (!origin) return new Promise<Image[] | undefined>(resolve => resolve(undefined));
    const originKey = origin.join(',');
    const rec = this.capturePointImageMap[originKey];
    if (rec && rec.res) return new Promise<Image[] | undefined>(resolve => resolve(rec.res));
    const prom = this.capturePointImageMap[originKey]?.promise;
    if (prom) return prom;

    this.popImageCache();

    this.capturePointImageMap[originKey] = { insertionOrder: this.insertionOrder };
    this.insertionOrder++;
    this.capturePointImageMap[originKey].promise = this.fetchNewImages(capturePoint).then(it => {
      this.capturePointImageMap[originKey].res = it;
      return it;
    });

    return this.capturePointImageMap[originKey].promise!;
  }

  private async fetchNewImages(capturePoint: CapturePoint) {
    return await this.getImages(capturePoint, capturePoint.snapshotId);
  }

  load(capturePoint: Partial<CapturePoint>, tree: DetailedTree | null, account: Account, loadVisibleTrees = true): Promise<PanoramicData | undefined> {
    const origin = capturePoint.location;
    if (!origin)
      return new Promise<PanoramicData | undefined>(resolve => resolve(undefined));
    const originKey = origin.join(',');
    const rec = this.capturePointLegacyMap[originKey] as any;
    if (rec && rec.res) {
      return new Promise<PanoramicData | undefined>(resolve => resolve(rec.res));
    }
    const prom = this.capturePointLegacyMap[originKey]?.promise;
    if (prom) {
      return prom;
    }

    this.popLegacyCache();

    this.capturePointLegacyMap[originKey] = { insertionOrder: this.insertionOrder };
    this.insertionOrder++;
    this.capturePointLegacyMap[originKey].promise = this.fetchNewObjects(origin, capturePoint.snapshotId!, loadVisibleTrees, tree).then(it => {
      this.capturePointLegacyMap[originKey].res = it;
      return it;
    });

    return this.capturePointLegacyMap[originKey].promise!;
  }

  private async fetchNewObjects(origin: [number, number, number], snapshotId: string, loadVisibleTrees: boolean, tree: DetailedTree | null) {
    const [capturePoints, visibleTrees] = await Promise.all([
      this.capturePointService.listVisibleFrom(this.organization.id, origin!, tree?.height ? Math.max(tree.height * 2, 25) : 25, tree?.recordingDate),
      loadVisibleTrees ? this.treeService.listVisibleFrom(this.organization.id, origin!, tree?.height ? Math.max(tree.height * 2, 25) : 25) : Promise.resolve([])
    ]);

    let currentCapturePoint = capturePoints.find(cpt => this.isSameLocation(cpt.location, origin!));

    const images = await this.getImages(currentCapturePoint, snapshotId);

    if (!currentCapturePoint) {
      const sameLocationCapturePoint = capturePoints.find(cpt => cpt.location.every((coord, ind) => images.find(img => img.origin.coordinates[ind] === coord)));
      currentCapturePoint = sameLocationCapturePoint || capturePoints.reduce((prev, curr) => {
        return ((prev.distance || 0) < (curr.distance || 0)) ? prev : curr;
      });
    }

    const capturePointsWithAngles: { id: string, location: [number, number, number], leftNeighborId: string, rightNeighborId: string }[] = capturePoints.map(it => {
      if (!tree) return { ...it, angleDiff: 0, leftNeighborId: '', rightNeighborId: '' };
      const relative = new THREE.Vector2(it.location[0] - tree.localizedLocation[0], it.location[1] - tree.localizedLocation[1]);
      const capturePoint = new THREE.Vector2(currentCapturePoint!.location[0], currentCapturePoint!.location[1]);
      const camera = capturePoint.clone().sub(new THREE.Vector2(tree.localizedLocation[0], tree.localizedLocation[1]));
      const angle = relative.angle();
      const cameraAngle = camera.angle();

      const angleDiffRadians = (angle - cameraAngle + Math.PI * 2) % (Math.PI * 2);
      const angleDiff = (angleDiffRadians * 180) / Math.PI;

      return { ...it, angleDiff, leftNeighborId: '', rightNeighborId: '' };
    }).sort((a, b) => a.angleDiff - b.angleDiff);

    return { visibleTrees, images, capturePoints: capturePointsWithAngles, currentCapturePoint };
  }

  private async getImages(currentCapturePoint: CapturePoint | undefined, snapshotId: string): Promise<Image[]> {
    if (currentCapturePoint?.type === 'single_images') {
      return currentCapturePoint?.imageProperties
        ? parseImageProperties(currentCapturePoint.imageProperties, this.organization, snapshotId)
        : await this.capturePointService.getImagesBySnapshotId(this.organization, snapshotId);
    }

    const images = currentCapturePoint!.imageProperties!;
    images.forEach(it => it.gufPath = this.organization.getCDNUrlOfExportedDataFromRelativePath(it.gufPath!));
    return images;
  }

  private popImageCache() {
    this.popCache(this.capturePointImageMap);
  }

  private popLegacyCache() {
    this.popCache(this.capturePointLegacyMap);
  }

  private popCache<T extends { insertionOrder: number }>(map: Record<string, T>) {
    if (Object.keys(map).length === 20) {
      let smallestKey = Object.keys(map)[0];
      Object.keys(map).forEach(key => {
        const prev = map[smallestKey].insertionOrder;
        const next = map[key].insertionOrder;
        if (prev && next && next < prev) {
          smallestKey = key;
        }
      });
      delete map[smallestKey];
    }
  }

  private isSameLocation(vec1: [number, number, number], vec2: [number, number, number]) {
    return new THREE.Vector3(...vec1).equals(new THREE.Vector3(...vec2));
  }
}
