import styles from './HistoryTrackingHandle.module.scss';
import React, { useContext } from 'react';
import DependencyInjectionContext from '../../../../../DependencyInjectionContext';
import { TreeDisplays } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { NavArrowDown, NavArrowUp } from 'iconoir-react';

export default function HistoryTrackingHandle() {
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const isOpen = urlContext.isHistoryTrackingOpen();
  const { t } = useTranslation();

  const toggleHistoryTracking = () => {
    urlContext.setHistoryTrackingOpen(!isOpen);
    if (!isOpen) {
      urlContext.setDetailsIsExpanded(true);
      urlContext.setDetailsSecondary(TreeDisplays.NULL);
      urlContext.setSelectedDataPanelTab(null);
    }
  };

  return (
    <div className={styles.toggleContainer} onClick={toggleHistoryTracking}>
      {isOpen ? <NavArrowDown /> : <NavArrowUp />}{t('treeDetails.historyTracking.title')}
    </div>
  );
}
