import mapboxgl, { CustomLayerInterface } from 'mapbox-gl';
import { Organization } from '../../../../organization/Organization';
import { MultiPolygon } from 'geojson';
import OrganizationBorderImage from '../../../Explore/images/organization-border.svg';

export class OrganizationLayer implements CustomLayerInterface {
  readonly id = 'organization';
  readonly type = 'custom';
  private organization = Organization.empty();
  private map: mapboxgl.Map | null = null;
  private borderImage: HTMLImageElement | null = null;

  onRemove(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    this.removeLayer();
    this.removeSource();
    if (map.hasImage('organization-border')) {
      map.removeImage('organization-border');
    }
  }

  render() {}

  setOrganization(organization: Organization) {
    this.removeLayer();
    this.removeSource();

    this.organization = organization;

    this.addSource();
    this.addLayer();

    return this;
  }

  centerOn() {
    if (!this.organization.boundaries.coordinates?.length) {
      return;
    }

    this.map?.fitBounds(this.organization.boundaries.coordinates
      .flatMap(position => position as [number, number][][])
      .flatMap(position => position as [number, number][])
      .reduce((bounds, position) => bounds.extend(position), new mapboxgl.LngLatBounds()),
    { bearing: 0, pitch: 0 });
  }

  async onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    if (!this.borderImage) {
      this.borderImage = await this.loadBorderImage();
    }
    if (!map.hasImage('organization-border')) {
      map.addImage('organization-border', this.borderImage);
    }

    this.map = map;
    this.addSource();
    this.addLayer();
  }

  private addSource() {
    if (!this.map?.hasImage('organization-border')) {
      return;
    }

    if (this.map?.getSource('organization')) return;

    this.map?.addSource('organization', {
      type: 'geojson',
      data: {
        type: 'Feature' as const,
        id: this.organization.id,
        properties: {},
        geometry: this.organization.boundaries as MultiPolygon
      }
    });
  }

  private removeSource() {
    if (!this.map || !this.map.getSource('organization')) {
      return;
    }

    this.map.removeSource('organization');
  }

  private addLayer() {
    if (!this.map?.hasImage('organization-border') || this.map?.getLayer('organization-border')) {
      return;
    }

    this.map?.addLayer({
      id: 'organization-border',
      source: 'organization',
      type: 'line',
      layout: {
        'line-cap': 'butt',
        'line-join': 'bevel'
      },
      paint: {
        'line-width': 4,
        'line-pattern': 'organization-border'
      }
    });
  }

  private removeLayer() {
    if (!this.map || !this.map.getLayer('organization-border')) {
      return;
    }

    this.map.removeLayer('organization-border');
  }

  private loadBorderImage(): Promise<HTMLImageElement> {
    const image = new Image(4, 8);
    image.src = OrganizationBorderImage;

    return new Promise((resolve, reject) => {
      image.onload = () => resolve(image);
      image.onerror = reject;
    });
  }
}
