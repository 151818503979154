import { Button, Dropdown, IconButton } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { CloseLarge } from '@carbon/icons-react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import React, { useContext, useEffect, useState } from 'react';
import PageLayout from '../../../components/UI/Page/carbon/PageLayout';
import { useCurrentAccount } from '../../../account/useAccounts';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import FilterConfig, {
  EnumCondition,
  Filter,
  FilterGroup as FilterGroupType,
  getProperties,
  isValid,
  Operator
} from '../../TaskManager/create/FilterConfig';
import CarbonFilterRow from './CarbonFilterRow';
import { Item } from '../../../components/UI/Dropdown/Dropdown';
import CarbonFilterGroup from './CarbonFilterGroup';

export default function CarbonFilterSelector() {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const [speciesLists, setSpeciesLists] = useState({ scientificNames: [], genus: [], species: [] });
  const [filterConfig, setFilterConfig] = useState<FilterConfig>(urlContext.getFilterConfiguration());

  useEffect(() => {
    (async () => {
      const list = await treeService.getSpeciesListsByManagedAreas(organization.id, urlContext.getManagedAreaIds(), urlContext.getReverseMASelection());
      setSpeciesLists(list);
    })();
  }, [organization.id, urlContext.getManagedAreaIds().join(','), treeService]);

  const handleAddFilter = () => {
    setFilterConfig({
      filters: [
        ...filterConfig.filters || [],
        { property: '' as unknown as keyof Tree, condition: '' as unknown as EnumCondition, value: [] }
      ],
      filterGroups: filterConfig.filterGroups || [],
      topLevelOperator: filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleDeleteFilter = (filterIndex: number) => {
    setFilterConfig({
      filters: filterConfig.filters.filter((_, i) => i !== filterIndex) || [],
      filterGroups: filterConfig.filterGroups || [],
      topLevelOperator: filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleAddFilterGroup = () => {
    setFilterConfig({
      filters: filterConfig.filters || [],
      filterGroups: [
        ...filterConfig.filterGroups || [],
        { filters: [{ property: '' as unknown as keyof Tree, condition: '' as unknown as EnumCondition, value: [] }], filterGroupOperator: Operator.AND }
      ],
      topLevelOperator: filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleDeleteGroup = (groupIndex: number) => {
    setFilterConfig({
      filters: filterConfig.filters || [],
      filterGroups: filterConfig.filterGroups.filter((_, i) => i !== groupIndex) || [],
      topLevelOperator: filterConfig.topLevelOperator || Operator.AND
    });
  };

  const updateFilter = (filterIndex: number, filter: Filter) => {
    const newFilters = filterConfig.filters.map((f, index) => index === filterIndex ? filter : f) || [];
    setFilterConfig({
      filters: newFilters,
      filterGroups: filterConfig.filterGroups || [],
      topLevelOperator: filterConfig.topLevelOperator || Operator.AND
    });
  };

  const updateTopLevelOperator = (operator: Operator) => {
    setFilterConfig({
      filters: filterConfig.filters || [],
      filterGroups: filterConfig.filterGroups || [],
      topLevelOperator: operator
    });
  };

  const updateFilterGroup = (groupIndex: number, group: FilterGroupType) => {
    const newGroups = filterConfig.filterGroups.map((g, index) => index === groupIndex ? group : g) || [];
    setFilterConfig({
      filters: filterConfig.filters || [],
      filterGroups: newGroups,
      topLevelOperator: filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleClearAll = () => {
    setFilterConfig({
      filters: [],
      filterGroups: [],
      topLevelOperator: Operator.AND
    });
  };

  const topLevelOperatorOptions = [
    { id: Operator.AND, translationKey: `taskManager.topLevelOperators.${Operator.AND}` },
    { id: Operator.OR, translationKey: `taskManager.topLevelOperators.${Operator.OR}` }
  ];

  const handleTopLevelOperatorSelect = (item: Item) => {
    updateTopLevelOperator(item.id as Operator);
  };

  const trimDraftFilters = (filterConfig: FilterConfig) => {
    return {
      filters: filterConfig.filters.filter(isValid),
      filterGroups: filterConfig.filterGroups.map(group => ({
        filters: group.filters.filter(isValid),
        filterGroupOperator: group.filterGroupOperator
      })),
      topLevelOperator: filterConfig.topLevelOperator
    };
  };

  const validFilterConfig = trimDraftFilters(filterConfig);

  let itemSerial = 0;
  return (
    <>
      <PageLayout.RightPanelContent>
        <div className="light-input-background px-4 flex flex-col gap-4">
          <div className="px-4 flex items-center justify-between">
            <h3 className="m-0 text-xl font-normal">{t('filter.filter')}</h3>
            <IconButton onClick={() => urlContext.setFilterPanelOpen(false)} label={t('filter.close')} align="left" kind="ghost"><CloseLarge/></IconButton>
          </div>
          {filterConfig.filters.map((filter, index) => {
            itemSerial++;
            return (
              <>
                {itemSerial === 2 && (
                  <div key={'operator-' + index} className="pt-4 px-4 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
                    <Dropdown
                      className="w-[100px] float-right"
                      id="dropdown"
                      items={topLevelOperatorOptions}
                      label=""
                      onChange={e => handleTopLevelOperatorSelect(e.selectedItem!)}
                      initialSelectedItem={topLevelOperatorOptions.find(it => it.id === filterConfig.topLevelOperator)}
                      selectedItem={topLevelOperatorOptions.find(it => it.id === filterConfig.topLevelOperator)}
                      itemToString={item => item ? t(item.translationKey) : ''}
                      titleText=''
                    />
                  </div>
                )}
                {itemSerial > 2 && (
                  <div key={'operator-label-' + index} className="pt-4 mx-4 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
                    <div className="w-[100px] float-right px-4 text-sm">
                      {t(topLevelOperatorOptions.find(it => it.id === filterConfig.topLevelOperator)?.translationKey || '')}
                    </div>
                  </div>
                )}
                <div className="px-4">
                  <CarbonFilterRow
                    key={`${filter.property}-${index}`}
                    index={index}
                    handleDeleteFilter={() => handleDeleteFilter(index)}
                    filter={filter}
                    filterIndex={index}
                    update={updateFilter}
                    speciesLists={speciesLists}
                  />
                </div>
              </>
            );
          })}
          {filterConfig.filterGroups.map(((group, index) => {
            itemSerial++;
            return (
              <>
                {itemSerial === 2 && (
                  <div key={'filtergroup-operator-' + index} className="pt-4 px-4 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
                    <Dropdown
                      className="w-[100px] float-right"
                      id="dropdown"
                      items={topLevelOperatorOptions}
                      label=""
                      onChange={e => handleTopLevelOperatorSelect(e.selectedItem!)}
                      initialSelectedItem={topLevelOperatorOptions.find(it => it.id === filterConfig.topLevelOperator)}
                      selectedItem={topLevelOperatorOptions.find(it => it.id === filterConfig.topLevelOperator)}
                      itemToString={item => item ? t(item.translationKey) : ''}
                      titleText=''
                    />
                  </div>
                )}
                {itemSerial > 2 && (
                  <div key={'filtergroup-operator-label-' + index} className="mx-4">
                    <div className="w-[100px] float-right px-4 text-sm">
                      {t(topLevelOperatorOptions.find(it => it.id === filterConfig.topLevelOperator)?.translationKey || '')}
                    </div>
                  </div>
                )}
                <CarbonFilterGroup
                  key={`filter-group-${index}`}
                  handleDelete={() => handleDeleteGroup(index)}
                  group={group}
                  groupIndex={index}
                  update={updateFilterGroup}
                  speciesLists={speciesLists}
                />
              </>
            );
          }))}
          <div className="px-4 border-solid border-t border-[var(--cds-border-subtle-00)] border-0"/>
          <div className="px-4 grid grid-cols-2 gap-0.5">
            <Button onClick={handleAddFilter} className="col-start-1 col-end-2 w-full" kind="tertiary">
              {t('taskManager.newFilter')}
            </Button>
            <Button onClick={handleAddFilterGroup} className="col-start-2 col-end-3 w-full whitespace-nowrap" kind="tertiary">
              {t('taskManager.newGroup')}
            </Button>
          </div>
        </div>
      </PageLayout.RightPanelContent>
      <PageLayout.RightPanelActions>
        <div className="grid grid-cols-2 gap-0.5">
          <Button
            onClick={handleClearAll}
            className="col-start-1 col-end-2 w-full"
            kind="secondary"
            size='xl'
          >
            {t('taskManager.clearAll')}
          </Button>
          <Button
            onClick={() => {
              urlContext.setFilterConfiguration(validFilterConfig);
              urlContext.setVisibleTableProperties(Array.from(new Set([...(urlContext.getVisibleTableProperties() ?? []), ...getProperties(validFilterConfig) as DisplayableTreeProperty[]])));
              urlContext.setFilterPanelOpen(false);
            }}
            className="col-start-2 col-end-3 w-full"
            size='xl'
          >
            {t('apply')}
          </Button>
        </div>
      </PageLayout.RightPanelActions>
    </>
  );
}
