import { SettingsPageTitle } from './SettingsPageTitle';
import { t } from 'i18next';
import React from 'react';
import { CheckCircle, XmarkCircle } from 'iconoir-react';
import { useCurrentAccount } from '../../account/useAccounts';
import { AccountType } from '../../account/AccountType';

enum Permissions {
  ViewTreeDetails = 'viewTreeDetails',
  DeleteTree = 'deleteTree',
  CreateTask = 'createTask',
  WorkOnTasks = 'workOnTasks',
  ManageMembers = 'manageMembers',
  OrganizationDataStandards = 'organizationDataStandards',
  OrganizationSettings = 'organizationSettings'
}

const clientPermissions = [
  Permissions.ViewTreeDetails
];

const employeePermissions = [
  ...clientPermissions,
  Permissions.WorkOnTasks
];
const managerPermissions = [
  ...employeePermissions,
  Permissions.CreateTask
];
const adminPermissions = [
  ...managerPermissions,
  Permissions.ManageMembers,
  Permissions.OrganizationDataStandards,
  Permissions.DeleteTree
];
const ownerPermissions = [
  ...adminPermissions.filter(it => it !== Permissions.WorkOnTasks),
  Permissions.OrganizationSettings
];
export function RolesAndPermissions() {
  const currentAccount = useCurrentAccount();
  const columns = [AccountType.Client, AccountType.Employee, AccountType.Manager, AccountType.Admin];
  if (currentAccount.isOwner()) columns.push(AccountType.Owner);
  return (
    <>
      <SettingsPageTitle>{t('rolesAndPermissions.title')}</SettingsPageTitle>
      <table className="border-outer-space-800 text-outer-space-50 w-full">
        <thead className="bg-outer-space-900 whitespace-nowrap">
          <tr>
            <th className="first:rounded-tl-lg bg-outer-space-900 sticky top-0 z-10" ></th>
            {columns.map(column => <HeaderCell key={column} column={column} />)}
          </tr>
        </thead>
        <tbody className="bg-outer-space-700">
          {Object.values(Permissions)
            .filter(it => it !== Permissions.OrganizationSettings || currentAccount.isOwner())
            .map(permission => (
              <tr key={permission}>
                <td className="py-2 px-4">{t(`rolesAndPermissions.${permission}`)}</td>
                <td
                  className={`py-2 px-4 text-center ${clientPermissions.includes(permission) ? 'bg-greehill-600' : 'text-outer-space-400'}`}>
                  {clientPermissions.includes(permission) ? <CheckCircle /> : <XmarkCircle />}
                </td>
                <td
                  className={`py-2 px-4 text-center ${employeePermissions.includes(permission) ? 'bg-greehill-600' : 'text-outer-space-400'}`}>
                  {employeePermissions.includes(permission) ? <CheckCircle /> : <XmarkCircle />}
                </td>
                <td
                  className={`py-2 px-4 text-center ${managerPermissions.includes(permission) ? 'bg-greehill-600' : 'text-outer-space-400'}`}>
                  {managerPermissions.includes(permission) ? <CheckCircle /> : <XmarkCircle />}
                </td>
                <td className={`py-2 px-4 text-center ${adminPermissions.includes(permission) ? 'bg-greehill-600' : 'text-outer-space-400'}`}>
                  {adminPermissions.includes(permission) ? <CheckCircle /> : <XmarkCircle />}
                </td>
                {currentAccount.isOwner() && <td className={`py-2 px-4 text-center ${ownerPermissions.includes(permission) ? 'bg-greehill-600' : 'text-outer-space-400'}`}>
                  {ownerPermissions.includes(permission) ? <CheckCircle /> : <XmarkCircle />}
                </td>}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

const HeaderCell = (props: { column: string }) => {
  return (
    <th className="last:rounded-tr-lg bg-outer-space-900 sticky top-0 z-10">
      <div className="h-14 p-4 justify-center items-center flex w-full capitalize">
        {t(`account.type.${props.column}`)}
      </div>
    </th>
  );
};
