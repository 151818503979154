const cache = new Map<string, HTMLImageElement>();

function isLowResolutionImage(url: string) {
  return url.includes('2_0_0');
}

export async function loadImage(url: string, signal: AbortSignal): Promise<HTMLImageElement> {
  if (cache.has(url) && isLowResolutionImage(url)) {
    return cache.get(url)!;
  }

  const image = new Image();
  try {
    const response = await fetch(url, {
      signal,
      credentials: 'include'
    });

    const blob = await response.blob();
    image.src = URL.createObjectURL(blob);
    await new Promise(resolve => {
      image.onload = () => resolve(undefined);
    });
    cache.set(url, image);
  } catch (err) {
    // aborted fetch
  }

  return image;
}