import { ReportData, SpeciesDistribution } from '../ReportData';
import ReportSection from './ReportSection';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { NumericFormat } from '../../../../numeric-format/NumericFormat';
import styles from './AvoidedWaterSection.module.scss';
import useCarbonThemes from '../../../../components/UI/Carbon/useCarbonThemes';
import { themes } from '@carbon/themes';

const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const { ctx, chartArea: { left, top, width, height } } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.setLineDash(options.borderDash || []);
    ctx.lineDashOffset = options.borderDashOffset;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  }
};

type AvoidedWaterSectionParams = {
  data: ReportData['avoidedWaterRunoff'],
  speciesDistribution: SpeciesDistribution,
  containerId: string,
  animationDisabled?: boolean,
  printable?: boolean,
  onLoaded?: () => void
};
export default function AvoidedWaterSection(props: AvoidedWaterSectionParams) {
  const { t } = useTranslation();
  const { theme } = useCarbonThemes();

  const onLoadedPlugin = {
    id: 'onLoaded',
    afterRender: props.onLoaded
  };

  const chartData = props.data.map((it, i) => ({
    ...it,
    speciesPercentage: Math.round(100 * props.speciesDistribution[i].percentage)
  }));

  const options = {
    responsive: true,
    animation: props.animationDisabled ? { duration: 0 } : undefined,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        display: false
      },
      chartAreaBorder: {
        borderColor: 'rgba(91, 113, 121, 0.3)',
        borderWidth: 1
      }
    },
    scales: {
      percentageAxes: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        grid: {
          color: 'rgba(91, 113, 121, 0.3)',
          drawTicks: false
        },
        ticks: {
          padding: 16,
          color: themes[theme].textSecondary,
          callback: value => value + ' %'
        }
      },
      absoluteAxes: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
          color: 'rgba(91, 113, 121, 0.3)',
          drawTicks: false
        },
        afterFit: function(scaleInstance) {
          scaleInstance.width = 120;
        },
        ticks: {
          padding: 16,
          color: themes[theme].textSecondary,
          callback: value => NumericFormat.iso('kg/year', navigator.language, t).format(value)
        }
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          color: props.printable ? 'rgba(34, 45, 49, 1)' : themes[theme].textPrimary
        },
        grid: {
          display: false
        }
      }
    }
  };

  const data = {
    labels: chartData.map(it => it.key),
    datasets: [
      {
        label: t('reporting.avoidedWater.treePopulation'),
        data: chartData.map(it => it.speciesPercentage),
        backgroundColor: '#205151',
        minBarLength: 5,
        barPercentage: 1,
        maxBarThickness: 8,
        categoryPercentage: .3,
        yAxisID: 'percentageAxes'
      },
      {
        label: t('reporting.avoidedWater.avoidedWaterRunOff'),
        data: chartData.map(it => it.value),
        backgroundColor: '#01A657',
        minBarLength: 5,
        barPercentage: 1,
        maxBarThickness: 8,
        categoryPercentage: .3,
        yAxisID: 'absoluteAxes'
      }
    ]
  };

  const legend = (
    <div className={`${styles.legend} ${props.printable ? styles.printableLegend : ''}`}>
      <div className={styles.percentage}>
        {data.datasets.filter(it => it.yAxisID === 'percentageAxes').map((it, index) => {
          return (
            <div className={styles.legendItem} key={index}>
              <div className={styles.coloredMarker} style={{ backgroundColor: it.backgroundColor }} />
              <span>{it.label}</span>
            </div>
          );
        })}
      </div>

      <div className={styles.absolute}>
        {data.datasets.filter(it => it.yAxisID === 'absoluteAxes').map((it, index) => {
          return (
            <div className={styles.legendItem} key={index}>
              <div className={styles.coloredMarker} style={{ backgroundColor: it.backgroundColor }} />
              <span>{it.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2>{t('reporting.avoidedWater.title')}</h2>
      {legend}
      <Bar
        options={options}
        data={data}
        plugins={[chartAreaBorder, onLoadedPlugin]} />
    </ReportSection>
  );
}
