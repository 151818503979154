import { GeneralInsights, Quantity } from '../ReportData';
import ReportSection from './ReportSection';
import { useTranslation } from 'react-i18next';
import styles from './GeneralInsightsSection.module.scss';
import { NumericFormat } from '../../../../numeric-format/NumericFormat';
import { useCurrentAccount } from '../../../../account/useAccounts';

export default function GeneralInsightsSection(props: { data: GeneralInsights, containerId: string, printable?: boolean }) {
  const { t } = useTranslation();
  const tt = suffix => t(`reporting.generalInsights.${suffix}`);
  const ecological = props.data.ecologicalBenefits;
  const account = useCurrentAccount();

  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2 className={styles.header}>{tt('title')}</h2>

      <div className="flex flex-col gap-4">
        <h3 className="font-semibold">{tt('ecologicalBenefits')}</h3>
        <div className="flex gap-4">
          <div className="flex flex-col text-[var(--cds-text-secondary)]">
            <span>{tt('airPollutionRemoval')}</span>
            <span>{tt('avoidedRunoff')}</span>
            <span>{tt('grossCarbonSequestration')}</span>
            <span>{tt('carbonStorage')}</span>
          </div>
          <div className="flex flex-col">
            <Cell data={ecological.airPollutionRemoval.quantity} />
            <Cell data={ecological.avoidedRunoff.quantity} />
            <Cell data={ecological.grossCarbonSequestration.quantity} />
            <Cell data={ecological.carbonStorage.quantity} />
          </div>
        </div>
        <h3 className="font-semibold">{tt('economicalValues')}</h3>
        <div className="flex gap-4">
          <div className="flex flex-col text-[var(--cds-text-secondary)]">
            <span>{tt('treeValueCavat')}</span>
            <span>{tt('treeValueKoch')}</span>
            <span>{tt('treeValueRado')}</span>
          </div>
          <div className="flex flex-col">
            <Cell data={props.data.economicalValues.treeValueCavat} currency={account.organization.currency} />
            <Cell data={props.data.economicalValues.treeValueKoch} currency={account.organization.currency} />
            <Cell data={props.data.economicalValues.treeValueRado} currency={account.organization.currency} />
          </div>
        </div>
      </div>
    </ReportSection>
  );
}

function Cell(props: { data: Quantity, currency?: string }) {
  const { t } = useTranslation();
  return (
    <div className="flex text-sm font-normal items-center">
      { props.currency ?
        <span>
          {NumericFormat.currency(props.data.unit).format(props.data.value)}
        </span> :
        <>
          <span>{NumericFormat.iso(props.data.unit, navigator.language, t).getFormattedValue(props.data.value)}</span>
          <span className="ml-1">{NumericFormat.iso(props.data.unit, navigator.language, t).getUnit()}</span>
        </>}
    </div>
  );
}
