import styles from './Workspace.module.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { useAvailableProperties, usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useCurrentAccount } from '../../account/useAccounts';
import TableDashboard from './components/TableDashboard';
import { TFunction, useTranslation } from 'react-i18next';
import { useFetchTreeList } from './useFetchTreeList';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import LazyTreeList from './LazyTreeList';
import { TaskTemplateWithTrees } from '../TaskManager/create/TaskTemplate';
import { OverflowMenu, OverflowMenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@carbon/react';
import PartialTree from '../../tree/PartialTree';
import { SortButton, SortingDirection } from './SortButton';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { ChartColumn, ColorPalette, View } from '@carbon/icons-react';
import useManagedAreas from '../../managed-area/useManagedAreaList';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import { OpenNewWindow } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';
import GraphModal from './components/GraphModal';
import PropertyColorConfiguration from '../../properties/PropertyColorConfiguration';

export default function TreeTable(props: TreeTableProps) {
  const propertyConfigs = usePropertyConfigurations();
  const account = useCurrentAccount();
  const { t } = useTranslation();
  const { managedAreaList } = useManagedAreas(account.organization.id);

  const [trees, setTrees] = useState<PartialTree[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const { urlContext } = useContext(DependencyInjectionContext);
  const navigate = useNavigate();

  const [graphModalProperty, setGraphModalProperty] = useState<string | null>(null);

  const { treeList, firstPageIsLoading } = useFetchTreeList(0, urlContext.getTreeTableSortingString(), props.filters, props.properties, props.taskTemplate?.id);
  const [loading, setLoading] = useState(false);

  const fullSizedContainerRef = useRef<HTMLDivElement | null>(null);

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const getPropertyConfigColor = (property: DisplayableTreeProperty, value: number) => {
    if (propertyConfigs.isLoading) return '255, 255, 255';
    const propertyConfig = propertyConfigs.data.find(it => it.property === property);
    if (propertyConfig) {
      const index = propertyConfig.getRangeIndexForValue(value);
      const colors = PropertyColorConfiguration.getColorsForConfig(propertyConfig);
      return colors[index];
    }
    return '255, 255, 255';
  };

  useEffect(() => {
    if (!treeList) return;
    setLoading(true);
    treeList.getRange(0, (pageCount + 1) * 100).then(trees => {
      localStorage.setItem(
        'loadedTableTrees',
        JSON.stringify(trees.map(it => ({ id: it.id, externalId: it.externalId })))
      );
      setTrees(trees);
      setLoading(false);
    });
    return () => {
      treeList?.cancel();
      setLoading(false);
    };
  }, [treeList, pageCount]);

  useEffect(() => {
    if (!fullSizedContainerRef.current || !treeList) return;

    const onScroll = e => {
      const isScrolledToBottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
      if (isScrolledToBottom) {
        setPageCount(pageCount => pageCount + 1);
      }
    };

    fullSizedContainerRef.current.getElementsByTagName('table')[0].addEventListener('scroll', onScroll);

    return () => {
      if (!fullSizedContainerRef.current) return;
      fullSizedContainerRef.current.getElementsByTagName('table')[0].removeEventListener('scroll', onScroll);
    };
  }, [treeList]);

  function toggleSort(property: DisplayableTreeProperty | 'externalId') {
    return () => {
      const direction = (urlContext.getTreeTableSorting()?.property === property && urlContext.getTreeTableSorting()?.direction) || null;
      urlContext.setTreeTableSorting(property, direction === SortingDirection.ASCENDING ? SortingDirection.DESCENDING : SortingDirection.ASCENDING);
    };
  }

  const navigateToTreeDetails = (event, treeId: string) => {
    event.stopPropagation();
    navigate(`/organizations/${account.organization.id}/inventory/trees/${treeId}`);
  };

  const handleTableTreeSelect = id => {
    urlContext.setTreeId(id);
    urlContext.setCapturePointId(null);
    urlContext.setCameraRotation(null);
  };

  const properties = useAvailableProperties();
  const mapAnalyticsProperties = Object.keys(properties).reduce((acc, key) => {
    acc = [...acc, ...properties[key]];
    return acc;
  }, [] as DisplayableTreeProperty[]);

  if (propertyConfigs.isLoading) return <Spinner />;
  return <>
    {props.open && (
      <div
        className={`${styles.treeListTableContainer}`}
        onMouseEnter={() => {
          if (props.properties.length * 200 + 100 > window.innerWidth) {
            const arrows = document.getElementsByClassName(styles.floatingArrow);
            for (let i = 0; i < arrows.length; i++) {
              const arrow = arrows.item(i);
              (arrow as HTMLDivElement).style.visibility = 'visible';
            }
          }
        }}
        onMouseLeave={() => {
          const arrows = document.getElementsByClassName(styles.floatingArrow);
          for (let i = 0; i < arrows.length; i++) {
            const arrow = arrows.item(i);
            (arrow as HTMLDivElement).style.visibility = 'hidden';
          }
        }}
      >
        <TableDashboard
          treeList={treeList as LazyTreeList}
          windSpeed={props.windSpeed}
          properties={props.properties}
        />
        <div className="mx-8 h-full">
          <div className="text-sm leading-[18px] tracking-tight mb-1">
            {`${t('total')} ${treeList?.getTotalFilteredByArea() || 0} / ${t('filtered')} ${treeList?.getNotFilteredCount() || 0}`}
          </div>
          {props.areaFilterIsSelected ?
            <div
              ref={fullSizedContainerRef}
              className="h-full max-w-full overflow-y-clip [&>section]:h-full [&>section>div]:h-full [&>section>div]:border-none"
              style={{ width: (props.properties.length * 200) + 12 + 'px' }}
            >
              <Table className="max-h-[calc(100%-120px)] border-none" size="lg" useZebraStyles={false} stickyHeader={true}>
                <TableHead style={{ width: (props.properties.length * 200) + 'px', overflow: 'hidden' }}>
                  <TableRow>
                    {props.properties.map(property => {
                      const unit = Tree.getUnit(property, account.organization);
                      const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
                      const columnTitle = t(`tree.${property}`) + translatedUnit;

                      return <td id={property} key={property} className="h-12 flex items-center justify-between p-0" style={{ paddingBlockStart: '0px', paddingInlineEnd: '0px' }}>
                        <Tooltip overlay={columnTitle}>
                          <div className="overflow-x-clip text-ellipsis whitespace-nowrap shrink-[2] w-[86px]">{columnTitle}</div>
                        </Tooltip>
                        <div className="flex shrink-1">
                          <SortButton
                            sortingDirection={urlContext.getTreeTableSorting()?.property === property ? urlContext.getTreeTableSorting()?.direction : null}
                            onClick={toggleSort(property)}
                          />
                          <OverflowMenu menuOptionsClass="w-fit mt-1" className="p-6" flipped>
                            {mapAnalyticsProperties.includes(property as DisplayableTreeProperty) && <OverflowMenuItem
                              itemText={<div className="flex gap-2 p-2 pl-0 whitespace-nowrap">
                                <ColorPalette />
                                {t('treeList.mapAnalytics')}
                                {urlContext.getSelectedTreeProperty() === property ? <View /> : ''}
                              </div>}
                              onClick={() => {
                                if (urlContext.getSelectedTreeProperty() === property) {
                                  urlContext.setSelectedTreeProperty(null);
                                } else {
                                  urlContext.setSelectedTreeProperty(property as DisplayableTreeProperty);
                                }
                              }}
                            />}
                            <OverflowMenuItem
                              itemText={<div className="flex gap-2 p-2 pl-0 whitespace-nowrap">
                                <ChartColumn/>
                                {t('treeList.statistics')}
                              </div>}
                              onClick={() => setGraphModalProperty(property)}
                            />
                          </OverflowMenu>
                        </div>
                      </td>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: (props.properties.length * 200) + 'px' }}>
                  {trees.map(tree =>
                    <TableRow key={tree.id} onClick={() => handleTableTreeSelect(tree.id)} isSelected={urlContext.getTreeId() === tree.id}>
                      {props.properties.map(property => {
                        if (property === DisplayableTreeProperty.ExternalId) {
                          return <TableCell key={property} className="flex justify-between">
                            <span>{tree.externalId}</span>
                            <OpenNewWindow onClick={e => navigateToTreeDetails(e, tree.id)} className="cursor-pointer" />
                          </TableCell>;
                        }

                        const value = property === 'managedAreaId' ? tree['managedAreaName'] : tree[property];

                        return <TableCell key={property} className={`${Tree.isItalicProperty(property) ? 'italic' : ''} relative`}>
                          <span className="w-full" style={selectedTreeProperty === property ? { color: `rgb(${getPropertyConfigColor(property, value)})` } : {}}>
                            {Tree.renderPropertyValue(property, value, t)}
                          </span>
                          <div className="absolute left-0 top-0 bottom-0 w-[1px] bg-[var(--cds-border-subtle-00)]"></div>
                        </TableCell>;
                      })}
                    </TableRow>)}
                </TableBody>
              </Table>
            </div>
            : <EmptyState/>}
          {(firstPageIsLoading || loading) && <Spinner/>}
        </div>
      </div>
    )}
    {graphModalProperty && <GraphModal
      onClose={() => setGraphModalProperty(null)}
      property={graphModalProperty}
      managedAreas={managedAreaList}
    />}
  </>;
}

function EmptyState() {
  const { t } = useTranslation();

  return <div className={styles.emptyState}>
    <h2>{t('workspace.emptyState')}</h2>
    <p>{/*Lorem ipsum*/}</p>
  </div>;
}
export function getAggregationOptions(t: TFunction, configuration: ColumnConfiguration) {
  return [
    { label: t('treeList.min'), value: 'min' as const },
    { label: t('treeList.max'), value: 'max' as const },
    { label: t('treeList.sum'), value: 'sum' as const },
    { label: t('treeList.avg'), value: 'avg' as const },
    { label: t('treeList.med'), value: 'med' as const }
  ].filter(it => configuration[it.value]);
}

export type Property = DisplayableTreeProperty | 'externalId';

export interface ColumnConfiguration {
  min: boolean,
  max: boolean,
  avg: boolean,
  med: boolean,
  sort: boolean,
  sum: boolean,
  graph: boolean
}

export const defaultColumnConfiguration: ColumnConfiguration = {
  min: false,
  max: false,
  avg: false,
  med: false,
  sort: false,
  sum: false,
  graph: false
};

const numericColumnConfiguration: ColumnConfiguration = { min: true, max: true, avg: true, med: true, sort: true, sum: false, graph: true };
const enumColumnConfiguration: ColumnConfiguration = { min: false, max: false, avg: false, med: false, sort: true, sum: false, graph: true };

export const columnConfiguration = new Map<Property, ColumnConfiguration>([
  ['externalId', defaultColumnConfiguration],
  [DisplayableTreeProperty.ScientificName, enumColumnConfiguration],
  [DisplayableTreeProperty.Species, enumColumnConfiguration],
  [DisplayableTreeProperty.Genus, enumColumnConfiguration],
  [DisplayableTreeProperty.ManagedAreaId, enumColumnConfiguration],
  [DisplayableTreeProperty.Status, enumColumnConfiguration],
  [DisplayableTreeProperty.VitalityVigor, enumColumnConfiguration],
  [DisplayableTreeProperty.Height, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyWidth, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkDiameter, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAt80Kmh, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactors, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafArea, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafBiomass, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaIndex, numericColumnConfiguration],
  [DisplayableTreeProperty.CarbonStorage, numericColumnConfiguration],
  [DisplayableTreeProperty.GrossCarbonSequestration, numericColumnConfiguration],
  [DisplayableTreeProperty.NO2, numericColumnConfiguration],
  [DisplayableTreeProperty.SO2, numericColumnConfiguration],
  [DisplayableTreeProperty.PM25, numericColumnConfiguration],
  [DisplayableTreeProperty.CO, numericColumnConfiguration],
  [DisplayableTreeProperty.O3, numericColumnConfiguration],
  [DisplayableTreeProperty.NDVI, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeHealth, numericColumnConfiguration],
  [DisplayableTreeProperty.PotentialEvapotranspiration, numericColumnConfiguration],
  [DisplayableTreeProperty.Transpiration, numericColumnConfiguration],
  [DisplayableTreeProperty.OxygenProduction, numericColumnConfiguration],
  [DisplayableTreeProperty.ThermalComfort, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueRado, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueCavat, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueKoch, numericColumnConfiguration],
  [DisplayableTreeProperty.LeaningAngle, numericColumnConfiguration],
  [DisplayableTreeProperty.WaterIntercepted, numericColumnConfiguration],
  [DisplayableTreeProperty.Evaporation, numericColumnConfiguration],
  [DisplayableTreeProperty.AvoidedRunoff, numericColumnConfiguration],
  [DisplayableTreeProperty.Dieback, numericColumnConfiguration],
  [DisplayableTreeProperty.Slenderness, numericColumnConfiguration],
  [DisplayableTreeProperty.LiveCrownRatio, numericColumnConfiguration],
  [DisplayableTreeProperty.CrownLightExposure, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaPerCrownVolume, numericColumnConfiguration],
  [DisplayableTreeProperty.ViStatus, enumColumnConfiguration],
  [DisplayableTreeProperty.HasMitigation, enumColumnConfiguration],
  [DisplayableTreeProperty.HasAssessmentRequest, enumColumnConfiguration],
  [DisplayableTreeProperty.HasViObservation, enumColumnConfiguration],
  [DisplayableTreeProperty.Mitigations, enumColumnConfiguration],
  [DisplayableTreeProperty.AssessmentRequests, enumColumnConfiguration],
  [DisplayableTreeProperty.ViObservations, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierHeightPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierHeightPerLeafArea, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerHeight, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea, enumColumnConfiguration],
  [DisplayableTreeProperty.OverallOutlierIndex, numericColumnConfiguration]
]);

interface TreeTableProps {
  filters: TreeFilter[],
  properties: Property[],
  areaFilterIsSelected: boolean,
  windSpeed: number,
  open: boolean,
  taskTemplate?: TaskTemplateWithTrees
}
