import * as THREE from 'three';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { RulerGroup } from './RulerGroup';
import { MultiOrbitControl } from './MultiOrbitControl';
import MatrixUtils from '../../utils/MatrixUtils';
import { removeObjectFromScene } from '../../utils/ThreeJsHelpers';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Organization } from '../../organization/Organization';

export class PointCloudView {
  static readonly FIELD_OF_VIEW = 30;

  private canvas: HTMLCanvasElement | null = null;
  private renderer: THREE.WebGLRenderer | null = null;
  private camera: THREE.PerspectiveCamera | null = null;
  private controls: MultiOrbitControl | null = null;
  private htmlRenderer = new CSS2DRenderer();
  private scene = new THREE.Scene();

  private id = Math.random().toString(16).slice(-7);

  private grid = new THREE.Group();

  render = () => {
    if (this.camera === null || this.renderer === null) return;
    this.grid.rotation.copy(this.camera.rotation);
    this.grid.rotateX(Math.PI / 2);
    this.renderer.render(this.scene, this.camera);
    this.htmlRenderer.render(this.scene, this.camera);
  };

  setCanvasSize = () => {
    if (!this.canvas || !this.camera || !this.renderer) {
      return;
    }

    const width = this.canvas.parentElement?.clientWidth ?? 0;
    const height = this.canvas.parentElement?.clientHeight ?? 0;
    this.canvas.style.width = width + 'px';
    this.canvas.style.height = height + 'px';

    this.camera.aspect = width / height;

    this.htmlRenderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.camera.updateProjectionMatrix();

    this.render();
  };

  init(canvas: HTMLCanvasElement, controls: MultiOrbitControl) {
    this.canvas = canvas;

    const aspect = this.canvas.clientWidth / this.canvas.clientHeight;
    this.camera = new THREE.PerspectiveCamera(PointCloudView.FIELD_OF_VIEW, aspect);

    this.htmlRenderer.domElement.style.position = 'absolute';
    this.htmlRenderer.domElement.style.top = '0px';
    this.htmlRenderer.domElement.style.left = '0px';
    this.canvas.parentElement!.appendChild(this.htmlRenderer.domElement);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.canvas,
      context: this.canvas.getContext('webgl')!
    });

    this.controls = controls;
    this.controls.register(this.id, this.camera, this.htmlRenderer.domElement);

    this.initGrid();
  }

  clear() {
    this.htmlRenderer.domElement.replaceChildren();
    this.scene.clear();
  }

  clearHtmlRenderer() {
    this.htmlRenderer.domElement.replaceChildren();
  }

  addToScene(object: THREE.Object3D) {
    this.scene.add(object);
  }

  removeFromScene(object: THREE.Object3D) {
    this.scene.remove(object);
  }

  addGrid() {
    this.addToScene(this.grid);
  }
  async addCable(cableUrl: string, treePosition: [number, number, number], color: string) {
    const response = await new Promise((resolve, reject) => new GLTFLoader()
      .setWithCredentials(true)
      .load(cableUrl, resolve, () => {}, reject));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const object: THREE.Group = response.scene;
    object.children.forEach(it => ((it as THREE.Mesh).material = new THREE.MeshBasicMaterial({
      color,
      side: THREE.DoubleSide
    })));
    const rotatedTreePosition = new THREE.Vector3(...treePosition).applyAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
    const translation = rotatedTreePosition.multiplyScalar(-1).toArray();
    object.children.forEach(it => (it as THREE.Mesh).geometry.translate(...translation));
    this.addToScene(object);
  }

  async addLShape(organization: Organization, treePosition: [number, number, number], externalTreeId: string) {
    const lShapeObjectUrl = organization.getCDNUrlFromRelativePath(`tasks/US_PA_PIT23_0177_A_009/infrastructure/road_clearing/${externalTreeId}_L_shape4.gltf`);

    const response = await new Promise((resolve, reject) => new GLTFLoader().setWithCredentials(true).load(lShapeObjectUrl, resolve, () => {}, reject));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const object: THREE.Group = response.scene;
    const translation = treePosition.map(it => -it) as [number, number, number];
    object.children.forEach(it => ((it as THREE.Mesh).material = new THREE.MeshBasicMaterial({
      color: 'white',
      transparent: true,
      opacity: 0.1,
      side: THREE.DoubleSide
    })));
    object.children.forEach(it => (it as THREE.Mesh).geometry.translate(...translation));
    this.addToScene(object.rotateX(-Math.PI / 2));
  }

  addPointClouds(pointClouds: THREE.Group[]) {
    this.addToScene(pointClouds.reduce((group, it) => group.add(it), new THREE.Group().rotateX(-Math.PI / 2)));
  }

  lookAtTree(treeHalfHeight: number, canopyDirection: number) {
    if (!this.camera || !this.controls) return;

    this.camera.position.copy(
      new THREE.Vector3(0, treeHalfHeight, this.calculateIdealDistanceFromTree(treeHalfHeight))
        .applyMatrix3(MatrixUtils.degToYRotationMatrix(canopyDirection - 90))
    );

    this.controls.lookAtHeight(treeHalfHeight);
  }

  zoomIn() {
    if (this.controls && this.camera) {
      this.controls.zoomIn(this.camera);
      this.render();
    }
  }

  zoomOut() {
    if (this.controls && this.camera) {
      this.controls.zoomOut(this.camera);
      this.render();
    }
  }

  resetTo(treeHalfHeight: number, canopyDirection: number) {
    if (!this.controls || !this.camera) return;
    this.controls = this.controls.reset(new THREE.Vector3().setY(treeHalfHeight).setZ(this.calculateIdealDistanceFromTree(treeHalfHeight)));
    this.lookAtTree(treeHalfHeight, canopyDirection);
  }

  addRulers(rulers: RulerGroup[]) {
    rulers.forEach(it => this.addToScene(it));
  }

  addEventListeners() {
    if (!this.controls) return;
    this.controls.addRenderCallback(this.id, this.render);
    window.addEventListener('resize', this.setCanvasSize);
  }

  removeEventListeners() {
    if (!this.controls) return;
    this.controls.removeListeners(this.id);
    window.removeEventListener('resize', this.setCanvasSize);
  }

  dispose() {
    this.scene.children.forEach(obj => {
      removeObjectFromScene(obj, this.scene);
    });
    this.scene.clear();
    this.renderer?.dispose();
    this.controls?.dispose(this.id);
  }

  private initGrid() {
    const gridColor = window.getComputedStyle(document.body).getPropertyValue('--pointcloud-grid').trim();
    const largeGrid = new THREE.GridHelper(100, 100, gridColor, gridColor);
    (largeGrid.material as THREE.Material).setValues({ transparent: true });
    const smallGrid = new THREE.GridHelper(100, 500, gridColor, gridColor);
    (smallGrid.material as THREE.Material).setValues({ transparent: true, opacity: 0.35 });
    this.grid = new THREE.Group().add(largeGrid, smallGrid);
  }

  private calculateIdealDistanceFromTree(treeHalfHeight: number) {
    const effectiveFieldOfViewInRadians = THREE.MathUtils.degToRad(PointCloudView.FIELD_OF_VIEW / 2);
    return 1.5 * 1.25 * treeHalfHeight / Math.tan(effectiveFieldOfViewInRadians);
  }
}
