import { LasLoaderStyle } from '../las-loader-style';
export class DefaultLasLoaderStyle implements LasLoaderStyle {
  public getPointVisible(classification: number, _intensity: number): boolean {
    return [0, 1, 20, 21, 22, 23].includes(classification);
  }

  public getPointColor(classification: number, intensity: number): [number, number, number, number] {
    switch (classification) {
    case 23:
      return [
        (104 / 255),
        (172 / 255),
        (35 / 255),
        0.2 * intensity
      ];
    case 22:
      return [
        ((198 - 40) / 255),
        ((152 - 40) / 255),
        ((116 - 40) / 255),
        0.2 * intensity
      ];
    case 21:
      return [
        (51 / 255),
        (102 / 255),
        (0 / 255),
        intensity
      ];
    case 20:
      return [
        (95 / 255) * 2.0,
        (52 / 255) * 2.0,
        (33 / 255) * 2.0,
        1.0
      ];
    default:
      return [
        1,
        1,
        1,
        0.2 * intensity
      ];
    }
  }
}
