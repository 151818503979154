import { StreetViewProps } from './useStreetViewProps';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import CapturePoint from '../../../../capture-point/CapturePoint';
import { useTracking } from '../../../../analytics/useTracking';
import SafetyFactorMarkerGroup from '../../../../components/PointCloud/SafetyFactorMarkerGroup';
import { LeaningAngleGroup } from '../../../../components/PointCloud/LeaningAngleGroup';
import { RulerGroup } from '../../../../components/PointCloud/RulerGroup';
import PanoramicDataHandler from '../../../Explore/panoramic-view/PanoramicDataHandler';
import { newVector3FromLocalCoords } from '../../../../utils/ThreeJsHelpers';
import * as THREE from 'three';
import { PanoramicViewLight } from '../../../Explore/panoramic-view/PanoramicViewLight';
import styles from './StreetViewTile.module.scss';
import useMountedEffect from '../../../../hooks/useMountedEffect';
import { LoadablePointCloud } from '../../../../point-cloud/LoadablePointCloud';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { DetailsContext } from '../../CarbonDetails';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import StreetViewRiskOverlay from '../components/StreetViewRiskOverlay';
import { useCapturePoints } from '../../../../capture-point/useCapturePoints';
import loadLowResolutionImageOfCapturePoint
  from '../../../../components/Panorama/FullPanoramaSphere/loadLowResolutionImageOfCapturePoint';

export default function StreetViewContent(props: StreetViewProps) {
  const [isLoaded, setLoaded] = useState(false);
  const currentAccount = useCurrentAccount();
  const { rulers, riskOverlayIsVisible, showRootZone, showSCRZ, showCRZ } = useContext(DetailsContext);

  const organizationId = currentAccount.organization.id;

  const { t } = useTranslation();
  const { capturePointService, treeService, urlContext } = useContext(DependencyInjectionContext);
  const { bestCapturePoint, capturePoints, isLoading } = useCapturePoints(organizationId, props.tree);
  const capturePointId = urlContext.getCapturePointId();
  const account = useCurrentAccount();
  const [currentCapturePoint, setCurrentCapturePoint] = useState<CapturePoint | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { events, track } = useTracking();

  const environmentPointSize = 1.6;

  const safetyFactorGroupRef = useRef<SafetyFactorMarkerGroup | null>(null);
  const leaningAngleGroupRef = useRef<LeaningAngleGroup | null>(null);
  const rulersRef = useRef<RulerGroup[]>([]);

  const dataHandler = useMemo(() =>
    new PanoramicDataHandler(treeService, capturePointService, account.organization),
  [treeService, capturePointService, account.organization]);

  const isCanvasRendered = useCallback(node => {
    if (node !== null) {
      canvasRef.current = node;
    }
  }, []);

  const getTreePosition = useCallback(tree => {
    return newVector3FromLocalCoords(tree.localizedLocation)
      .sub(new THREE.Vector3(...props.tree.localizedLocation)
        .applyAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2));
  }, [props.tree.localizedLocation]);

  useEffect(() => {
    if (!canvasRef.current) return;
    props.viewRef.current = PanoramicViewLight.create(canvasRef.current!, styles, account.organization.isMetric);
    props.viewRef.current?.listen(() => {});
    props.viewRef.current.render();
    return () => {
      props.viewRef.current?.dispose();
      props.viewRef.current?.clearListeners();
    };
  }, []);

  useMountedEffect(() => {
    const view = props.viewRef.current;
    if (!view) return;

    if (props.lineMeasurementEnabled) {
      track(events.LINE_MEASUREMENT_CHANGE_IN_DETAILS, { enabled: true });
      view.enableLineMeasure();
    } else {
      track(events.LINE_MEASUREMENT_CHANGE_IN_DETAILS, { enabled: false });
      view.disableLineMeasure();
    }
    view.render();
    return () => view.disableLineMeasure();
  }, [props.lineMeasurementEnabled]);

  useEffect(() => {
    if (!canvasRef.current?.parentElement) return;
    const ro = new ResizeObserver(() => props.viewRef.current?.setSize());
    ro.observe(canvasRef.current?.parentElement);
    return () => ro.disconnect();
  }, [props.viewRef]);

  useEffect(() => props.viewRef.current?.setTreePosition(props.tree), [props.tree, props.viewRef]);

  useEffect(() => {
    const view = props.viewRef.current;
    if (!view) return;

    const innerDiv = document.createElement('div');
    const innerStyle = {
      width: '12px',
      height: '12px',
      background: '#FFE846',
      borderRadius: '50%',
      margin: '4px'
    };
    Object.assign(innerDiv.style, innerStyle);

    const outerDiv = document.createElement('div');
    const outerStyle = {
      width: '24px',
      height: '24px',
      border: '2px solid #FFE846',
      borderRadius: '50%',
      background: '#3F464B'
    };
    Object.assign(outerDiv.style, outerStyle);

    outerDiv.appendChild(innerDiv);
    const marker = new CSS2DObject(outerDiv);
    marker.position.copy(getTreePosition(props.tree));

    if (!props.showPointcloud) {
      view.addToScene(marker);
    } else {
      view.removeFromScene(marker);
    }
    view.render();
    return () => {
      view.removeFromScene(marker);
    };
  }, [props.tree, props.viewRef, props.showPointcloud]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view) return;
    view.measurement.reset();
    new LoadablePointCloud(props.tree.getPointCloudUrl(account.organization)).loadIntoWithTreeId(props.tree.id, props.tree.localizedLocation, undefined, props.hideCanopy).then(pc => {
      if (pc && pc.belongsTo(props.tree) && isLoaded) {
        view.removePointCloud();
        view.setPointCloud(pc);
        if (!props.showPointcloud) {
          view.hideTreePointCloud();
        }
        view.render();
      }
    });

    if (showRootZone) view.displayRootZone(props.tree, account.organization.isMetric);
    else view.hideRootZone();
  }, [props.viewRef, props.tree, props.showPointcloud, account.organization, isLoaded, showRootZone, props.hideCanopy]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view) return;
    if (showCRZ) view.displayCRZ(props.tree, account.organization.isMetric);
    else view.hideCRZ();
    view.render();
  }, [props.viewRef, props.tree, account.organization, isLoaded, showCRZ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view) return;
    if (showSCRZ) view.displaySCRZ(props.tree, account.organization.isMetric);
    else view.hideSCRZ();
    view.render();
  }, [props.viewRef, props.tree, account.organization, isLoaded, showSCRZ]);

  useEffect(() => {
    const view = props.viewRef.current;

    if (!view || !props.tree) return;

    const scalingFactor = props.tree!.height / 6;
    safetyFactorGroupRef.current = new SafetyFactorMarkerGroup(
      2, 0.1, 50, props.tree.getWindDirectionAngle(80) || 0, scalingFactor, () => view.render()
    );
    safetyFactorGroupRef.current.position.copy(getTreePosition(props.tree));
    safetyFactorGroupRef.current.renderOrder = 100;

    view.scene.add(safetyFactorGroupRef.current);

    return () => {
      view.scene.remove(safetyFactorGroupRef.current!);
      safetyFactorGroupRef.current = null;
    };
  }, [props.viewRef, props.tree, getTreePosition, account.organization]);

  useEffect(() => {
    const view = props.viewRef.current;

    if (!view || !props.tree) return;

    leaningAngleGroupRef.current = new LeaningAngleGroup(
      props.tree?.height || 0,
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(...(props.tree?.leaningVector || [0, 0, 0])),
      t('analytics.properties.leaningAngle'),
      () => view.render(),
      true
    );
    leaningAngleGroupRef.current.renderOrder = 100;
    leaningAngleGroupRef.current.position.copy(getTreePosition(props.tree));

    view.scene.add(leaningAngleGroupRef.current);

    return () => {
      view.scene.remove(leaningAngleGroupRef.current!);
      leaningAngleGroupRef.current = null;
    };
  }, [props.viewRef, props.tree, t, getTreePosition]);

  useEffect(() => {
    const view = props.viewRef.current;

    if (!view || !props.tree) return;
    view.clear2DRenderer();

    const isMetrical = account.organization.getIsMetrical();
    rulersRef.current = [
      RulerGroup.forHeight(props.tree, t('analytics.properties.height'), () => view.render(), isMetrical, true),
      RulerGroup.forFirstBifurcation(props.tree, t('analytics.properties.trunkHeight'), () => view.render(), isMetrical, true),
      RulerGroup.forCanopyWidth(props.tree, t('analytics.properties.canopyWidth'), () => view.render(), isMetrical, true),
      RulerGroup.forCanopyHeight(props.tree, t('analytics.properties.canopyHeight'), () => view.render(), isMetrical, true),
      RulerGroup.forDBH(account.organization, props.tree, t('analytics.properties.trunkDiameter'), () => view.render(), true)
    ];

    rulersRef.current
      .filter(it => rulers.includes(it.propertyName))
      .forEach(ruler => {
        if (rulers.length > 1) {
          ruler.displayedInOriginalPlace();
        } else {
          ruler.displayedInTheMiddle();
        }
      });

    view.scene.add(...rulersRef.current);

    return () => {
      view.scene.remove(...rulersRef.current);
      rulersRef.current = [];
    };
  }, [props.viewRef, props.tree, account.organization, t, getTreePosition, rulers]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view || !view.scene ||
      !safetyFactorGroupRef.current ||
      !leaningAngleGroupRef.current
    ) return;

    safetyFactorGroupRef.current.visible = rulers.includes('safetyFactor');
    leaningAngleGroupRef.current?.setVisibility(rulers.includes('leaningAngle'));
    rulersRef.current.forEach(it => it.setVisibility(rulers.includes(it.propertyName)));
    view.render();
  }, [props.viewRef, account.organization, t, props.tree, rulers]);

  useEffect(() => {
    new LoadablePointCloud(props.tree.getEnvironmentPointCloudUrl(account.organization), environmentPointSize)
      .loadInto(props.tree.localizedLocation, true).then(pc => {
        const view = props.viewRef.current;
        if (pc && view) {
          view.removeEnvironmentPointCloud();
          view.setEnvironmentPointCloud(pc);
          if (!props.showEnvironmentPointcloud) {
            view.hideEnvironmentPointCloud();
          }
          view.render();
        }
      });
  }, [props.viewRef, props.tree, props.showEnvironmentPointcloud, account.organization]);

  useEffect(() => {
    if (capturePointId) {
      const capturePoint = props.capturePoints.find(it => it.id === capturePointId);
      if (capturePoint) {
        setCurrentCapturePoint(capturePoint as any);
      } else {
        capturePointService.show(organizationId, capturePointId).then(setCurrentCapturePoint);
      }
    }
  }, [props.tree.capturePointId, organizationId, capturePointId, props.tree]);

  useEffect(() => {
    if (capturePointId || isLoading) return;

    const nextCapturePoint = bestCapturePoint;
    if (!nextCapturePoint || (currentCapturePoint && nextCapturePoint.id === currentCapturePoint.id)) return;
    urlContext.setCapturePointId(nextCapturePoint.id);
    urlContext.setCameraRotation(null);
  }, [bestCapturePoint, isLoading]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view || !view.scene) return;

    props.setCapturePoints(capturePoints);
    view.setCapturePointsAsCss3D(capturePoints, onCapturePointChange);
    view.render();
  }, [props.tree, capturePoints]);

  const onCapturePointChange = (nextCapturePointId: string) => {
    track(events.CAPTURE_POINT_CHANGE_FROM_DETAILS_VIEW, { from: capturePointId, to: nextCapturePointId });
    urlContext.setCapturePointId(nextCapturePointId);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = props.viewRef.current;
    if (!canvas || !view || !view.scene || !currentCapturePoint || !dataHandler) return;

    const abortController = new AbortController();
    (async () => {
      const images = await dataHandler.loadImages(currentCapturePoint);
      if (!images) return;
      view.setSphereAndPutCameraThere(images).then(() => {
        view.lookAtTree(props.tree, currentAccount.organization.isMetric);
        view.setSize();
        view.render();
        setLoaded(true);
      });
      loadLowResolutionImageOfCapturePoint(account.organization, capturePoints.find(it => it.id === currentCapturePoint.leftNeighborId) || null, abortController).then();
      loadLowResolutionImageOfCapturePoint(account.organization, capturePoints.find(it => it.id === currentCapturePoint.rightNeighborId) || null, abortController).then();
    })();
    return () => {
      if (!canvas) return;
      view.remove();
      setLoaded(false);
      abortController.abort();
    };
  }, [currentCapturePoint]);

  useEffect(() => {
    const view = props.viewRef.current;
    if (!view) return;
    if (props.showEnvironmentPointcloud) {
      view.showEnvironmentPointCloud();
    } else {
      view.hideEnvironmentPointCloud();
    }
    view.render();
  }, [props.viewRef, props.showEnvironmentPointcloud]);

  return <>
    <div className={`w-full h-full ${props.isCaptureModeActive ? 'border-4 border-cerulean-blue-700 overflow-hidden rounded-xl border-solid' : ''}`}>
      <canvas id="streetView" ref={isCanvasRendered} style={{ visibility: isLoaded ? 'visible' : 'hidden' }} />
      {!isLoaded &&
        <div>
          <Spinner />
        </div>}
      {riskOverlayIsVisible && <StreetViewRiskOverlay
        tree={props.tree}
        viewRef={props.viewRef}
      />}
    </div>
  </>;
}
