import PageLayout from '../../components/UI/Page/PageLayout';
import greeHillLogo from '../../components/UI/Icon/svg/greehill-logo.svg';
import { TaskManagerView } from './TaskManagerView';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import { Home, NavArrowRight } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import CreateTasks from './create/CreateTasks';
import ManageTasks from './manage/ManageTasks';
import TaskCreationFromTemplate from './create/TaskCreationFromTemplate';
import ManageTaskTrees from './manage/ManageTaskTrees';
import { useCurrentAccount } from '../../account/useAccounts';
import { Flippers } from '../../switches/Flippers';
import LegacyTaskCreationFromTemplate from './create/LegacyTaskCreationFromTemplate';
import { AccountType } from '../../account/AccountType';
import React from 'react';
import CarbonTaskManager from '../CarbonTaskManager/CarbonTaskManager';

export default function TaskManager() {
  const match = useMatch('/organizations/:organizationId/task-manager/:subItem/*');
  const { t } = useTranslation();
  const subItem = match?.params.subItem ?? TaskManagerView.MANAGE;
  const account = useCurrentAccount();
  const navigate = useNavigate();
  const organization = account.organization;

  if (account.type === AccountType.Client) {
    navigate('/organizations/' + match?.params.organizationId + '/inventory');
    return null;
  }

  return (
    <PageLayout>
      <PageLayout.LeftNavExtensions>
        <img
          src={greeHillLogo}
          alt="greeHill" />
      </PageLayout.LeftNavExtensions>
      <PageLayout.CenterNavExtensions>
      </PageLayout.CenterNavExtensions>
      <PageLayout.Content>
        <div className="pt-6 px-8 flex-col justify-start items-start gap-2 inline-flex rounded-tl-lg bg-outer-space-800 w-full h-full">
          <div className="h-5 justify-start items-center gap-2 inline-flex text-outer-space-300">
            <Home className="w-4 h-4 relative" />
            <NavArrowRight className="w-4 h-4 relative" />
            <div className="text-[13px] font-normal font-['Inter'] leading-tight">
              {t('mainMenu.taskManager')}
            </div>
            <NavArrowRight className="w-4 h-4 relative" />
            <div className="text-outer-space-100 text-[13px] font-normal font-['Inter'] leading-tight">
              {t(`taskManager.subItems.${subItem}`)}
            </div>
          </div>
          <Routes>
            {/* You must add these paths to the app router as well */}
            <Route path="create" element={<CreateTasks />}/>
            <Route path='create/:creatingFromId' element={organization.isEnabled(Flippers.release24q3) ? <TaskCreationFromTemplate /> : <LegacyTaskCreationFromTemplate />}/>
            <Route path="manage" element={<ManageTasks />}/>
            <Route path="manage/:taskId" element={<ManageTaskTrees />}/>
          </Routes>
        </div>
      </PageLayout.Content>
    </PageLayout>
  );
}
