import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LegacyPageLayout from '../../components/UI/Page/LegacyPageLayout';
import styles from './Settings.module.scss';
import { UserPreferences } from './UserPreferences';
import SettingsTabBar from '../../components/Settings/SettingsTabBar';
import { useTranslation } from 'react-i18next';
import { Members } from './Members';
import useAccounts, { useCurrentAccount } from '../../account/useAccounts';
import Organization from './Organization';
import OrganizationDataStandards from './DataStandards/OrganizationDataStandards';
import { FunctionButton } from '../../components/UI/Button/LegacyButton';
import { ArrowLeft } from 'iconoir-react';
import { useContext, useEffect, useState } from 'react';
import LegacyCreateOrganization from './LegacyCreateOrganization';
import { AuthContext } from '../../auth/AuthContext';
import { Flippers } from '../../switches/Flippers';
import { RolesAndPermissions } from './RolesAndPermissions';
import CarbonSettings from '../CarbonSettings/CarbonSettings';

export default function Settings() {
  const { isLoadingAccounts } = useAccounts();
  const user = useContext(AuthContext).user;
  const { t } = useTranslation();
  const currentAccount = useCurrentAccount();
  const location = useLocation();
  const [fromPath, setFromPath] = useState('');

  useEffect(() => {
    setFromPath(location.state?.['from']);
  }, []);

  if (!isLoadingAccounts && !currentAccount.exists()) {
    return <Navigate to="/" />;
  }

  if (currentAccount.organization.isEnabled(Flippers.carbonRedesign) && (location.pathname.includes('roles') || location.pathname.includes('user'))) {
    return <CarbonSettings />;
  }

  function getNavigation() {
    return <LegacyPageLayout.LeftNavExtensions>
      <div className={styles.breadCrumbContainer}>
        <Link to={fromPath || currentAccount.linkToMap()} className={styles.breadCrumb}>
          <FunctionButton icon={<ArrowLeft />}/>
        </Link>
        <h1 className={styles.title}>{t('settings.title')}</h1>
      </div>
    </LegacyPageLayout.LeftNavExtensions>;
  }

  return (
    <LegacyPageLayout>
      {getNavigation()}

      <LegacyPageLayout.Content>
        <div className={styles.settings}>
          <div className={styles.container}>

            <div className={styles.tabBar}>
              <SettingsTabBar currentAccount={currentAccount} />
            </div>

            <div className={styles.content}>
              <Routes>
                <Route path="user" element={<UserPreferences />} />

                {currentAccount.organization.isEnabled(Flippers.release24q3) && (
                  <Route path="roles" element={<RolesAndPermissions />} />
                )}

                {currentAccount.canManageMembers() && (
                  <Route path="members" element={<Members currentAccount={currentAccount} organizationId={currentAccount.organization.id} />} />
                )}

                {currentAccount.canEditOrganizationSettings() && (
                  <Route path="organization" element={<Organization currentAccount={currentAccount} />} />
                )}

                {currentAccount.canEditDataStandards() && (
                  <Route path="data-standards" element={<OrganizationDataStandards/>} />
                )}

                {user.isSuperOwner() && (
                  <Route path="create-organization" element={<LegacyCreateOrganization/>} />
                )}

                {currentAccount.exists() && <Route path="*" element={<Navigate to="user" />} />}
              </Routes>
            </div>
          </div>
        </div>
      </LegacyPageLayout.Content>
    </LegacyPageLayout>
  );
}
