import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { Language, Map } from 'iconoir-react';
import { DisplayTreesOptions } from '../../../components/Navbar/DisplayModes';
import { useTracking } from '../../../analytics/useTracking';
import { OverflowMenu, RadioButton, RadioButtonGroup, Toggle } from '@carbon/react';
import { Layers } from '@carbon/icons-react';

export enum MapStyle {
  Default = 'default',
  Satellite = 'satellite'
}

export default function MapStyleSelector() {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const { events, track } = useTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [displayISO, setDisplayISO] = useState(urlContext.isIsoMapEnabled());
  const [displayValues, setDisplayValues] = useState(urlContext.areTreeMarkersVisible());

  const views = [
    { title: MapStyle.Default, icon: <Map />, isDisabled: false },
    { title: MapStyle.Satellite, icon: <Language />, isDisabled: false }
  ];

  const handleViewSelect = view => {
    track(events.MAP_STYLE_CHANGE, { style: view.title });
    if (view.title === MapStyle.Default || view.title === MapStyle.Satellite) {
      urlContext.setCapturePointId('');
    }
    urlContext.setStyle(view.title);
  };

  const displayTreeDropdownOptions = [
    { id: 'markers', translationKey: 'displayModes.displayTreesAsMarkers' },
    { id: 'canopy', translationKey: 'displayModes.displayTreesAsCanopy' }
  ];

  const handleDisplayTreesChange = (item: DisplayTreesOptions) => {
    track(events.DISPLAY_MODES_DISPLAY_TREES_AS_CHANGE, { displayTreesAs: item });
    urlContext.setDisplayMode(item);
  };

  const handleISOChange = () => {
    const enabled = !displayISO;
    track(events.DISPLAY_MODES_DISPLAY_ISO_MAP, { enabled });
    setDisplayISO(enabled);
    urlContext.setIsoMapEnabled(enabled);
  };

  const handleDisplayValueChange = () => {
    const enabled = !displayValues;
    track(events.DISPLAY_MODES_DISPLAY_VALUES, { enabled });
    setDisplayValues(enabled);
    urlContext.setDisplayValuesDisabled(!enabled);
  };

  return (
    <div>
      <OverflowMenu
        className="bg-[#22272ACC] hover:bg-[#22272A]"
        direction="top"
        flipped
        size="lg"
        renderIcon={Layers}
        menuOptionsClass="w-[238px]"
        iconClass={isOpen ? '' : '!fill-greehill-00'}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        iconDescription={t('displayModes.title')}
      >
        <RadioButtonGroup className="w-full" name="map-style" orientation={'vertical'}>
          {views.map(view => (
            <RadioButton key={view.title} checked={urlContext.getStyle() === view.title} onClick={() => handleViewSelect(view)} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" labelText={t('viewSelector.' + view.title)} value={view.title} />
          ))}
        </RadioButtonGroup>
        <RadioButtonGroup className="w-full border-solid border-0 border-t border-t-[var(--cds-border-subtle)]" name="marker-type" orientation={'vertical'}>
          {displayTreeDropdownOptions.map(option => (
            <RadioButton key={option.id} checked={urlContext.getDisplayMode() === option.id} onClick={() => handleDisplayTreesChange(option.id as DisplayTreesOptions)} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" labelText={t(option.translationKey)} value={option.id} />
          ))}
        </RadioButtonGroup>
        <div className="w-full border-solid border-0 border-t border-t-[var(--cds-border-subtle)]">
          <Toggle toggled={displayValues} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" id="toggle1" labelA={t('displayModes.displayValues')} labelB={t('displayModes.displayValues')} onToggle={handleDisplayValueChange} />
          <Toggle toggled={displayISO} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" id="toggle2" labelA={t('displayModes.displayIso')} labelB={t('displayModes.displayIso')} onToggle={handleISOChange} />
        </div>
      </OverflowMenu>
    </div>
  );
}
