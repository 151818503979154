import { ButtonsOverlay, TileButton, TileContainer, TileContent } from '../TileLayout';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentAccount } from '../../../../account/useAccounts';
import styles from './TwinViewTile.module.scss';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { PointCloudView } from '../../../../components/PointCloud/PointCloudView';
import { MultiOrbitControl } from '../../../../components/PointCloud/MultiOrbitControl';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import * as THREE from 'three';
import { useTranslation } from 'react-i18next';
import { RulerGroup } from '../../../../components/PointCloud/RulerGroup';
import DetailedTree from '../../../../tree/DetailedTree';
import { getEndYearFromScanInterval } from '../../../../tree/TseTreeScan';
import { DetailsContext } from '../../CarbonDetails';
import DateSelector from '../components/DateSelector';
import ViewSelector from '../components/ViewSelector';
import { DisplayableTreeProperty } from '../../../../tree/Tree';
import { LoadablePointCloud } from '../../../../point-cloud/LoadablePointCloud';
import RiskOverlay from '../components/RiskOverlay';
import ZoomIn from '@carbon/icons-react/lib/ZoomIn';
import ZoomOut from '@carbon/icons-react/lib/ZoomOut';
import { Close, Reset, SplitScreen } from '@carbon/icons-react';
import { TreeDisplays } from '../../../Details/TreeDisplayTile/TreeDisplays';

export default function TwinViewTile(props: TwinViewTileProps) {
  const { t } = useTranslation();
  const { tree } = props;
  const [seed, setSeed] = useState(Math.random().toString(16).slice(-7));
  const [showDetails, setDetailsVisibility] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const viewRef = useRef(new PointCloudView());
  const treePointCloud = useRef<THREE.Group>(new THREE.Group());
  const environmentPointCloud = useRef<THREE.Group>(new THREE.Group());
  const historicalPointCloud = useRef<THREE.Group>(new THREE.Group());
  const rulersRef = useRef<RulerGroup[]>([]);
  const [hideCanopy, setHideCanopy] = useState(false);

  const account = useCurrentAccount();
  const { rulers, riskOverlayIsVisible } = useContext(DetailsContext);

  const [isViewInitialized, setIsViewInitialized] = useState(false);
  const [isPointCloudLoading, setIsPointCloudLoading] = useState(true);

  const [envPointCloudTriggeredByAccordion, setEnvPointCloudTriggeredByAccordion] = useState(false);
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const resetView = () => setSeed(Math.random().toString(16).slice(-7));

  const selectedTab = urlContext.getSelectedDataPanelTab();
  const selectedAccordion = urlContext.getSelectedEnvAccordion();
  const selectedYear = urlContext.getSelectedYear();

  const selectedScan = useMemo(() => tree.tseTreeScans?.find(scan => getEndYearFromScanInterval(scan.scanInterval) === selectedYear), [tree.tseTreeScans, selectedYear]);

  useEffect(() => {
    if (envPointCloudTriggeredByAccordion) {
      setDetailsVisibility(false);
      setEnvPointCloudTriggeredByAccordion(false);
    }
  }, [envPointCloudTriggeredByAccordion, showDetails, selectedAccordion]);

  useEffect(() => {
    if (canvasRef.current === null) return;

    const view = viewRef.current;
    view.init(canvasRef.current, new MultiOrbitControl());

    setIsViewInitialized(true);

    return () => {
      view.dispose();
    };
  }, [canvasRef.current]);

  useEffect(() => {
    if (!props.tree) return;
    const halfHeight = props.tree.metricHeight / 2;
    viewRef.current.resetTo(halfHeight, props.tree.canopyDirection);
  }, [isViewInitialized, props.tree, seed, account.organization]);

  useEffect(() => {
    if (props.tree === null) return;

    const isMetrical = account.organization.getIsMetrical();

    rulersRef.current = [
      RulerGroup.forHeight(props.tree, t('analytics.properties.height'), viewRef.current.render, isMetrical),
      RulerGroup.forFirstBifurcation(props.tree, t('analytics.properties.trunkHeight'), viewRef.current.render, isMetrical),
      RulerGroup.forCanopyWidth(props.tree, t('analytics.properties.canopyWidth'), viewRef.current.render, isMetrical),
      RulerGroup.forCanopyHeight(props.tree, t('analytics.properties.canopyHeight'), viewRef.current.render, isMetrical),
      RulerGroup.forDBH(account.organization, props.tree, t('analytics.properties.trunkDiameter'), viewRef.current.render, false)
    ];
  }, [props.tree, t, account.organization]);

  useEffect(() => {
    if (!canvasRef.current?.parentElement) return;
    const ro = new ResizeObserver(() => viewRef.current?.setCanvasSize());
    ro.observe(canvasRef.current?.parentElement);
    return () => ro.disconnect();
  }, []);

  useEffect(() => {
    if (tree === null) return;
    setIsPointCloudLoading(true);

    let isCancelled = false;
    const environmentPointSize = 1.6;
    const pos = tree.localizedLocation;
    Promise.all([
      new LoadablePointCloud(tree.getPointCloudUrl(account.organization))
        .loadInto(pos, false, undefined, hideCanopy)
        .then(pc => {
          if (isCancelled) return;
          treePointCloud.current.add(pc);
        }),
      new LoadablePointCloud(tree.getEnvironmentPointCloudUrl(account.organization), environmentPointSize)
        .loadInto(pos, true)
        .then(pc => {
          if (isCancelled) return;
          environmentPointCloud.current.add(pc);
        }),
      selectedScan?.pointCloudPath ? new LoadablePointCloud(account.organization.getCDNUrlOfTreeDataFromRelativePath(selectedScan.pointCloudPath))
        .loadInto(selectedScan.localizedLocation.coordinates, true, new THREE.PointsMaterial({ size: 0.2, color: '#C96DD8', sizeAttenuation: false }))
        .then(pc => {
          historicalPointCloud.current.add(pc);
        }) : Promise.resolve()
    ])
      .then(() => {
        viewRef.current.render();
        if (isCancelled) return;
        setIsPointCloudLoading(false);
      });
    return () => {
      treePointCloud.current.clear();
      environmentPointCloud.current.clear();
      historicalPointCloud.current.clear();
      isCancelled = true;
    };
  }, [tree, t, account.organization, selectedScan, hideCanopy]);

  useEffect(() => {
    if (
      tree === null ||
      isPointCloudLoading ||
      !isViewInitialized
    ) return;

    viewRef.current.addEventListeners();
    viewRef.current.clear();

    viewRef.current.addPointClouds([
      treePointCloud.current,
      showDetails && environmentPointCloud.current,
      selectedScan && historicalPointCloud.current
    ].filter(it => !!it) as any[]);

    viewRef.current.addRulers(
      rulersRef.current
        .filter(it => rulers.includes(it.propertyName))
        .map(it => ((rulers.includes(DisplayableTreeProperty.CanopyWidth) || rulers.length > 1) ? it.displayedInOriginalPlace() : it.displayedInTheMiddle()))
    );

    viewRef.current.addGrid();
    viewRef.current.setCanvasSize();

    return () => {
      viewRef.current.removeEventListeners();
    };
  }, [
    isPointCloudLoading,
    isViewInitialized,
    tree,
    showDetails,
    selectedScan,
    t,
    selectedTab,
    rulers,
    selectedAccordion
  ]);

  return (
    <TileContainer>
      <div className={styles.resizableContainer}>
        <ButtonsOverlay>
          <ButtonsOverlay.TopRight>
            {props.showAddButton && <TileButton label={t('tooltips.splitScreen')} onClick={() => props.openSecondaryTile()} icon={<SplitScreen/>}/>}
            {props.showCloseButton && <TileButton label={t('tooltips.close')} onClick={() => props.setView(TreeDisplays.NULL)} icon={<Close/>}/>}
          </ButtonsOverlay.TopRight>
          <ButtonsOverlay.BottomRight>
            <div className="flex gap-4">
              <div className="flex gap-0.5">
                <ViewSelector
                  setView={props.setView}
                  view={props.view}
                  showPointcloud={true}
                  setPointcloudVisibility={() => {}}
                  hideCanopy={hideCanopy}
                  setHideCanopy={setHideCanopy}
                  showEnvironment={showDetails}
                  setEnvironmentVisibility={setDetailsVisibility}
                />
              </div>
              <div className="flex gap-0.5">
                <TileButton label={t('tooltips.resetView')} onClick={resetView} icon={<Reset/>}/>
                <div className="flex">
                  <TileButton label={t('tooltips.zoomIn')} onClick={() => viewRef?.current?.zoomIn()} icon={<ZoomIn/>}/>
                  <TileButton label={t('tooltips.zoomOut')} onClick={() => viewRef?.current?.zoomOut()} icon={<ZoomOut/>}/>
                </div>
              </div>
            </div>
          </ButtonsOverlay.BottomRight>
          <ButtonsOverlay.BottomLeft>
            {tree.recordingDate && <DateSelector date={tree.recordingDate}/>}
          </ButtonsOverlay.BottomLeft>
        </ButtonsOverlay>
        <TileContent>
          <div className={styles.container}>
            <canvas ref={canvasRef} className={styles.canvas}/>
            {isPointCloudLoading && (
              <div className={styles.spinner}>
                <Spinner/>
              </div>
            )}
            {riskOverlayIsVisible &&
                <RiskOverlay
                  tree={props.tree}
                  viewRef={viewRef}
                  treePointCloud={treePointCloud}
                  environmentPointCloud={environmentPointCloud}
                  isViewInitialized={isViewInitialized}
                  showDetails={showDetails}
                  isPointCloudLoading={isPointCloudLoading}
                />
            }
          </div>
        </TileContent>
      </div>
    </TileContainer>
  );
}

interface TwinViewTileProps {
  tree: DetailedTree,
  view: TreeDisplays,
  setView: (view: TreeDisplays) => void,
  openSecondaryTile: () => void,
  showAddButton: boolean,
  showCloseButton: boolean
}
