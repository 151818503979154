import { Organization } from '../../../organization/Organization';
import { loadTilesJson } from './loadTilesJson';
import { loadImage } from './loadImage';
import CapturePoint from '../../../capture-point/CapturePoint';

export default async function (organization: Organization, capturePoint: CapturePoint | null, abortController: AbortController) {
    const relativeUrl = capturePoint?.imageProperties?.[0]?.path;
    if (!relativeUrl) return;
    const url = organization.getCDNUrlOfExportedDataFromRelativePath(relativeUrl);
    const layers = await loadTilesJson(url, abortController.signal);
    const singleTileLayer = layers.find((item: any) => item.items?.length === 1);
    const lowResolutionImageUrl = url.replace('panoramas', 'panorama-tiles').replace(/\.(jpeg|jpg)/, `/${singleTileLayer?.items?.[0]?.path}`);

    await loadImage(lowResolutionImageUrl, abortController.signal);
}