import { ReactElement, useContext } from 'react';
import TwinViewTile from './twin-view/TwinViewTile';
import ImagesViewTile from './images-view/ImagesViewTile';
import StreetViewTile from './street-view/StreetViewTile';
import { TreeDisplays } from '../constants';
import DetailedTree from '../../../tree/DetailedTree';
import DependencyInjectionContext from '../../../DependencyInjectionContext';

export default function TreeDisplayTile(props: TreeDisplayTileProps) {
  const { primaryTile, secondaryTile, isExpanded } = props;
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const showAddButton = primaryTile === TreeDisplays.NULL || secondaryTile === TreeDisplays.NULL;

  const openSecondaryTile = () => {
    urlContext.setDetailsSecondary(TreeDisplays.IMAGES);
    urlContext.setHistoryTrackingOpen(false);
  };

  const resizePrimaryTile = () => {
    if (secondaryTile !== TreeDisplays.NULL) {
      urlContext.setDetailsSecondary(TreeDisplays.NULL);
    }

    const isDetailsExpanded = urlContext.getDetailsIsExpanded();
    urlContext.setDetailsIsExpanded(!isDetailsExpanded);
    if (isDetailsExpanded) urlContext.setHistoryTrackingOpen(false);
  };

  const resizeSecondaryTile = () => {
    const isDetailsExpanded = urlContext.getDetailsIsExpanded();
    urlContext.setDetailsIsExpanded(!isDetailsExpanded);
    if (isDetailsExpanded) urlContext.setHistoryTrackingOpen(false);
    if (primaryTile !== TreeDisplays.NULL) {
      urlContext.setDetailsPrimary(secondaryTile);
      urlContext.setDetailsSecondary(TreeDisplays.NULL);
    }
  };

  const primaryTiles = new Map<TreeDisplays, ReactElement>([
    [TreeDisplays.STREET_VIEW, (
      <StreetViewTile
        view={primaryTile}
        setView={urlContext.setDetailsPrimary.bind(urlContext)}
        tree={props.tree}
        openSecondaryTile={openSecondaryTile}
        showAddButton={showAddButton}
        showCloseButton={false}
        isExpanded={isExpanded}
        resizeTile={resizePrimaryTile} />
    )],
    [TreeDisplays.IMAGES, (
      <ImagesViewTile
        view={primaryTile}
        setView={urlContext.setDetailsPrimary.bind(urlContext)}
        tree={props.tree}
        openSecondaryTile={openSecondaryTile}
        showAddButton={showAddButton}
        showCloseButton={false}
        isExpanded={isExpanded}
        resizeTile={resizePrimaryTile} />
    )],
    [TreeDisplays.TWIN_VIEW, (
      <TwinViewTile
        view={primaryTile}
        setView={urlContext.setDetailsPrimary.bind(urlContext)}
        tree={props.tree}
        openSecondaryTile={openSecondaryTile}
        showAddButton={showAddButton}
        showCloseButton={false}
        isExpanded={isExpanded}
        resizeTile={resizePrimaryTile} />
    )]
  ]);

  const secondaryTiles = new Map<TreeDisplays, ReactElement>([
    [TreeDisplays.STREET_VIEW, (
      <StreetViewTile
        view={secondaryTile}
        setView={urlContext.setDetailsSecondary.bind(urlContext)}
        tree={props.tree}
        openSecondaryTile={() => {}}
        showAddButton={showAddButton}
        showCloseButton={true}
        isExpanded={isExpanded}
        resizeTile={resizeSecondaryTile} />
    )],
    [TreeDisplays.IMAGES, (
      <ImagesViewTile
        view={secondaryTile}
        setView={urlContext.setDetailsSecondary.bind(urlContext)}
        tree={props.tree}
        openSecondaryTile={() => {}}
        showAddButton={showAddButton}
        showCloseButton={true}
        isExpanded={isExpanded}
        resizeTile={resizeSecondaryTile} />
    )],
    [TreeDisplays.TWIN_VIEW, (
      <TwinViewTile
        view={secondaryTile}
        setView={urlContext.setDetailsSecondary.bind(urlContext)}
        tree={props.tree}
        openSecondaryTile={() => {}}
        showAddButton={showAddButton}
        showCloseButton={true}
        isExpanded={isExpanded}
        resizeTile={resizeSecondaryTile} />
    )]
  ]);

  return <>
    {primaryTiles.get(primaryTile)}
    {secondaryTile !== TreeDisplays.NULL && secondaryTiles.get(secondaryTile)}
  </>;
}

interface TreeDisplayTileProps {
  tree: DetailedTree,
  primaryTile: TreeDisplays,
  secondaryTile: TreeDisplays,
  isExpanded: boolean
}
