import React, { useContext, useMemo } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import LazyTreeList from '../LazyTreeList';
import { useCurrentAccount } from '../../../account/useAccounts';
import ManagedAreaSelector from './ManagedAreaSelector';
import { IconButton, OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import ColumnSelector from './ColumnSelector';
import { downloadBlob } from '../../Explore/table-view/components/FileDownloadButton';
import { Tree } from '../../../tree/Tree';
import proj4 from 'proj4';
import { Property } from '../../Explore/workspace/TreeTable';
import { Download, Filter, ReportData } from '@carbon/icons-react';

type ExtendedProperty = Property | 'x' | 'y';

export default function TableDashboard(props: TableDashboardProps) {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const { organization } = account;
  const missingAreaSelection = useMemo(() => (!urlContext.getReverseMASelection() && urlContext.getManagedAreaIds().length === 0),
    [JSON.stringify(urlContext.getManagedAreaIds()), JSON.stringify(urlContext.getReverseMASelection())]);

  const generateReport = () => {
    urlContext.setReportOpen(true);
  };

  const handleShpExport = async e => {
    e.stopPropagation();
    const response = await treeService.getShpExport(organization.id, urlContext.getReverseMASelection(), urlContext.getManagedAreaIds(), urlContext.getTreeFilterIds(), urlContext.getVisibleTableProperties() || []);
    const contentType = response.headers['content-type'];
    const fileName = response.headers['content-disposition'].match(/filename="([^"]*)"/)?.[1] || 'trees';

    downloadBlob(response.data, contentType, fileName);
  };

  const handleDownload = e => {
    e.stopPropagation();

    props.treeList?.getAll().then(trees => {
      const header = [...props.properties];
      header.splice(3, 0, 'x', 'y');
      const alternativeExportEPSGConvertParams = account.organization?.metaData?.alternativeExportEPSGConvertParams;
      const csv = [
        header.map(it => {
          let title = t(`tree.${it}`);
          if (it === 'safetyFactors') {
            title = title.concat(t('treeList.windSpeedValue', {
              value: props.windSpeed,
              unit: Tree.getWindSpeedUnit(account.organization),
              interpolation: { escapeValue: false }
            }));
          }
          if (it === 'x' || it === 'y') title = it;
          return title;
        }),
        ...trees.map(tree => header.map(it => {
          if (tree.localizedLocation && alternativeExportEPSGConvertParams?.from && alternativeExportEPSGConvertParams?.to){
            const x = tree.localizedLocation ? tree.localizedLocation.coordinates[0] : -1;
            const y = tree.localizedLocation ? tree.localizedLocation.coordinates[1] : -1;
            const newCoordinates = proj4(alternativeExportEPSGConvertParams?.from, alternativeExportEPSGConvertParams?.to, [x, y]);
            if (it === 'x' as Property) {
              return tree.localizedLocation ? newCoordinates[0] : -1;
            }
            if (it === 'y' as Property) {
              return tree.localizedLocation ? newCoordinates[1] : -1;
            }
          }
          if (it === 'safetyFactors') {
            return tree.getSafetyFactor(props.windSpeed)?.toFixed(3);
          }
          if (it === 'managedAreaId') {
            return tree.managedAreaName;
          }
          if (it === 'x' as Property) {
            return tree.localizedLocation ? tree.localizedLocation.coordinates[0] : -1;
          }
          if (it === 'y' as Property) {
            return tree.localizedLocation ? tree.localizedLocation.coordinates[1] : -1;
          }
          if (tree[it] instanceof Array) {
            return tree[it].map(valueItem => t(Tree.getTKeyForProperty(it, valueItem.name))).join(', ');
          }
          if (typeof tree[it] === 'number') return tree[it].toFixed(3);
          return t(Tree.getTKeyForProperty(it, tree[it]));
        }))
      ]
        .map(row => row.map(it => JSON.stringify(it || '')).join(','))
        .join('\r\n');

      const filename = trees.length === 1
        ? `${account.organization.name}_${trees[0].externalId}_export.csv`
        : `${account.organization.name}_export.csv`;

      downloadBlob(csv, 'data:text/csv;charset=utf-8', filename);
    });
  };

  return (
    <div className={`flex justify-between m-8 mt-2 gap-2 ${urlContext.isFilterPanelOpen() ? 'flex-col' : ''}`} data-testid="table-dashboard">
      <div className='flex gap-0.5'>
        <ManagedAreaSelector />
        <ColumnSelector disabled={missingAreaSelection} />
        <IconButton
          label={t('taskManager.filters')}
          kind="ghost"
          style={urlContext.isFilterPanelOpen() ? { backgroundColor: 'var(--cds-button-secondary)' } : {}}
          onClick={() => {
            urlContext.setFilterPanelOpen(!urlContext.isFilterPanelOpen());
          }}
          disabled={missingAreaSelection}
          className="border-0 border-r border-[var(--cds-border-strong)]"
        >
          <Filter
            style={urlContext.isFilterPanelOpen() ? { fill: 'white' } : {}}
          />
        </IconButton>
        <OverflowMenu
          className="p-6"
          menuOptionsClass="w-fit"
          renderIcon={() => <Download />}
          disabled={missingAreaSelection}
          iconDescription={t('treeList.download')}
        >
          <OverflowMenuItem
            itemText={t('treeList.download')}
            onClick={handleDownload}
          />
          <OverflowMenuItem
            itemText={t('treeList.viewDownloadDropdown.downloadAsShp')}
            onClick={handleShpExport}
          />
        </OverflowMenu>
        <IconButton
          label={t('reporting.generateReport')}
          kind="ghost"
          onClick={generateReport}
          disabled={missingAreaSelection}
        >
          <ReportData />
        </IconButton>
      </div>
    </div>
  );
}

interface TableDashboardProps {
  treeList: LazyTreeList | null,
  windSpeed: number,
  properties: ExtendedProperty[]
}
