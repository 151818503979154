import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './Inventory.module.scss';
import { useCurrentAccount } from '../../account/useAccounts';
import MapViewer from '../Explore/map-view/MapViewer';
import { MapStyle } from '../Explore/components/MapStyleSelector';
import MapToolbar from '../Explore/components/MapToolbar';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import { DisplayableTreeProperty, TreeDisplayConfiguration } from '../../tree/Tree';
import useTree from '../../tree/useTree';
import { PanoramicViewer } from '../Explore/panoramic-view/PanoramicViewer';
import { PanelLayout } from './PanelLayout';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import ReportDialog from '../Explore/reports/ReportDialog';
import TreeInfo from '../Explore/components/TreeInfo';
import { usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import mapboxgl from 'mapbox-gl';
import { useTracking } from '../../analytics/useTracking';
import { Flippers } from '../../switches/Flippers';
import BallGridLoader from '../../components/UI/BallGridLoader/BallGridLoader';
import Workspace from '../Explore/workspace/Workspace';
import useManagedAreas from '../../managed-area/useManagedAreaList';
import PageLayout from '../../components/UI/Page/PageLayout';
import SearchBar from '../../components/Navbar/SearchBar/SearchBar';
import greeHillLogo from '../../components/UI/Icon/svg/greehill-logo.svg';
import PropertyLegend from '../../components/Navbar/PropertyLegend';
import { SigmaBoundary } from '../Explore/table-view/advanced-filter/AdvancedFilter';
import { useCapturePoints } from '../../capture-point/useCapturePoints';
import loadLowResolutionImageOfCapturePoint
  from '../../components/Panorama/FullPanoramaSphere/loadLowResolutionImageOfCapturePoint';
import CarbonInsights from '../CarbonInsights/CarbonInsights';

export default function Inventory() {
  const navigate = useNavigate();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const panelLayout = useRef(new PanelLayout(urlContext.getTreeTableOpen()));
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const treeId = urlContext.getTreeId() ?? '';
  const urlContextFingerPrint = urlContext.serialize();
  const { treeFilterService } = useContext(DependencyInjectionContext);
  const { tree, isTreeLoading } = useTree(organizationId, treeId);
  const { bestCapturePoint } = useCapturePoints(organizationId, tree);
  const account = useCurrentAccount();
  const propertyConfigs = usePropertyConfigurations();
  const { events, track } = useTracking();
  const { managedAreaList } = useManagedAreas(organizationId);
  const [searchParams] = useSearchParams();

  // useStates

  const [treeFilters, setTreeFilters] = useState<TreeFilter[] | null>(null);
  const [, setTableDragDistance] = useState<number | null>(null);
  const [hideMarkers, setHideMarkers] = useState(false);
  const [hideTreePointCloud, setHideTreePointCloud] = useState(false);
  const [useLineMeasurement, setUseLineMeasurement] = useState(false);
  const [mapResizeTriggerKey, triggerMapSizeChange] = useState('');
  const [mapResetTriggerKey, triggerMapReset] = useState<{ key?: number, on?: 'tree' | 'managedArea' | 'organization' }>({});
  const [isLoading, setLoadingState] = useState(false);
  const [selectedTreePropertyRangeIndex, setSelectedTreePropertyRangeIndex] = useState(-1);
  const [selectedPropertyCohortBoundary, setSelectedPropertyCohortBoundary] = useState<SigmaBoundary | null>(null);

  // Variables

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const mapStyle = getCurrentMapStyle(urlContext.getStyle());
  const isMapStylePanoramic = mapStyle === MapStyle.Panoramic;

  const managedAreaIdsInURL = urlContext.getManagedAreaIds();
  const selectedManagedAreas = useMemo(() => {
    const ManagedAreasInURL = urlContext.getManagedAreaIds();
    return (managedAreaList ?? []).filter(it => urlContext.getReverseMASelection() ? !ManagedAreasInURL.includes(it.id) : ManagedAreasInURL.includes(it.id));
  }, [JSON.stringify(urlContext.getReverseMASelection()), managedAreaList, JSON.stringify(urlContext.getManagedAreaIds())]);

  const selectedManagedAreaIds = useMemo(() => selectedManagedAreas.map(it => it.id), [selectedManagedAreas]);

  const selectedTreeFilters = useMemo(() => (treeFilters ?? []).filter(it => urlContext.isTreeFilterSelected(it)), [treeFilters, urlContextFingerPrint]);

  const treeDisplayConfiguration: TreeDisplayConfiguration = {
    property: selectedTreeProperty,
    managedAreaIds: urlContext.getManagedAreaIds(),
    isManagedAreaSelectionReversed: urlContext.getReverseMASelection(),
    filters: selectedTreeFilters,
    windSpeed: urlContext.getWindSpeed() || account.getDefaultWindSpeed()
  };

  const selectedTreeFilterProperties: DisplayableTreeProperty[] = useMemo(() => {
    const treeProperties = urlContext.getVisibleTableProperties() || Array.from(new Set(selectedTreeFilters
      .flatMap(it => it.getPropertyNames() as DisplayableTreeProperty[])
      .filter(it => !['species', 'externalId'].includes(it))));

    if (selectedTreeProperty && !treeProperties.includes(selectedTreeProperty)) {
      treeProperties.unshift(selectedTreeProperty);
    }
    return treeProperties;
  }, [selectedTreeFilters, selectedTreeProperty]);

  // useEffects
  useEffect(() => {
    const abortController = new AbortController();
    loadLowResolutionImageOfCapturePoint(account.organization, bestCapturePoint, abortController).then();
    return () => abortController.abort();
  }, [bestCapturePoint]);

  useEffect(() => {
    localStorage.setItem('inventorySearchParams', searchParams.toString());
  }, [searchParams]);

  useEffect(() => {
    const searchParams = window.location.href.split('?')[1];
    if (!account.organization.isEnabled(Flippers.workspace)) {
      navigate(`/organizations/${organizationId}/map${searchParams ? '?' + searchParams : ''}`);
    }
  }, [account.organization, organizationId, navigate]);

  useEffect(() => {
    mapboxgl.prewarm();
    return () => {
      mapboxgl.clearPrewarmedResources();
    };
  }, []);

  useEffect(() => {
    treeFilterService.list(organizationId).then(setTreeFilters);
  }, [organizationId]);

  useEffect(() => {
    if (tree === null) return;
    urlContext.setPositionFromTree(tree);
  }, [tree]);

  useEffect(() => {
    const title = account.organization.name + ' - greehill';
    document.title = title;
    if (tree) document.title = tree?.externalId + ' - ' + title;
  }, [tree, account]);
  const [tableResizeTrigger, setTableResizeTrigger] = useState<number>(0);

  useEffect(() => {
    const gracefulMapResizerTimer = setInterval(
      () => requestAnimationFrame(() => {
        triggerMapSizeChange(Math.random().toString());
      }),
      2
    );
    //Resizing needs more time to occur than 120ms in cleanupTimer
    const cleanupTimer = setTimeout(() => clearInterval(gracefulMapResizerTimer), 250);
    return () => {
      clearInterval(gracefulMapResizerTimer);
      clearTimeout(cleanupTimer);
      triggerMapSizeChange(Math.random().toString());
    };
  }, [JSON.stringify(urlContext.getTreeTableOpen()), tableResizeTrigger]);

  // Functions

  const resetView = () => {
    if (urlContext.getTreeId()) {
      triggerMapReset({ key: Math.random(), on: 'tree' });
      return;
    }

    if (isMapStylePanoramic) return;

    if (managedAreaList && managedAreaList?.some(it => urlContext.isManagedAreaSelected(it))) {
      triggerMapReset({ key: Math.random(), on: 'managedArea' });
      return;
    }

    triggerMapReset({ key: Math.random(), on: 'organization' });
  };

  const handleTableTreeSelect = id => {
    track(events.TREE_SELECT_FROM_TABLE, { treeId: id });
    urlContext.setTreeId(id);
    urlContext.setCapturePointId(null);
    urlContext.setCameraRotation(null);
  };

  if (account.organization.isEnabled(Flippers.carbonRedesign)) return <CarbonInsights />;

  return (
    <PageLayout>
      <PageLayout.LeftNavExtensions>
        <img
          src={greeHillLogo}
          alt="greeHill" />
      </PageLayout.LeftNavExtensions>
      <PageLayout.CenterNavExtensions>
        {selectedTreeProperty &&
          <div
            className={styles.propertyLegendContainer}
            style={panelLayout.current.getPropertyLegendContainerStyle(Boolean(treeId))}
            data-testid='property-legend'
          >
            <PropertyLegend
              hasTableData={selectedManagedAreaIds.length !== 0 || selectedTreeFilters.length !== 0}
              selectedProperty={selectedTreeProperty}
              selectedTreePropertyRangeIndex={selectedTreePropertyRangeIndex}
              setSelectedTreePropertyRangeIndex={setSelectedTreePropertyRangeIndex}
              selectedPropertyCohortBoundary={selectedPropertyCohortBoundary}
              setSelectedPropertyCohortBoundary={setSelectedPropertyCohortBoundary}
              advancedFilterConfiguration={urlContext.getAdvancedFilterConfiguration()}
            />
          </div>
        }

        <SearchBar
          managedAreas={managedAreaList}
          organization={account.organization}
        />
      </PageLayout.CenterNavExtensions>
      <PageLayout.Content>
        <div className={styles.container}>
          {isLoading && (
            <div className={styles.mapLoader}>
              <BallGridLoader />
            </div>
          )}
          {treeId !== '' && (
            <div
              className={styles.treeInfoContainer}
              style={panelLayout.current.getTreeInfoStyle()}
              data-testid={'tree-info-container'}
            >
              <TreeInfo
                tree={tree}
                isTreeLoading={isTreeLoading}
                isOpen={!panelLayout.current.isTableVisible()} />
            </div>
          )
          }

          <div className={styles.mapAndControlsContainer}>
            <div className={styles.mapControls} style={panelLayout.current.getControlsContainerStyle()}>
              <MapToolbar
                style={mapStyle}
                onResetView={resetView}
                onTreeMarkerSwitch={() => setHideMarkers(prev => !prev)}
                onTreePointCloudSwitch={() => setHideTreePointCloud(prev => !prev)}
                treeMarkersEnabled={hideMarkers}
                treePointCloudEnabled={hideTreePointCloud}
                onLineMeasurementSwitch={() => setUseLineMeasurement(prev => !prev)}
                lineMeasurementEnabled={useLineMeasurement}
                lineMeasurementSwitchDisabled={!urlContext.getTreeId() || !isMapStylePanoramic}
                resetViewSwitchDisabled={!urlContext.getTreeId() && isMapStylePanoramic}
              />
            </div>

            <div className={styles.treeListTableContainer} style={panelLayout.current.getTableContainerStyle()}>
              <Workspace
                setTableDragDistance={setTableDragDistance}
                open={urlContext.getTreeTableOpen()}
                filters={selectedTreeFilters}
                properties={selectedTreeFilterProperties}
                onSelect={handleTableTreeSelect}
                panelLayoutRef={panelLayout.current}
                selectedTreeId={urlContext.getTreeId()}
                setTableResizeTrigger={setTableResizeTrigger}
                windSpeed={urlContext.getWindSpeed() || account.getDefaultWindSpeed()}
                areaFilterIsSelected={selectedManagedAreaIds.length > 0}
              />
            </div>

            {!propertyConfigs.isLoading &&
              <div className={styles.mapContainer} style={panelLayout.current.getMapContainerStyle()}>
                {
                  !isMapStylePanoramic &&
                  <MapViewer
                    tableShouldSnapToTop={panelLayout.current.tableShouldSnapToTop()}
                    resizeTriggerKey={mapResizeTriggerKey}
                    mapResetTriggerKey={mapResetTriggerKey}
                    organization={account.organization}
                    selectedManagedAreas={selectedManagedAreas}
                    managedAreaIdsInURL={managedAreaIdsInURL}
                    treeDisplayConfiguration={treeDisplayConfiguration}
                    selectedPropertyConfig={propertyConfigs.data!.find(it => it.property === selectedTreeProperty) ?? null}
                    selectedTreePropertyRangeIndex={-1}
                    selectedPropertyCohortBoundary={null}
                    style={mapStyle}
                    selectedTreeId={treeId}
                    treeClusteringPieChartProperty={selectedTreeProperty}
                    showIsoMap={urlContext.isIsoMapEnabled()}
                    hideMarkers={hideMarkers}
                    onLoading={setLoadingState}
                  />
                }
                {
                  isMapStylePanoramic &&
                  <PanoramicViewer
                    isLineMeasureEnabled={useLineMeasurement}
                    tree={tree}
                    hideLabels={!urlContext.areTreeMarkersVisible()}
                    hideMarkers={hideMarkers}
                    hideTreePointCloud={hideTreePointCloud}
                    viewResetTriggerKey={mapResetTriggerKey}
                    selectedPropertyConfig={propertyConfigs.data!.find(it => it.property === selectedTreeProperty) ?? null}
                    selectedTreePropertyRangeIndex={-1}
                    treeDisplayConfiguration={treeDisplayConfiguration}
                  />
                }
              </div>}
          </div>
        </div>
        {urlContext.getReportOpen() &&
          <ReportDialog
            onHide={() => urlContext.setReportOpen(false)}
            managedAreas={selectedManagedAreas}
            filters={selectedTreeFilters}
          />}
      </PageLayout.Content>
    </PageLayout>
  );
}

function getCurrentMapStyle(mapStyleFromContext: MapStyle) {
  return Object.values(MapStyle).includes(mapStyleFromContext) ? mapStyleFromContext : MapStyle.Default;
}
