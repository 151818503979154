import styles from './TableDashboard.module.scss';
import { useTranslation } from 'react-i18next';
import React, { useContext, useMemo } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import { Filter, NavArrowDown, TableRows, ViewGrid } from 'iconoir-react';
import LazyTreeList from '../../LazyTreeList';
import ManagedAreaSelector from '../../components/ManagedAreaSelector';
import FilterSelector from '../../components/FilterSelector';
import { TreeTableVersion } from '../TreeTable';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { Flippers } from '../../../../switches/Flippers';
import TableColumnSelector from '../../../TaskManager/create/TableColumnSelector';

export default function TableDashboard(props: TableDashboardProps) {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const missingAreaSelection = useMemo(() => (!urlContext.getReverseMASelection() && urlContext.getManagedAreaIds().length === 0),
    [JSON.stringify(urlContext.getManagedAreaIds()), JSON.stringify(urlContext.getReverseMASelection())]);

  const toggleManagedAreaSelection = (id: string) => {
    const managedAreaIds = urlContext.getManagedAreaIds();
    if (urlContext.getManagedAreaIds().includes(id)) {
      urlContext.setManagedAreaIds(managedAreaIds.filter(it => it !== id));
    } else {
      urlContext.setManagedAreaIds([...managedAreaIds, id]);
    }
  };

  const toggleReverseMASelection = () => {
    urlContext.setReverseMASelection(urlContext.getReverseMASelection() ? urlContext.getManagedAreaIds().length !== 0 : true);
    urlContext.setManagedAreaIds([]);
  };

  return (
    <div className={styles.listDashboardContainer} data-testid="table-dashboard">
      <div className={styles.functionButtonsContainer}>
        <div className={styles.areaSection}>
          <div className={styles.dropdownContainer}>
            <div>
              {props.treeTableVersion === TreeTableVersion.INSIGHTS &&
                <div className="twp flex items-center gap-4">
                  <FunctionButton
                    variant="rounded"
                    icon={<ViewGrid />}
                    disabled={true}
                  >
                    <span className="flex items-center">{t('taskManager.mainView')} <NavArrowDown /></span>
                  </FunctionButton>
                  <ManagedAreaSelector
                    selectedManagedAreaIds={urlContext.getManagedAreaIds()}
                    setSelectedManagedAreaIds={(ids: string[]) => urlContext.setManagedAreaIds(ids)}
                    toggleSelection={toggleManagedAreaSelection}
                    reverseMASelection={urlContext.getReverseMASelection()}
                    toggleReverseMASelection={toggleReverseMASelection}
                  />
                  {
                    organization.isEnabled(Flippers.release24q3) && (
                      <TableColumnSelector
                        key={(urlContext.getVisibleTableProperties() || []).join(',')}
                        properties={urlContext.getVisibleTableProperties() || []}
                        setProperties={properties => urlContext.setVisibleTableProperties(properties)}
                      />
                    )
                  }
                  <div className="border-l border-outer-space-400">
                    <FilterSelector disabled={missingAreaSelection} />
                  </div>
                </div>}
              {props.treeTableVersion === TreeTableVersion.TASK_CREATION &&
                <div className="flex items-center gap-4">
                  {
                    organization.isEnabled(Flippers.release24q3) && (
                      <TableColumnSelector
                        key={(urlContext.getVisibleTableProperties() || []).join(',')}
                        properties={urlContext.getVisibleTableProperties() || []}
                        setProperties={properties => urlContext.setVisibleTableProperties(properties)}
                      />
                    )
                  }
                  <FilterSelector disabled={missingAreaSelection} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.functionButtonsContainer}>
        <div className="twp flex text-greehill-00 bg-outer-space-700 rounded-lg px-3">
          <div className="flex items-center gap-1 pr-2 border-outer-space-800 py-2">
            <Filter />{props.treeList?.getNotFilteredCount() || 0}
          </div>
          <div className="flex items-center gap-1 pl-2 border-l border-outer-space-800 py-2">
            <TableRows />{props.treeList?.getTotal() || 0}
          </div>
        </div>
      </div>
    </div>
  );
}

interface TableDashboardProps {
  treeList: LazyTreeList | null,
  treeTableVersion: TreeTableVersion
}
