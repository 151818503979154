import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Checkbox, FilterableMultiSelect } from '@carbon/react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { ManagedArea } from '../../../managed-area/ManagedArea';

export default function ManagedAreaSelector() {
  const { urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const { t } = useTranslation();
  const { managedAreaList } = useManagedAreas(organization.id);
  const items = managedAreaList;
  const selectedItems = items.filter(it => urlContext.getManagedAreaIds().includes(it.id) !== urlContext.getReverseMASelection());
  const allSelected = urlContext.getReverseMASelection() && urlContext.getManagedAreaIds().length === 0;
  const indeterminate = !allSelected && urlContext.getManagedAreaIds().length > 0;
  let isChangeHandlerCalled = false;

  const filterItems = (items: ManagedArea[], extra: { inputValue: string | null }) => {
    return items.filter(it => it.getName()?.toLowerCase().includes(extra.inputValue!.toLowerCase()));
  };
  const placeholder = () => {
    if (selectedItems.length > 1) return t('areaSelector.areasSelected');
    return selectedItems.length ? t('areaSelector.areaSelected') : t('areaSelector.selectArea');
  };
  const handleChange = (data: { selectedItems: ManagedArea[] }) => {
    urlContext.setTreeId('');
    if (isChangeHandlerCalled) return;
    isChangeHandlerCalled = true;
    if (data.selectedItems.length === 0) {
      urlContext.setReverseMASelection(false);
      urlContext.setManagedAreaIds([]);
      return;
    }

    if (urlContext.getReverseMASelection()) {
      const selectedIds = data.selectedItems.map(it => it.id);
      urlContext.setManagedAreaIds(items.filter(item => !selectedIds.includes(item.id)).map(it => it.id));
    } else {
      urlContext.setManagedAreaIds(data.selectedItems.map(it => it.id));
    }
  };

  const handleSelectAll = () => {
    urlContext.setTreeId('');
    urlContext.setManagedAreaIds([]);
    urlContext.setReverseMASelection(!urlContext.getReverseMASelection());
  };

  return (
    <div className="flex bg-[var(--cds-field)]">
      <div className="flex items-center h-full px-2 border-solid border-b border-0 border-[var(--cds-border-strong)]">
        <Checkbox className="text-[var(--cds-text-secondary)] pt-1 [&>label]:pt-0.5" indeterminate={indeterminate} checked={allSelected} id="select-all-managed-areas" labelText={t('all') || ''} onClick={handleSelectAll} />
      </div>
      <FilterableMultiSelect
        id="managed-area-selector"
        placeholder={placeholder()}
        items={items}
        selectionFeedback="fixed"
        selectedItems={selectedItems}
        itemToString={item => item ? item.getName() : ''}
        filterItems={filterItems}
        onChange={handleChange}
        size="lg"
      />
    </div>
  );
}
