export enum Flippers {
  davey = 'davey',
  dashboardRedesign = 'dashboardRedesign',
  compareToCohort = 'compareToCohort',
  capturePointStepperChevrons = 'capturePointStepperChevrons',
  horizontalPaginationInTable = 'horizontalPaginationInTable',
  newPlatformProperties = 'newPlatformProperties',
  workspace = 'workspace',
  release24q3 = 'release24q3',
  windspeedSlider = 'windspeedSlider',
  carbonRedesign = 'carbonRedesign'
}
