import React, {
  Children,
  createContext,
  createRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import Sidebar from './Sidebar';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { Header, HeaderName, HeaderPanel, Theme } from '@carbon/react';
import greeHillLogo from '../../../UI/Icon/svg/logo.svg';
import useCarbonThemes from '../../Carbon/useCarbonThemes';
import ProfileMenu from './ProfileMenu';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useTranslation } from 'react-i18next';
import { IbmCloudDirectLink_2Dedicated } from '@carbon/icons-react';
import { createPortal } from 'react-dom';

export const PageLayoutContext = createContext<{
  rightPanelRef: RefObject<HTMLDivElement>,
  onClose: () => void,
  setOnClose: (onClose: () => void) => void
    }>({ rightPanelRef: createRef<HTMLDivElement>(), onClose: () => {}, setOnClose: () => {} });

export default function PageLayout(props: PageLayoutProps) {
  const { t } = useTranslation();
  const { theme } = useCarbonThemes();
  const account = useCurrentAccount();
  const { urlContext } = useContext(DependencyInjectionContext);
  const isSideBarOpen = urlContext.getSidebarOpen();
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [onClose, setOnClose] = useState<() => void>(() => {});

  const content = Children.map(props.children, child => ((child as ReactElement)?.type !== CenterNavExtensions ? child : null));
  const centerNavExtensions = Children.map(props.children, child => ((child as ReactElement)?.type === CenterNavExtensions ? child : null));

  useEffect(() => {
    if (!rightPanelRef.current) return;

    const observer = new MutationObserver(() => {
      if ((rightPanelRef.current?.childElementCount ?? 0) > 0) {
        setRightPanelOpen(true);
      } else {
        setRightPanelOpen(false);
      }
    });
    observer.observe(rightPanelRef.current, { childList: true });

    return () => observer.disconnect();
  }, []);

  return (
    <Theme theme={theme} className="normalize-scrollbar">
      <PageLayoutContext.Provider value={{ rightPanelRef, onClose, setOnClose }}>
        <div
          style={{ backgroundColor: 'var(--cds-background)' }}
          className="w-[100vw] h-[100vh] fixed left-0 top-0 -z-10"/>
        <Header className="flex justify-between items-center" aria-label="header">
          <div className="flex items-center gap-[10px]">
            <HeaderName prefix="" href="/" className="!p-[10px]">
              <img src={greeHillLogo} alt="greeHill"/>
            </HeaderName>
            <h1 className="text-sm font-semibold text-[var(--cds-text-primary)]">
              {t(props.pageTitle)}
            </h1>
            <div className={'mx-8'}>
              {!!props.externalSystemLink &&
                <a href={props?.externalSystemLink?.url?.toString()} target={'_blank'} className={'hover:text-[var(--cds-link-primary)] cursor-pointer'} title={`Open in ${props?.externalSystemLink?.name}`} rel="noreferrer">
                  <IbmCloudDirectLink_2Dedicated/>
                </a>}
            </div>
          </div>

          {centerNavExtensions}

          <ProfileMenu currentAccount={account}/>
          <HeaderPanel
            className={(rightPanelOpen ? 'w-[374px]' : '') + ' z-10'}
            expanded={rightPanelOpen}
          >
            <div ref={rightPanelRef}/>
          </HeaderPanel>
        </Header>
        {account.isEmployeeOrAbove() && <Sidebar/>}
        <div className={(isSideBarOpen ? 'pl-[255px]' : 'pl-12') + ' pt-12 h-[100vh] w-[100vw]'}>
          {content}
        </div>
      </PageLayoutContext.Provider>
    </Theme>
  );
}

function CenterNavExtensions({ children }) {
  return children;
}

function RightPanel({ children, onClose }: PropsWithChildren<{ onClose: () => void }>) {
  const { rightPanelRef, setOnClose } = useContext(PageLayoutContext);
  if (!rightPanelRef.current) return null;
  setOnClose(() => onClose);
  return createPortal(<div className="relative pb-16 h-[calc(100vh-48px)]">{children}</div>, rightPanelRef.current);
}

function RightPanelContent({ children }) {
  return <div className="py-2 h-full overflow-y-auto" style={{ scrollbarGutter: 'stable' }}>{children}</div>;
}

function RightPanelActions({ children }) {
  return <div className="w-full absolute bottom-0 left-0">{children}</div>;
}

PageLayout.CenterNavExtensions = CenterNavExtensions;
PageLayout.RightPanel = RightPanel;
PageLayout.RightPanelContent = RightPanelContent;
PageLayout.RightPanelActions = RightPanelActions;

function Content({ children }) {
  return children;
}

PageLayout.Content = Content;

interface PageLayoutProps {
 children: ReactNode | undefined,
 pageTitle: string,
 externalSystemLink?: {
  url?: string | null | undefined,
  name?:string | null | undefined
 } | null | undefined
}
