const cache = new Map<string, { layer: number, items: { path: string }[] }[]>();

export async function loadTilesJson(url: string, signal: AbortSignal): Promise<{ layer: number, items: { path: string }[] }[]> {
  if (cache.has(url)) {
    return cache.get(url)!;
  }

  try {
    const tileUrl = url
      .replace('panoramas', 'panorama-tiles')
      .replace(/\.(jpeg|jpg)/, '/tiles.json');

    const { layers } = await fetch(tileUrl, { signal, credentials: 'include' }).then(res => res.json());
    cache.set(url, layers);
    return layers;
  } catch (err) {
    // aborted fetch
  }
  return [];
}