import React, { ReactNode } from 'react';
import { NavArrowDown, NavArrowLeft } from 'iconoir-react';

export function DetailsGroup({ children, className }: { children: ReactNode, className?: string }) {
  return (
    <ul className={className}>
      {children}
    </ul>
  );
}

export function ControlledExclusiveDetails({
  children,
  summary,
  className,
  summaryClassName,
  isOpen,
  toggleOpen,
  summaryControl
}: {
  children: ReactNode,
  summary: string | ReactNode,
  className?: string,
  summaryClassName?: string,
  isOpen: boolean,
  toggleOpen: () => void,
  summaryControl?: React.ReactNode | React.ReactNode[]
}) {
  return (
    <li className={`${isOpen ? 'outline outline-1 outline-greehill-400' : ''}`}>
      <details className={className} open={isOpen}>
        <summary
          onClick={toggleOpen}
          className={`${summaryClassName} w-full flex items-center justify-between cursor-pointer`}
        >
          <div className={`flex items-center gap-2 text-[13px] ${isOpen ? 'text-greehill-400' : ''}`}>
            {summaryControl}
            {summary}
          </div>
          {isOpen
            ? <NavArrowDown className="size-[20px] text-greehill-400"/>
            : <NavArrowLeft className="size-[20px]"/>}
        </summary>
        {children}
      </details>
    </li>
  );
}
