import ManagedAreaSelector from '../../Explore/components/ManagedAreaSelector';
import { useContext, useEffect, useState } from 'react';
import { useCurrentAccount } from '../../../account/useAccounts';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { JobColumnName } from './JobColumnName';
import JobColumn from './JobColumn';
import TaskTemplateList from './TaskTemplateList';
import SlideInPanel from './SlideInPanel';
import EditTaskTemplate from './EditTaskTemplate';
import { useTaskTemplatesWithTrees } from './useTaskTemplatesWithTrees';
import { useTranslation } from 'react-i18next';
import TaskTemplate from './TaskTemplate';
import FilterConfig from './FilterConfig';
import { useQueryClient } from 'react-query';
import { Filter, NavArrowDown, Okrs, ViewGrid } from 'iconoir-react';
import { dismissibleErrorToast } from '../../../components/UI/Toast/DismissibleToast';

enum SlideInPanelContent {
  TaskTemplates = 'taskTemplates',
  EditTaskTemplate = 'editTaskTemplate'
}

export default function CreateTasks() {
  const [slideInPanelContent, setSlideInPanelContent] = useState<null | SlideInPanelContent>(null);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<TaskTemplate | null>(null);

  const { urlContext } = useContext(DependencyInjectionContext);
  const selectedManagedAreaIds = urlContext.getManagedAreaIds();
  const reverseMASelection = urlContext.getReverseMASelection();
  const account = useCurrentAccount();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const {
    categories,
    isLoading,
    isError
  } = useTaskTemplatesWithTrees(account.organization.id);

  useEffect(() => {
    if (isError) dismissibleErrorToast(t('genericError'));
  }, [isError]);

  const toggleSelection = async (id: string) => {
    if (selectedManagedAreaIds.includes(id)) {
      urlContext.setManagedAreaIds([...selectedManagedAreaIds].filter(it => it !== id));
    } else {
      urlContext.setManagedAreaIds([...selectedManagedAreaIds, id]);
    }
    await queryClient.invalidateQueries(`${account.organization.id}-task-templates`);
  };
  const toggleReverseMASelection = () => {
    urlContext.setReverseMASelection(reverseMASelection ? selectedManagedAreaIds.length !== 0 : true);
    urlContext.setManagedAreaIds([]);
  };

  const closeSlideInPanel = () => setSlideInPanelContent(null);

  const saveTaskTemplate = async (templateData: TaskTemplateDto, templateId?: string) => {
    if (templateId) {
      await taskManagerService.updateTaskTemplate(account.organization.id, templateId, templateData);
      setSelectedTaskTemplate(null);
    } else {
      await taskManagerService.createTaskTemplate(account.organization.id, templateData);
    }
    closeSlideInPanel();
    await queryClient.invalidateQueries(`${account.organization.id}-task-templates`);
  };

  const editTaskTemplate = (taskTemplate: TaskTemplate) => {
    setSelectedTaskTemplate(taskTemplate);
    setSlideInPanelContent(SlideInPanelContent.EditTaskTemplate);
  };

  const deleteTaskTemplate = async (taskTemplateId: string) => {
    await taskManagerService.deleteTaskTemplate(account.organization.id, taskTemplateId);
    setSelectedTaskTemplate(null);
    setSlideInPanelContent(SlideInPanelContent.TaskTemplates);
  };

  return (
    <section className="twp w-full">
      <div className="flex justify-between my-4">
        <h2 className="text-2xl font-semibold">{t('taskManager.createTasks')}</h2>
        <FunctionButton
          variant="rounded"
          icon={<Okrs />}
          onClick={() => setSlideInPanelContent(SlideInPanelContent.TaskTemplates)}
        >
          {t('taskManager.taskTemplates')}
        </FunctionButton>
      </div>
      <div className="flex items-center gap-4">
        <FunctionButton
          variant="rounded"
          icon={<ViewGrid />}
          disabled={true}
        >
          <span className="flex items-center">{t('taskManager.mainView')} <NavArrowDown /></span>
        </FunctionButton>
        <ManagedAreaSelector
          selectedManagedAreaIds={selectedManagedAreaIds}
          setSelectedManagedAreaIds={(ids: string[]) => urlContext.setManagedAreaIds(ids)}
          toggleSelection={toggleSelection}
          reverseMASelection={reverseMASelection}
          toggleReverseMASelection={toggleReverseMASelection}
        />
        <FunctionButton
          variant="rounded"
          icon={<Filter />}
          disabled={true}
        >
          <span className="flex items-center">{t('taskManager.filters')} <NavArrowDown /></span>
        </FunctionButton>
      </div>
      <div className="my-4 flex gap-4 text-greehill-00">
        {Object.values(JobColumnName).map(columnName => {
          const currentCategory = categories.find(category => category.name === columnName);
          const taskTemplates = currentCategory?.taskTemplates ?? [];
          const notAssignedCount = currentCategory?.notAssignedCount ?? 0;
          const totalTreeCountByArea = currentCategory?.totalTreeCountByArea ?? 0;
          return (
            <JobColumn
              key={columnName}
              columnName={columnName}
              taskTemplates={taskTemplates}
              templatesLoading={isLoading}
              notAssignedCount={notAssignedCount}
              totalTreeCountByArea={totalTreeCountByArea}
              missingAreaSelection={selectedManagedAreaIds.length === 0 && !reverseMASelection}
            />
          );
        })}
      </div>
      <SlideInPanel isOpen={slideInPanelContent !== null} className="w-full max-w-[920px]">
        {slideInPanelContent === SlideInPanelContent.EditTaskTemplate
          ? <EditTaskTemplate
            closeSlideInPanel={closeSlideInPanel}
            save={saveTaskTemplate}
            taskTemplate={selectedTaskTemplate}
            deleteTaskTemplate={deleteTaskTemplate}
            selectedManagedAreaIds={selectedManagedAreaIds}
            reverseMASelection={reverseMASelection}
            taskTemplatesLength={categories.find(it => it.name === selectedTaskTemplate?.jobColumnName)?.taskTemplates.length ?? 0}
            key={selectedTaskTemplate?.id ?? 'new'}
          />
          : <TaskTemplateList
            taskTemplates={categories.flatMap(it => it.taskTemplates)}
            closeSlideInPanel={closeSlideInPanel}
            goToNewTaskType={() => {
              setSlideInPanelContent(SlideInPanelContent.EditTaskTemplate);
              setSelectedTaskTemplate(null);
            }}
            editTaskTemplate={editTaskTemplate}
          />}
      </SlideInPanel>
    </section>
  );
}

export interface TaskTemplateDto {
  name?: string,
  rank?: number,
  connectedModule?: string,
  jobColumnName?: JobColumnName,
  filterConfig?: FilterConfig
}
