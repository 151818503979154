import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { ManagedArea } from '../../managed-area/ManagedArea';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import { MapStyle } from './components/MapStyleSelector';
import * as lzString from 'lz-string';
import { DisplayTreesOptions } from '../../components/Navbar/DisplayModes';
import { AdvancedFilterConfiguration } from './table-view/advanced-filter/AdvancedFilter';
import { Tab } from '../LegacyDetails/DataPanel/DataTabs/TabSelector';
import { Tab as LegacyTab } from '../LegacyDetails/LegacyDataPanel/DataTabs/TabSelector';
import { EnvironmentAccordion } from '../LegacyDetails/DataPanel/DataTabs/ClearancesTab';
import { ColoringType } from '../../components/Navbar/PropertyLegend';
import FilterConfig, { Operator } from '../TaskManager/create/FilterConfig';
import { TreeDisplays } from '../Details/TreeDisplayTile/TreeDisplays';
import { SortingDirection } from '../CarbonInsights/SortButton';

export enum ContextFields {
  ManagedAreaId = 'a',
  ReverseMASelection = 'rev',
  SelectedTreeProperty = 'e',
  TreeFilterId = 'f',
  VisibleTableProperties = 'h',
  TreeId = 'i',
  Lat = 'lat',
  Lng = 'lng',
  Style = 'j',
  CapturePointId = 'l',
  CameraRotation = 'm',
  Report = 'r',
  TreeTable = 't',
  Sidebar = 's',
  SavedTreeFilterId = 'z',
  WindSpeed = 'w',
  TreeDisplayMode = 'dm',
  IsoMap = 'hm',
  DisplayValues = 'dv',
  AdvancedFiltering = 'g',
  DetailsTreeDisplay = 'dtd',
  DetailsPrimary = 'pri',
  DetailsSecondary = 'sec',
  DetailsIsExpanded = 'exp',
  SelectedDataPanelTab = 'tab',
  SelectedEnvAccordion = 'envacc',
  HistoryTracking = 'ht',
  ColoringType = 'ct',
  SelectedYear = 'sy',
  Zoom = 'zoom',
  FilterConfig = 'fc',
  FilterPanel = 'fp'
}

export const ReverseContextFieldsMap = new Map<string, string>(
  Object.keys(ContextFields).map(key => [ContextFields[key], key])
);

export class UrlContext {
  constructor(
    private readonly setSearchParams: (searchParams: URLSearchParams) => void
  ) {}

  get searchParams() {
    return new URLSearchParams(window.location.search);
  }

  setManagedAreaIds(ids: string[]) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.ManagedAreaId);
    Array.from(new Set(ids)).forEach(id => searchParams.append(ContextFields.ManagedAreaId, id));
    this.update(searchParams);
  }

  appendManagedAreaId(id: string) {
    const searchParams = this.searchParams;
    searchParams.append(ContextFields.ManagedAreaId, id);
    this.update(searchParams);
  }

  removeManagedAreaId(id: string) {
    const managedAreaIds = this.getManagedAreaIds();
    this.setManagedAreaIds(managedAreaIds.filter(it => it !== id));
  }

  getManagedAreaIds(){
    return this.searchParams.getAll(ContextFields.ManagedAreaId);
  }

  isManagedAreaSelected(managedArea: ManagedArea): boolean {
    const isMAInSearchParams = managedArea.isSameByAnyId(this.searchParams.getAll(ContextFields.ManagedAreaId));
    return this.getReverseMASelection() ? !isMAInSearchParams : isMAInSearchParams;
  }

  setReverseMASelection(reverseMASelection: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.ReverseMASelection);
    if (reverseMASelection) searchParams.append(ContextFields.ReverseMASelection, reverseMASelection.toString());
    this.update(searchParams);
  }

  getReverseMASelection() {
    return this.searchParams.get(ContextFields.ReverseMASelection) === 'true';
  }

  getZoom(): number | null {
    return Number(this.searchParams.get(ContextFields.Zoom));
  }

  deleteZoom() {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.Zoom);
    this.update(searchParams);
  }

  getWindSpeed(): number | null {
    if (this.searchParams.get(ContextFields.WindSpeed) !== '') {
      return Number(this.searchParams.get(ContextFields.WindSpeed));
    }
    return null;
  }

  setWindSpeed(windSpeed: number) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.WindSpeed);
    searchParams.append(ContextFields.WindSpeed, windSpeed.toString());
    this.update(searchParams);
  }

  setSelectedTreeProperty(property: DisplayableTreeProperty | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.SelectedTreeProperty);
    if (property) {
      searchParams.append(ContextFields.SelectedTreeProperty, property);
    }
    this.update(searchParams);
  }

  getSelectedTreeProperty(): DisplayableTreeProperty | null {
    return (
      Object.values(DisplayableTreeProperty).find(
        it => this.searchParams.get(ContextFields.SelectedTreeProperty) === it
      ) ?? null
    );
  }

  getTreeId(): string | null {
    return this.searchParams.get(ContextFields.TreeId);
  }

  setTreeId(treeId: string) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.TreeId);
    if (treeId !== '') {
      searchParams.append(ContextFields.TreeId, treeId);
      searchParams.delete(ContextFields.FilterPanel);
    }
    this.update(searchParams);
  }

  setPositionFromTree(tree: Tree) {
    const lat = tree.getWorldCoordinates()[0];
    const lng = tree.getWorldCoordinates()[1];
    this.setPositionFromCoordinates([lng, lat]);
  }

  setPositionFromCoordinates(coordinates: number[]) {
    const searchParams = this.searchParams;

    searchParams.delete(ContextFields.Lng);
    searchParams.append(ContextFields.Lng, coordinates[0].toFixed(4));

    searchParams.delete(ContextFields.Lat);
    searchParams.append(ContextFields.Lat, coordinates[1].toFixed(4));

    this.update(searchParams);
  }

  getLatitude(): number | null {
    return Number(this.searchParams.get(ContextFields.Lat));
  }

  getLongitude(): number | null {
    return Number(this.searchParams.get(ContextFields.Lng));
  }

  isTreeFilterSelected(treeFilter: TreeFilter): boolean {
    return treeFilter.isSameByAnyId(this.searchParams.getAll(ContextFields.TreeFilterId));
  }

  addTreeFilter(treeFilter: TreeFilter) {
    const searchParams = this.searchParams;
    searchParams.append(ContextFields.TreeFilterId, treeFilter.id);
    this.update(searchParams);
  }

  setTreeFilterIds(ids: string[]) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.TreeFilterId);
    ids.forEach(id => searchParams.append(ContextFields.TreeFilterId, id));
    this.update(searchParams);
  }

  setSavedTreeFilterId(id: string) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.SavedTreeFilterId);
    if (id !== '') {
      searchParams.append(ContextFields.SavedTreeFilterId, id);
    }
    this.update(searchParams);
  }

  getSavedTreeFilterId(): string | null {
    return this.searchParams.get(ContextFields.SavedTreeFilterId);
  }

  deleteTreeFilterIds() {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.TreeFilterId);
    this.update(searchParams);
  }

  getTreeFilterIds(){
    return this.searchParams.getAll(ContextFields.TreeFilterId);
  }

  setVisibleTableProperties(properties: DisplayableTreeProperty[]) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.VisibleTableProperties);
    searchParams.append(ContextFields.VisibleTableProperties, lzString.compressToEncodedURIComponent(JSON.stringify(properties)));
    this.update(searchParams);
  }

  getVisibleTableProperties(): DisplayableTreeProperty[] | null {
    const properties = this.searchParams.get(ContextFields.VisibleTableProperties);
    if (!properties) {
      return null;
    }
    return JSON.parse(lzString.decompressFromEncodedURIComponent(properties));
  }

  setStyle(style: MapStyle) {
    if (this.isMap(style)) {
      this.setCameraRotation(null);
    }
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.Style);
    searchParams.append(ContextFields.Style, style);
    this.update(searchParams);
  }

  getStyle(): MapStyle {
    return (this.searchParams.get(ContextFields.Style) ?? MapStyle.Default) as MapStyle;
  }

  setColoringType(coloringType: ColoringType | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.ColoringType);
    if (coloringType) {
      searchParams.append(ContextFields.ColoringType, coloringType);
    }
    this.update(searchParams);
  }

  getColoringType(): ColoringType {
    return this.searchParams.get(ContextFields.ColoringType) as ColoringType;
  }

  serialize(): string {
    return this.searchParams.toString();
  }

  clear() {
    const searchParams = this.searchParams;
    Object.values(ContextFields).forEach(it => searchParams.delete(it));
    this.update(searchParams);
  }

  setCapturePointId(capturePointId: string | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.CapturePointId);
    if (capturePointId) {
      searchParams.append(ContextFields.CapturePointId, capturePointId);
    }
    this.update(searchParams);
  }

  getCapturePointId() {
    return this.searchParams.get(ContextFields.CapturePointId);
  }

  setCameraRotation(eulerAngle: any[] | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.CameraRotation);
    if (eulerAngle) {
      searchParams.append(ContextFields.CameraRotation, JSON.stringify(eulerAngle));
    }
    this.update(searchParams);
  }

  getCameraRotation(): [number, number, number] | null {
    const rotation = this.searchParams.get(ContextFields.CameraRotation);
    if (rotation) {
      return JSON.parse(rotation);
    }

    return null;
  }

  getFilterConfiguration(): FilterConfig {
    const config = this.searchParams.get(ContextFields.FilterConfig);
    if (config) return JSON.parse(lzString.decompressFromEncodedURIComponent(config));
    return { filters: [], filterGroups: [], topLevelOperator: Operator.AND };
  }

  setFilterConfiguration(config: FilterConfig | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.FilterConfig);
    if (config) {
      searchParams.append(ContextFields.FilterConfig, lzString.compressToEncodedURIComponent(JSON.stringify(config)));
    }
    this.update(searchParams);
  }

  getAdvancedFilterConfiguration(): AdvancedFilterConfiguration {
    const config = this.searchParams.get(ContextFields.AdvancedFiltering);
    if (config) return JSON.parse(lzString.decompressFromEncodedURIComponent(config));

    return { includeOnly: [], min: [], max: [], propertyConfigs: [], cohort: [] };
  }

  setAdvancedFilterConfiguration(config: AdvancedFilterConfiguration | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.AdvancedFiltering);
    if (config !== null && [config.min, config.max, config.includeOnly, config.propertyConfigs, config.cohort].some(it => it.length > 0)) {
      searchParams.append(ContextFields.AdvancedFiltering, lzString.compressToEncodedURIComponent(JSON.stringify(config)));
    }
    this.update(searchParams);
  }

  private update(searchParams: URLSearchParams) {
    this.setSearchParams(searchParams);
  }

  private isMap(style: MapStyle) {
    return [MapStyle.Satellite, MapStyle.Default].includes(style);
  }

  setTreeTableOpen(isOpen: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.TreeTable);
    searchParams.append(ContextFields.TreeTable, isOpen.toString());
    this.update(searchParams);
  }

  getTreeTableOpen() {
    return this.searchParams.get(ContextFields.TreeTable) !== 'false';
  }

  setReportOpen(property: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.Report);
    if (property) {
      searchParams.append(ContextFields.Report, property.toString());
    }
    this.update(searchParams);
  }

  getReportOpen() {
    return this.searchParams.get(ContextFields.Report) === 'true';
  }

  setSidebarOpen(isOpen: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.Sidebar);
    if (isOpen) {
      searchParams.append(ContextFields.Sidebar, isOpen.toString());
    }
    this.update(searchParams);
  }

  getSidebarOpen() {
    return this.searchParams.get(ContextFields.Sidebar) === 'true';
  }

  isFilterPanelOpen(): boolean {
    return this.searchParams.get(ContextFields.FilterPanel) === 'true';
  }

  setFilterPanelOpen(isOpen: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.FilterPanel);
    if (isOpen) {
      searchParams.append(ContextFields.FilterPanel, isOpen.toString());
      searchParams.delete(ContextFields.TreeId);
    }
    this.update(searchParams);
  }

  setDisplayMode(mode: DisplayTreesOptions) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.TreeDisplayMode);
    if (mode !== DisplayTreesOptions.markers) {
      searchParams.append(ContextFields.TreeDisplayMode, mode.toString());
    }
    this.update(searchParams);
  }

  getDisplayMode() {
    return (this.searchParams.get(ContextFields.TreeDisplayMode) || DisplayTreesOptions.markers) as DisplayTreesOptions;
  }

  setIsoMapEnabled(enabled: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.IsoMap);
    if (enabled) {
      searchParams.append(ContextFields.IsoMap, enabled.toString());
    }
    this.update(searchParams);
  }

  isIsoMapEnabled() {
    return this.searchParams.get(ContextFields.IsoMap) === 'true';
  }

  setDisplayValuesDisabled(enabled: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.DisplayValues);
    if (enabled) {
      searchParams.append(ContextFields.DisplayValues, enabled.toString());
    }
    this.update(searchParams);
  }

  areTreeMarkersVisible() {
    return this.searchParams.get(ContextFields.DisplayValues) !== 'true';
  }

  setDetailsTreeDisplay(view: TreeDisplays) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.DetailsTreeDisplay);
    searchParams.append(ContextFields.DetailsTreeDisplay, view);
    this.update(searchParams);
  }

  getDetailsTreeDisplay() {
    return (this.searchParams.get(ContextFields.DetailsTreeDisplay) as TreeDisplays || TreeDisplays.STREET_VIEW);
  }

  setDetailsPrimary(view: TreeDisplays) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.DetailsPrimary);
    if (view !== TreeDisplays.NULL) searchParams.append(ContextFields.DetailsPrimary, view);
    this.update(searchParams);
  }

  getDetailsPrimary() {
    return (this.searchParams.get(ContextFields.DetailsPrimary) as TreeDisplays);
  }

  setDetailsSecondary(view: TreeDisplays) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.DetailsSecondary);
    if (view !== TreeDisplays.NULL) searchParams.append(ContextFields.DetailsSecondary, view);
    this.update(searchParams);
  }

  getDetailsSecondary(): TreeDisplays {
    return (this.searchParams.get(ContextFields.DetailsSecondary) as TreeDisplays);
  }

  setDetailsIsExpanded(isExpanded: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.DetailsIsExpanded);
    if (isExpanded) {
      searchParams.append(ContextFields.DetailsIsExpanded, isExpanded.toString());
    }
    this.update(searchParams);
  }

  getDetailsIsExpanded() {
    return (this.searchParams.get(ContextFields.DetailsIsExpanded) === 'true');
  }

  getSelectedDataPanelTab(): Tab | LegacyTab | null {
    return (this.searchParams.get(ContextFields.SelectedDataPanelTab)) as Tab;
  }

  setSelectedDataPanelTab(tab: Tab | LegacyTab | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.SelectedDataPanelTab);
    if (tab) searchParams.append(ContextFields.SelectedDataPanelTab, tab);
    this.update(searchParams);
  }

  getSelectedEnvAccordion(): EnvironmentAccordion | null {
    return (this.searchParams.get(ContextFields.SelectedEnvAccordion)) as EnvironmentAccordion;
  }

  setSelectedEnvAccordion(accordion: EnvironmentAccordion | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.SelectedEnvAccordion);
    if (accordion) searchParams.append(ContextFields.SelectedEnvAccordion, accordion);
    this.update(searchParams);
  }

  setHistoryTrackingOpen(isOpen: boolean) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.HistoryTracking);
    if (isOpen) searchParams.append(ContextFields.HistoryTracking, isOpen.toString());
    this.update(searchParams);
  }

  isHistoryTrackingOpen(): boolean {
    return this.searchParams.get(ContextFields.HistoryTracking) === 'true';
  }

  setSelectedYear(year: string | null) {
    const searchParams = this.searchParams;
    searchParams.delete(ContextFields.SelectedYear);
    if (year) {
      searchParams.append(ContextFields.SelectedYear, year);
    }
    this.update(searchParams);
  }

  getSelectedYear() {
    return this.searchParams.get(ContextFields.SelectedYear);
  }

  setTreeTableSorting(property: DisplayableTreeProperty | 'externalId', direction: SortingDirection) {
    const searchParams = this.searchParams;
    searchParams.delete('sort');
    searchParams.append('sort', `${direction === SortingDirection.DESCENDING ? '-' : ''}${property}`);
    this.update(searchParams);
  }

  getTreeTableSorting(): { property: DisplayableTreeProperty | 'externalId', direction: SortingDirection } | null {
    const sort = this.searchParams.get('sort');
    if (!sort) return { property: 'externalId', direction: SortingDirection.ASCENDING };
    const direction = sort.startsWith('-') ? SortingDirection.DESCENDING : SortingDirection.ASCENDING;
    const property = sort.replace(/^-/, '') as DisplayableTreeProperty | 'externalId';
    return { property, direction };
  }

  getTreeTableSortingString(): string | null {
    return this.searchParams.get('sort') || 'externalId';
  }
}
