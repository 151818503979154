import { useState } from 'react';

export enum CarbonTheme {
  white = 'white',
  g100 = 'g100',
  g90 = 'g90',
  g10 = 'g10'
}

export default function useCarbonThemes() {
  const [theme, setTheme] = useState<CarbonTheme>((localStorage.getItem('carbon-theme') as CarbonTheme) || CarbonTheme.g90);
  const changeTheme = (newTheme: CarbonTheme) => {
    document.documentElement.dataset.carbonTheme = newTheme;
    localStorage.setItem('carbon-theme', newTheme);
    setTheme(newTheme);
  };
  if (!document.documentElement.dataset.carbonTheme) document.documentElement.dataset.carbonTheme = theme;

  return { theme, setTheme: changeTheme };
}