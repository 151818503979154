import { MutableRefObject, useRef, useState } from 'react';
import { PanoramicViewLight } from '../../../Explore/panoramic-view/PanoramicViewLight';
import { useCurrentAccount } from '../../../../account/useAccounts';
import DetailedTree from '../../../../tree/DetailedTree';
import CapturePoint from '../../../../capture-point/CapturePoint';

export type StreetViewProps = {
  seed: string,
  resetView: () => void,
  showEnvironmentPointcloud: boolean,
  setEnvironmentPointcloudVisibility: (boolean) => void,
  showPointcloud: boolean,
  setPointcloudVisibility: (boolean) => void,
  lineMeasurementEnabled: boolean,
  setLineMeasurementEnabled: (boolean) => void,
  showDimensions: boolean,
  setDimensionsVisibility: (boolean) => void,
  showSafety: boolean,
  setSafetyVisibility: (boolean) => void,
  viewRef: MutableRefObject<PanoramicViewLight | null>,
  tree: DetailedTree,
  isCaptureModeActive: boolean,
  capturePoints: CapturePoint[],
  setCapturePoints: (points: CapturePoint[]) => void,
  hideCanopy: boolean,
  setHideCanopy: (boolean) => void,
  organization: any
};

export default function useStreetViewProps(props: { tree: DetailedTree, isCaptureModeActive: boolean }): StreetViewProps {
  const { organization } = useCurrentAccount();
  const [showPointcloud, setPointcloudVisibility] = useState(false);
  const [hideCanopy, setHideCanopy] = useState(false);
  const [showEnvironmentPointcloud, setEnvironmentPointcloudVisibility] = useState(false);
  const [lineMeasurementEnabled, setLineMeasurementEnabled] = useState(false);
  const [showDimensions, setDimensionsVisibility] = useState(false);
  const [showSafety, setSafetyVisibility] = useState(false);
  const [seed, setSeed] = useState(Math.random().toString(16).slice(-7));
  const [capturePoints, setCapturePoints] = useState<CapturePoint[]>([]);

  const resetView = () => {
    const view = viewRef.current;
    if (!view) return;
    setSeed(Math.random().toString(16).slice(-7));
    view.measurement.reset();
    view.resetZoom();
    view.lookAtTree(props.tree, organization.isMetric);
    view.render();
  };
  const viewRef = useRef<PanoramicViewLight | null>(null);

  return {
    showPointcloud, setPointcloudVisibility,
    hideCanopy, setHideCanopy,
    showEnvironmentPointcloud, setEnvironmentPointcloudVisibility,
    lineMeasurementEnabled, setLineMeasurementEnabled,
    showDimensions, setDimensionsVisibility,
    showSafety, setSafetyVisibility,
    seed, resetView,
    viewRef,
    tree: props.tree,
    isCaptureModeActive: props.isCaptureModeActive,
    capturePoints, setCapturePoints,
    organization
  };
}
