import { AirPollutionRemoval, Quantity } from '../ReportData';
import ReportSection from './ReportSection';
import { useTranslation } from 'react-i18next';
import styles from './AirPollutionSection.module.scss';
import { NumericFormat } from '../../../../numeric-format/NumericFormat';

type AirPollutionSectionParams = { data: AirPollutionRemoval, containerId: string, printable?: boolean };
export default function AirPollutionSection(props: AirPollutionSectionParams) {
  const { t } = useTranslation();
  const tt = postfix => t(`reporting.airPollution.${postfix}`);

  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2 className={styles.header}>{tt('title')}</h2>

      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex flex-col text-[var(--cds-text-secondary)]">
            <span>{tt('co')}</span>
            <span>{tt('no2')}</span>
            <span>{tt('o3')}</span>
            <span>{tt('pm25')}</span>
            <span>{tt('so2')}</span>
          </div>
          <div className="flex flex-col">
            <Cell data={props.data.co.quantity} />
            <Cell data={props.data.no2.quantity} />
            <Cell data={props.data.o3.quantity} />
            <Cell data={props.data.pm25.quantity} />
            <Cell data={props.data.so2.quantity} />
          </div>
        </div>
      </div>
    </ReportSection>
  );
}

function Cell(props: { data: Quantity, currency?: string }) {
  const { t } = useTranslation();
  return (
    <div className="flex text-sm font-normal items-center">
      { props.currency ?
        <span>
          {NumericFormat.currency(props.data.unit).format(props.data.value)}
        </span> :
        <>
          <span>{NumericFormat.iso(props.data.unit, navigator.language, t).getFormattedValue(props.data.value)}</span>
          <span className="ml-1">{NumericFormat.iso(props.data.unit, navigator.language, t).getUnit()}</span>
        </>}
    </div>
  );
}
