import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LegacyButton from '../UI/Button/LegacyButton';
import { FullLogoIcon } from '../UI/Icon/Icon';
import styles from './Login.module.scss';
import Input, { PasswordInput } from '../UI/Input/Input';
import LanguageSelector from '../../account/LanguageSelector';
// import AuthPageLayout from '../UI/Page/carbon/AuthPageLayout';
// import { Button, FluidForm } from '@carbon/react';
// import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';

export default function LoginScreen(props: LoginProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [email, setEmail] = useState(props.signedUpUsing || '');
  const [password, setPassword] = useState('');

  const onSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      if (!formRef.current || !formRef.current.reportValidity()) {
        return;
      }

      props.onLogin(email, password);

      return false;
    },
    [props, email, password]
  );

  // TODO: Add CDS redesigned login screen
  // const handleSubmit = useCallback(
  //   (event: SyntheticEvent) => {
  //     event.preventDefault();
  //
  //     if (event.currentTarget.tagName === 'FORM' && !(event.currentTarget as HTMLFormElement).reportValidity()) {
  //       return;
  //     }
  //
  //     props.onLogin(email, password);
  //
  //     return false;
  //   },
  //   [props, email, password]
  // );
  //
  // return (
  //   <AuthPageLayout pageTitle="authPageTitles.login">
  //     <AuthPageLayout.Content>
  //       <FluidForm onSubmit={handleSubmit} className="w-full relative">
  //         {props.error && <div className="absolute top-0 transform -translate-y-full text-[#DA211E] pb-4">{props.error}</div>}
  //         <div className="flex flex-col gap-2 w-full">
  //           <FluidTextInput
  //             autoComplete="email"
  //             labelText={t('signup.email')}
  //             autoFocus={true}
  //             className="w-80"
  //             id="login-email"
  //             type="email"
  //             value={email}
  //             onChange={e => setEmail(e.target.value)}
  //           />
  //           <FluidTextInput
  //             type="password"
  //             labelText={t('userPreferences.currentPasswordLabel')}
  //             className="w-80"
  //             id="login-password"
  //             value={password}
  //             onChange={e => setPassword(e.target.value)}
  //           />
  //         </div>
  //         <div className="flex justify-between items-center mt-4 w-full">
  //           <div
  //             className="text-sm text-[var(--cds-interactive)] cursor-pointer"
  //             onClick={() => navigate('/forgot-password')}
  //           >
  //             {t('login.forgotPassword')}
  //           </div>
  //           <Button type="submit" kind="primary" className="px-4 py-3">{t('login.title')}</Button>
  //         </div>
  //       </FluidForm>
  //     </AuthPageLayout.Content>
  //   </AuthPageLayout>
  // );

  return (
    <div className={styles.login}>
      <FullLogoIcon />

      <form
        data-testid="login-form"
        action="#"
        className={styles.form}
        ref={formRef}
        onSubmit={onSubmit}
      >
        {props.error && <div className={styles.error}>{props.error}</div>}

        {props.signedUpUsing && <div className={styles.success}>{t('login.successfulSignUp')}</div>}

        <Input
          label="login.email"
          placeholder="email@provider.com"
          onValueChange={setEmail}
          value={email}
          required
          type="email"
          testId="login-email"
          legacyStyle={true}
        />
        <PasswordInput
          placeholder={t('login.password')}
          onValueChange={setPassword}
          value={password}
          testId="login-password"
        />

        <div className={styles.controls}>
          <LegacyButton
            type="button"
            variant="secondary"
            onClick={() => navigate('/forgot-password')}
            role="link"
          >
            {t('login.forgotPassword')}
          </LegacyButton>

          <LegacyButton>{t('login.title')}</LegacyButton>
        </div>
      </form>
      <div className={styles.languageSelector}>
        <LanguageSelector
          onLanguageChange={user => i18n.changeLanguage(user.language)}
          initialLanguage={i18n.language || 'en'} />
      </div>
    </div>
  );
}

interface LoginProps {
  onLogin: (email: string, password: string) => unknown,
  error: string,
  signedUpUsing: string | null
}
