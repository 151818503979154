import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import React, { ReactNode, useState } from 'react';
import { useAvailableFilterItemProperties } from '../../../properties/usePropertyConfigurations';
import { useCurrentAccount } from '../../../account/useAccounts';
import { EnumCondition, Filter, NumericCondition } from './FilterConfig';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';
import { ViStatus } from '../../../tree/DetailedTree';
import { SpeciesLists } from './SpeciesList';
import { IconButton } from '@carbon/react';
import FluidDropdown from '@carbon/react/es/components/FluidDropdown/FluidDropdown';
import FluidMultiSelect from '@carbon/react/es/components/FluidMultiSelect/FluidMultiSelect';
import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
import { FilterRemove } from '@carbon/icons-react';

type ReactSelectItem = { value: string, label: string };
export default function CarbonFilterRow(props: FilterRowProps) {
  const enumProperties = {
    [DisplayableTreeProperty.Status]: Object.values(TreeStatus),
    [DisplayableTreeProperty.VitalityVigor]: Object.values(VitalityVigor),
    [DisplayableTreeProperty.ViStatus]: Object.values(ViStatus),
    [DisplayableTreeProperty.ScientificName]: props.speciesLists.scientificNames,
    [DisplayableTreeProperty.Genus]: props.speciesLists.genus,
    [DisplayableTreeProperty.Species]: props.speciesLists.species,
    [DisplayableTreeProperty.OutlierHeightPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierHeightPerLeafArea]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerHeight]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea]: ['true', 'false'],
    [DisplayableTreeProperty.OverallOutlierIndex]: new Array(7).fill(0).map((_, i) => i.toString())
  };

  const { t } = useTranslation();

  const { organization } = useCurrentAccount();
  const properties = useAvailableFilterItemProperties();

  const [inputValue, setInputValue] = useState(props.filter.value?.toString());

  const getDisplayableProperty = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, organization);
    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    return t(`tree.${property}`) + translatedUnit;
  };

  const groups = Object.keys(properties).map(title => ({
    label: t(`treePropertySelector.${title}`),
    options: properties[title].map(it => ({ value: it, label: getDisplayableProperty(it) }))
  }));
  const allProperty = groups.flatMap(group => group.options);
  const selectedProperty = allProperty.find(item => item.value === props.filter.property);

  const handleTreePropertySelect = (item: ReactSelectItem) => {
    if (item?.value) {
      props.update(props.filterIndex, {
        ...props.filter,
        property: item.value as keyof Tree,
        condition: Tree.isEnumProperty(item.value as keyof Tree) ? EnumCondition.IN : NumericCondition.EQUALS,
        value: Tree.isEnumProperty(item.value as keyof Tree) ? [] : (null as unknown as number)
      });
    } else {
      props.update(props.filterIndex, {
        ...props.filter,
        property: '' as keyof Tree,
        condition: '' as EnumCondition,
        value: []
      });
    }
  };

  const handleConditionSelect = (item: ReactSelectItem) => {
    if (item?.value) {
      props.update(props.filterIndex, {
        ...props.filter,
        condition: item?.value as NumericCondition | EnumCondition,
        value: isEnumCondition(item.value as NumericCondition | EnumCondition) ? [] : 0
      });
    } else {
      props.update(props.filterIndex, {
        ...props.filter,
        condition: '' as NumericCondition | EnumCondition,
        value: []
      });
    }
  };

  const handleNumericUpdate = (value: string) => {
    value = value.replace(/-/g, '');
    props.update(props.filterIndex, {
      ...props.filter,
      value: Number(value)
    });
    setInputValue(value);
  };

  const handleEnumChange = (value: string[]) => {
    props.update(props.filterIndex, { ...props.filter, value });
  };

  const enumConditionOptions = Object.values(EnumCondition).map(it => ({ value: it, label: t(`taskManager.${it}`) }));
  const numberConditionOptions = Object.values(NumericCondition).map(it => ({ value: it, label: t(`taskManager.${it}`) }));
  const selectedCondition = [...enumConditionOptions, ...numberConditionOptions].find(item => item.value === props.filter.condition);

  const isEnumCondition = (condition: EnumCondition | NumericCondition) => {
    return Object.values(EnumCondition).includes(condition as EnumCondition);
  };

  const enumPropertyOptions = enumProperties[props.filter.property]?.map(it => ({
    label: Tree.renderPropertyValue(props.filter.property, it, t),
    value: it
  })) || [];

  return (
    <FilterRow i={props.index + 1} onDelete={props.handleDeleteFilter}>
      <FluidDropdown
        className="col-start-1 col-end-3"
        titleText={t('filter.property')}
        onChange={it => handleTreePropertySelect(it.selectedItem)}
        initialSelectedItem={selectedProperty}
        items={allProperty}
        itemToString={item => item.label}
        id={props.index + 1 + '-property'}
        label=''
      />
      <FluidDropdown
        className="col-start-1 col-end-2"
        titleText={t('filter.condition')}
        onChange={it => handleConditionSelect(it.selectedItem)}
        initialSelectedItem={selectedCondition}
        items={Tree.isEnumProperty(props.filter.property) ? enumConditionOptions : numberConditionOptions}
        itemToString={item => item.label}
        id={props.index + 1 + '-condition'}
        label=''
      />
      {isEnumCondition(props.filter.condition) ? (
        <FluidMultiSelect
          isFilterable
          className="col-start-2 col-end-3"
          titleText={t('filter.value')}
          labelText={t('filter.value')}
          onChange={it => handleEnumChange(it.selectedItems.map(it => it.value))}
          items={enumPropertyOptions}
          initialSelectedItems={enumPropertyOptions.filter(it => (props.filter.value as string[]).includes(it.value))}
          itemToString={item => item.label}
        />
      ) : (
        <FluidTextInput
          className="col-start-2 col-end-3"
          type="number"
          labelText={t('filter.value')}
          onChange={e => handleNumericUpdate(e.target.value)}
          defaultValue={inputValue}
          id={props.index + 1 + '-input'}
        />
      )}
    </FilterRow>
  );
}

function FilterRow({ children, i, onDelete }: { children?: ReactNode, i: number, onDelete: () => void }) {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-0.5 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
      <div className="col-start-1 col-end-3 flex items-center justify-between">
        <p className="m-0 text-sm">{t('filter.filter') + ' ' + i.toString().padStart(2, '0')}</p>
        <IconButton onClick={onDelete} label={t('filter.removeFilter')} align="left" kind="ghost"><FilterRemove/></IconButton>
      </div>
      {children}
    </div>
  );
}

interface FilterRowProps {
  index: number,
  handleDeleteFilter: () => void,
  filter: Filter,
  filterIndex: number,
  update: (index: number, filter: Filter) => void,
  speciesLists: SpeciesLists
}
