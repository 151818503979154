import styles from '../Workspace.module.scss';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTracking } from '../../../../analytics/useTracking';
import { TooltipForEllipsis } from '../../../../components/UI/Tooltip/Tooltip';
import GraphModal from './GraphModal';
import { SortButton, SortingDirection } from '../../../../components/UI/SortButton/SortButton';
import TableColumnSelector from '../../../TaskManager/create/TableColumnSelector';
import { MoreVert, Palette, Presentation } from 'iconoir-react';
import { ContextMenu } from '../../../../components/UI/ContextMenu/ContextMenu';
import { ContextMenuTrigger } from '../../../../components/UI/ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../../../../components/UI/ContextMenu/ContextMenuContent';
import {
  columnConfiguration,
  ColumnSettings,
  defaultColumnConfiguration,
  Property,
  TreeTableVersion
} from '../TreeTable';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';
import { Flippers } from '../../../../switches/Flippers';

interface TableHeaderProps {
  columnSettings: Map<Property, ColumnSettings>,
  properties: Property[],
  onSortDirectionChange: (sortProperty: string | null) => unknown,
  setColumnSettings: Dispatch<SetStateAction<Map<Property, ColumnSettings>>>,
  managedAreas: { id: string, code: string }[],
  treeTableVersion: TreeTableVersion,
  handleSelectAll?: () => void,
  allSelected?: boolean
}

export default function TableHeader(props: TableHeaderProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const { events, track } = useTracking();
  const organization = account.organization;

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();

  const [graphModalProperty, setGraphModalProperty] = useState<string | null>(null);

  return (
    <div className="flex sticky top-0 z-[1]">
      {props.treeTableVersion === TreeTableVersion.TASK_CREATION &&
        <div className={'bg-outer-space-900 z-10 h-12 p-4 px-6 w-[4.5rem] left-0 border-r border-outer-space-800 sticky'}>
          <Checkbox
            checked={!!props.allSelected}
            onChange={props.handleSelectAll}
          />
        </div>}
      <div
        className="grid bg-outer-space-900"
        style={{ gridTemplateColumns: `repeat(${props.properties.length}, 200px) 1fr` }}>
        {props.properties.map(property => {
          const configuration = getColumnConfiguration(property);
          const unit = Tree.getUnit(property, account.organization);
          const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
          const columnTitle = t(`tree.${property}`) + translatedUnit;

          return (
            <div
              key={`header-${property}`}
              className={`${styles.header} ${property === selectedTreeProperty && styles.selectedHeader} bg-outer-space-900 ${props.treeTableVersion === TreeTableVersion.TASK_CREATION ? 'first:left-[4.5rem]' : 'first:left-0'}`}>
              <div className={styles.headerContent}>
                <div className={styles.titleContainer}>
                  <TooltipForEllipsis
                    overlay={columnTitle}
                    overlayClassName={styles.tooltip}
                  >
                    <div className={styles.headerTitle}>
                      {columnTitle}
                    </div>
                  </TooltipForEllipsis>

                  <div className={styles.buttonsContainer}>
                    <SortButton
                      sortingDirection={props.columnSettings.get(property)?.sort}
                      onClick={toggleSort(property)}
                    />
                    {configuration.graph && <ContextMenu>
                      <ContextMenuTrigger className="p-2 hover:bg-outer-space-700 rounded">
                        <MoreVert/>
                      </ContextMenuTrigger>

                      <ContextMenuContent className="rounded text-greehill-00 overflow-hidden">
                        {(toggle: () => void) => {
                          return <ul>
                            <li
                              className={`p-2 hover:bg-outer-space-700 ${props.treeTableVersion === TreeTableVersion.TASK_CREATION ? 'opacity-20 cursor-not-allowed' : ''}`}
                              onClick={() => {
                                if (props.treeTableVersion === TreeTableVersion.TASK_CREATION) return;
                                urlContext.setSelectedTreeProperty(property as DisplayableTreeProperty);
                                toggle();
                              }}>
                              <Palette className="w-5 h-5"/>
                            </li>
                            {property !== DisplayableTreeProperty.SafetyFactors && (
                              <li
                                className="p-2 hover:bg-outer-space-700"
                                onClick={() => {
                                  setGraphModalProperty(property);
                                  toggle();
                                }}>
                                <Presentation className="w-5 h-5"/>
                              </li>
                            )}
                          </ul>;
                        }}
                      </ContextMenuContent>
                    </ContextMenu>}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {
          !organization.isEnabled(Flippers.release24q3) && (
            <div className="twp p-2 flex items-center border-outer-space-800 justify-center">
              <TableColumnSelector
                key={(urlContext.getVisibleTableProperties() || []).join(',')}
                properties={urlContext.getVisibleTableProperties() || []}
                setProperties={properties => urlContext.setVisibleTableProperties(properties)}
              />
            </div>
          )
        }
      </div>
      {graphModalProperty && <GraphModal
        onClose={() => setGraphModalProperty(null)}
        property={graphModalProperty}
        managedAreas={props.managedAreas}
      />}
    </div>
  );

  function toggleSort(property: DisplayableTreeProperty | 'externalId', direction?: SortingDirection | null) {
    return async () => {
      const original = props.columnSettings.get(property) ?? { sort: null, aggregateFunction: null };
      const updated = new Map<Property, ColumnSettings>(
        Array.from(props.columnSettings, it => [it[0], { ...it[1], sort: null }])
      );
      let value: SortingDirection | null = original.sort === null ? SortingDirection.ASCENDING : original.sort === SortingDirection.DESCENDING ? null : SortingDirection.DESCENDING;
      if (direction) {
        value = original.sort === direction ? null : direction;
      }
      updated.set(property, { sort: value, aggregateFunction: original.aggregateFunction });
      let sortProperty: string = property;
      if (property === 'managedAreaId') {
        sortProperty = 'managedAreaName';
      }
      const prefix = value === SortingDirection.DESCENDING ? '-' : '';
      const sortPropertyWithDirection = value === null ? null : prefix + sortProperty;
      await props.onSortDirectionChange(sortPropertyWithDirection);
      track(events.TABLE_SORT_COLUMN, { property, direction, ...updated.get(property) });
      props.setColumnSettings(updated);
    };
  }
}

function getColumnConfiguration(property: Property) {
  return columnConfiguration.get(property) ?? defaultColumnConfiguration;
}
