import Dropdown, { Item } from '../../../components/UI/Dropdown/Dropdown';
import { Xmark } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import GroupDropdown from '../../../components/UI/GroupDropdown/GroupDropdown';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import React, { useState } from 'react';
import { useAvailableFilterItemProperties } from '../../../properties/usePropertyConfigurations';
import { useCurrentAccount } from '../../../account/useAccounts';
import { EnumCondition, Filter, NumericCondition, Operator } from './FilterConfig';
import Input from '../../../components/UI/Input/Input';
import { MultiSelect } from '../../../components/Settings/Select/Select';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';
import { ViStatus } from '../../../tree/DetailedTree';
import { SpeciesLists } from './SpeciesList';
import { Flippers } from '../../../switches/Flippers';

export default function LegacyFilterRow(props: FilterRowProps) {
  const enumProperties = {
    [DisplayableTreeProperty.Status]: Object.values(TreeStatus),
    [DisplayableTreeProperty.VitalityVigor]: Object.values(VitalityVigor),
    [DisplayableTreeProperty.ViStatus]: Object.values(ViStatus),
    [DisplayableTreeProperty.ScientificName]: props.speciesLists.scientificNames,
    [DisplayableTreeProperty.Genus]: props.speciesLists.genus,
    [DisplayableTreeProperty.Species]: props.speciesLists.species
  };

  const { t } = useTranslation();

  const { organization } = useCurrentAccount();
  const properties = useAvailableFilterItemProperties();

  const [inputValue, setInputValue] = useState(props.filter.value.toString());

  const getDisplayableProperty = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, organization);
    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    return t(`tree.${property}`) + translatedUnit;
  };

  const groups = Object.keys(properties).map(title => ({
    title: t(`treePropertySelector.${title}`),
    items: properties[title].map(it => ({ id: it, translationKey: getDisplayableProperty(it) }))
  }));

  const handleTreePropertySelect = (item: Item) => {
    props.update(props.filterIndex, {
      ...props.filter,
      property: item.id as keyof Tree,
      condition: Tree.isEnumProperty(item.id as keyof Tree) ? EnumCondition.IN : NumericCondition.EQUALS,
      value: Tree.isEnumProperty(item.id as keyof Tree) ? [] : 0
    });
  };

  const handleConditionSelect = (item: Item) => {
    props.update(props.filterIndex, {
      ...props.filter,
      condition: item?.id as NumericCondition | EnumCondition,
      value: isEnumCondition(item.id as NumericCondition | EnumCondition) ? [] : 0
    });
  };

  const handleNumericUpdate = (value: string) => {
    value = value.replace(/-/g, '');
    props.update(props.filterIndex, {
      ...props.filter,
      value: Number(value)
    });
    setInputValue(value);
  };

  const handleEnumChange = (values: Item[]) => {
    props.update(props.filterIndex, {
      ...props.filter,
      value: values as string[]
    });
  };

  const enumConditionOptions = Object.values(EnumCondition).map(it => ({ id: it, translationKey: `taskManager.${it}` }));
  const numberConditionOptions = Object.values(NumericCondition).map(it => ({ id: it, translationKey: `taskManager.${it}` }));

  const isEnumCondition = (condition: EnumCondition | NumericCondition) => {
    return Object.values(EnumCondition).includes(condition as EnumCondition);
  };

  const enumPropertyOptions = enumProperties[props.filter.property]?.map(it => ({
    label: t(Tree.getTKeyForProperty(props.filter.property, it)),
    value: it
  })) || [];

  const topLevelOperatorOptions = [
    { id: Operator.AND, translationKey: `taskManager.topLevelOperators.${Operator.AND}` },
    { id: Operator.OR, translationKey: `taskManager.topLevelOperators.${Operator.OR}` }
  ];

  const handleTopLevelOperatorSelect = (item: Item) => {
    props.updateOperator(item.id as Operator);
  };

  return (<div className="grid grid-cols-12 text-greehill-00 items-center gap-3 my-3">
    <div className="col-span-2 flex justify-center items-center">
      {props.filterIndex === 0 ? (
        <span>{t('taskManager.where')}</span>)
        :
        props.filterIndex === 1 ? (
          <Dropdown
            value={topLevelOperatorOptions.find(it => it.id === props.topLevelOperator)}
            onSelect={handleTopLevelOperatorSelect}
            items={topLevelOperatorOptions}
            className="w-fit"
            fieldClassName="rounded h-11 flex items-center gap-2"
            menuClassname="bg-outer-space-600 mx-0 px-0"
            dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2"

          />
        ) : <span>{t(`taskManager.topLevelOperators.${props.topLevelOperator}`)}</span>
      }
    </div>
    <div className="col-span-9 rounded-lg items-center grid grid-cols-3 gap-3 w-full">
      <div className="col-span-1">
        <GroupDropdown
          groups={groups}
          value={groups.flatMap(group => group.items).find(item => item.id === props.filter.property)}
          placeholder={t('treePropertySelector.placeholder')}
          onSelect={handleTreePropertySelect}
          fieldClassName="rounded h-11 flex items-center"
          menuClassname="bg-outer-space-600 px-1.5 w-full shadow"
          dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2 w-full truncate"
          searchable={organization.isEnabled(Flippers.release24q3)}
          defaultItem={{ id: '', translationKey: '' }}
        />
      </div>
      <div className="col-span-1">
        {Tree.isEnumProperty(props.filter.property)
          ? <Dropdown
            value={enumConditionOptions.find(it => it.id === props.filter.condition)}
            placeholder={t('taskManager.condition')}
            onSelect={handleConditionSelect}
            items={enumConditionOptions}
            fieldClassName="rounded h-11 flex items-center"
            menuClassname="bg-outer-space-600 mx-0 px-0"
            dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2"
            searchable={organization.isEnabled(Flippers.release24q3)}
            defaultItem={{ id: '', translationKey: '' }}
          />
          : <Dropdown
            value={numberConditionOptions.find(it => it.id === props.filter.condition)}
            placeholder={t('taskManager.condition')}
            onSelect={handleConditionSelect}
            items={numberConditionOptions}
            fieldClassName="rounded h-11 flex items-center"
            menuClassname="bg-outer-space-600 mx-0 px-0"
            dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2"
            searchable={organization.isEnabled(Flippers.release24q3)}
            defaultItem={{ id: '', translationKey: '' }}
          />
        }
      </div>
      <div className="col-span-1">
        {isEnumCondition(props.filter.condition) ? (
          <div>
            <MultiSelect
              value={props.filter.value as Item[]}
              options={enumPropertyOptions}
              onChange={handleEnumChange}
              containerStyle={{ maxWidth: '15vw' }}
              label={''}
              isSearchable={false}
            />
          </div>
        ) : (
          <Input
            label={''}
            type={'number'}
            value={inputValue}
            onValueChange={handleNumericUpdate}
            legacyStyle={true}
            disabled={!props.filter.condition}
          />
        )}
      </div>
    </div>
    <div className="col-span-1 flex justify-center items-center">
      <Xmark onClick={() => props.handleDeleteFilter()} cursor={'pointer'} />
    </div>
  </div>);
}

interface FilterRowProps {
  handleDeleteFilter: () => void,
  filter: Filter,
  filterIndex: number,
  update: (index: number, filter: Filter) => void,
  updateOperator: (operator: Operator) => void,
  speciesLists: SpeciesLists,
  topLevelOperator?: string
}
