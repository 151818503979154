import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Text from '../Text/Text';
import styles from './GroupDropdown.module.scss';
import { NavArrowDown, NavArrowUp, Xmark } from 'iconoir-react';
import { useTranslation } from 'react-i18next';

export interface Item {
  id?: string,
  translationKey?: string
}

interface GroupDropdownProps {
  value?: Item,
  placeholder?: string,
  label?: string,
  groups: { title: string, items: Item[] }[],
  onSelect?: (item: Item) => unknown,
  className?: string,
  disabled?: boolean,
  valueRenderer?: (item: Item) => ReactNode,
  testId?: string,
  selected?: { [key: string]: boolean },
  setOpen?: Dispatch<SetStateAction<boolean>>,
  fieldClassName?: string,
  menuClassname?: string,
  openClassname?: string,
  dropDownItemClassName?: string,
  defaultItem?: Item,
  searchable?: boolean
}

export default function GroupDropdown(props: GroupDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shouldDropup, setShouldDropup] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [searchKey, setSearchKey] = useState('');
  const { t } = useTranslation();

  const filteredGroups = useMemo(() => {
    const newGroups = props.groups.map(group => ({
      ...group,
      items: group.items.filter(item => (item.translationKey ? t(item.translationKey) : item.id)!.toLowerCase().includes(searchKey.toLowerCase()))
    }));
    return newGroups.filter(group => group.items.length > 0);
  }, [props.groups, searchKey]);

  const itemsLength = useMemo(() => props.groups.reduce((acc, group) => acc + group.items.length, 0), [props.groups]);

  const handleSelect = (ev, item) => {
    setSearchKey('');
    ev.stopPropagation();
    props.onSelect?.(item);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const onClick = useCallback(() => {
    if (props.disabled) {
      return;
    }
    setIsDropdownOpen(state => (itemsLength > 0 ? !state : false));
  }, [props.disabled, itemsLength]);

  useEffect(() => {
    const dropdown = containerRef.current?.children.item(props.label ? 2 : 1);

    if (!isDropdownOpen || !dropdown) return;

    const isInModal = dropdown?.closest('[role=dialog]');

    const observer = new IntersectionObserver(entries => {
      if (entries[0] && entries[0].intersectionRatio < 1 && !isInModal) {
        setShouldDropup(true);
      }
    });

    observer.observe(dropdown);
    dropdown.classList.add(styles.opacityFull);

    return (() => {
      observer.unobserve(dropdown);
      dropdown.classList.remove(styles.opacityFull);
    });
  }, [shouldDropup, isDropdownOpen, props.label]);

  useEffect(() => {
    if (props.setOpen) props.setOpen(isDropdownOpen);
  }, [props.setOpen, isDropdownOpen]);

  const handleClearClick = (event: React.MouseEvent) => {
    handleSelect(event, props.defaultItem || props.groups[0].items[0]);
    setSearchKey('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const firstItem = props.groups.flatMap(group => group.items).find(item => item.translationKey?.toLowerCase().includes(searchKey.toLowerCase()));
      if (!firstItem) return;
      handleSelect(e, firstItem);
      setIsDropdownOpen(false);
    }
  };

  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${props.className ?? ''} ${props.disabled ? styles.disabled : ''}`}
      onClick={onClick}
      data-testid={props.testId}
    >
      {props.label && <Text
        className={styles.label}
        weight={600}
        translationKey={props.label} />}
      <div
        className={`${styles.field} ${props.fieldClassName ?? ''} ${isDropdownOpen ? props.openClassname : ''}`}
        data-testid={`${props.testId}-value`}>
        {props.searchable && isDropdownOpen ?
          <input
            type="text"
            value={searchKey}
            placeholder={t(props.value?.translationKey ?? '')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value)}
            onKeyDown={handleKeyDown}
            onClick={e => {
              setIsDropdownOpen(true);
              e.stopPropagation();
            }}
            onFocus={() => setIsDropdownOpen(true)}
            autoFocus={isDropdownOpen}
          />
          : props.value ?
            (props.valueRenderer ? props.valueRenderer(props.value) : <Text translationKey={props.value.translationKey!} />)
            : <Text color="ternary" translationKey={props.placeholder!} />}
        <div className='flex text-xs'>
          {props.searchable && props.defaultItem && props.value?.id && <Xmark onClick={handleClearClick} />}
          {itemsLength > 0 && (isDropdownOpen ? <NavArrowUp /> : <NavArrowDown />)}
        </div>
      </div>
      {isDropdownOpen && (
        <div className={`${styles.dropdown} ${shouldDropup && styles.dropup} ${props.menuClassname ?? ''}`}>
          {filteredGroups.map(group => (
            <div key={group.title}>
              <div className={styles.groupTitle}>{group.title}</div>
              {group.items.map((item, idx) => (
                props.valueRenderer ? (
                  <div
                    className={`${styles.dropdownItem} ${props.dropDownItemClassName ?? ''}`}
                    key={item.id || idx}
                    onClick={ev => handleSelect(ev, item)}>{ props.valueRenderer(item) }</div>
                ) :
                  <Text
                    className={`${styles.dropdownItem} ${props.dropDownItemClassName ?? ''} ${props.selected?.[item.id as string] ? styles.selected : ''}`}
                    key={item.id || idx}
                    color="secondary"
                    onClick={ev => handleSelect(ev, item)}
                    translationKey={item.translationKey!}
                  />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
